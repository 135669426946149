import * as CryptoJS from 'crypto-js';

const MERCK_EMAIL_DOMAINS = [
  'merckgroup.com',
  'emdserono.com',
  'emdgroup.com',
  'emdelectronics.com',
  'milliporesigma.com',
  'merck.de',
];

export function camelCaseToSpaces(str: string) {
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase();
  });
}

export async function blobToBase64(
  blob: Blob,
  keepDeclaration = false
): Promise<string> {
  const regex = /data:.+\/.+;base64,/gm;

  const b: string = await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
  if (keepDeclaration) {
    return b;
  }
  return b.replace(regex, '');
}

export function arrayToTableData<T>(arr: any[]) {
  let headers: string[] = [];
  const rows: T[][] = [];
  try {
    const first = arr.at(0);
    headers = Object.keys(first);
    arr.forEach((obj) => {
      const row: T[] = [];
      headers.forEach((key) => row.push(obj[key]));
      rows.push(row);
    });
  } finally {
    return {
      headers,
      rows,
    };
  }
}

export function renameObjectKey(obj: any, oldKey: string, newKey: string) {
  // check if old key = new key
  if (oldKey !== newKey) {
    const attr = Object.getOwnPropertyDescriptor(obj, oldKey);
    if (attr !== undefined) {
      Object.defineProperty(
        obj,
        newKey, // modify old key
        // fetch description from object
        attr
      );
      delete obj[oldKey]; // delete old key
    }
  }
}

export function getEmailAliases(email: string) {
  const name = email.split('@')[0];
  return MERCK_EMAIL_DOMAINS.map((domain) => `${name}@${domain}`);
}

export function hashCode(inputString: string | undefined) {
  let hash = 0;
  if (!inputString || inputString.length === 0) return hash;

  for (let i = 0; i < inputString.length; i++) {
    const char = inputString.charCodeAt(i);
    hash = (hash << 5) - hash + char;
  }

  return hash;
}

export const castToNumberSafely = (value: string | number | undefined) => {
  if (value === undefined || value === '') {
    return undefined;
  }

  if (typeof value === 'number' || !isNaN(Number(value))) {
    return +value;
  } else return undefined;
};

export function encryptToMD5(input: string): string {
  return CryptoJS.MD5(input).toString(CryptoJS.enc.Hex);
}
