import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useUser } from '@emdgroup/react-auth';
import { useEffect, useState } from 'react';
import { encryptToMD5 } from 'util/utils';

export const useAuthentication = () => {
  const user = useUser();
  const { pushInstruction } = useMatomo();
  const { authHeader, info } = user;
  const [identity, setIdentity] = useState<
    { externalId: string } | undefined
  >();
  useEffect(() => {
    if (authHeader?.Authorization !== undefined) {
      sessionStorage.setItem('accessToken', authHeader?.Authorization);
      fetch('https://login.emddigital.com/oauth2/userinfo?identities=true', {
        headers: authHeader,
      })
        .then((res) => res.json())
        .then((data) => setIdentity(data.identities[0]));
    }
  }, [authHeader]);
  useEffect(() => {
    if (info) pushInstruction('setUserId', encryptToMD5(info.sub));
  }, [info]);

  return { ...user, identity };
};
