var __defProp = Object.defineProperty;
var __markAsModule = (target) => __defProp(target, "__esModule", { value: true });
var __commonJS = (cb, mod) => function __require() {
  return mod || (0, cb[Object.keys(cb)[0]])((mod = { exports: {} }).exports, mod), mod.exports;
};
var __export = (target, all) => {
  __markAsModule(target);
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};

// node_modules/fast-deep-equal/index.js
var require_fast_deep_equal = __commonJS({
  "node_modules/fast-deep-equal/index.js"(exports2, module2) {
    "use strict";
    module2.exports = function equal(a, b) {
      if (a === b)
        return true;
      if (a && b && typeof a == "object" && typeof b == "object") {
        if (a.constructor !== b.constructor)
          return false;
        var length, i, keys;
        if (Array.isArray(a)) {
          length = a.length;
          if (length != b.length)
            return false;
          for (i = length; i-- !== 0; )
            if (!equal(a[i], b[i]))
              return false;
          return true;
        }
        if (a.constructor === RegExp)
          return a.source === b.source && a.flags === b.flags;
        if (a.valueOf !== Object.prototype.valueOf)
          return a.valueOf() === b.valueOf();
        if (a.toString !== Object.prototype.toString)
          return a.toString() === b.toString();
        keys = Object.keys(a);
        length = keys.length;
        if (length !== Object.keys(b).length)
          return false;
        for (i = length; i-- !== 0; )
          if (!Object.prototype.hasOwnProperty.call(b, keys[i]))
            return false;
        for (i = length; i-- !== 0; ) {
          var key = keys[i];
          if (!equal(a[key], b[key]))
            return false;
        }
        return true;
      }
      return a !== a && b !== b;
    };
  }
});

// node_modules/@emdgroup/tapir/node_modules/ajv/dist/runtime/equal.js
var require_equal = __commonJS({
  "node_modules/@emdgroup/tapir/node_modules/ajv/dist/runtime/equal.js"(exports2) {
    "use strict";
    Object.defineProperty(exports2, "__esModule", { value: true });
    var equal = require_fast_deep_equal();
    equal.code = 'require("ajv/dist/runtime/equal").default';
    exports2.default = equal;
  }
});

// api/src/types/types.ajv.ts
__export(exports, {
  AcquisitionFunctionType: () => AcquisitionFunctionType,
  BatchQueryParameterStringValue: () => BatchQueryParameterStringValue,
  CombinerType: () => CombinerType,
  ConditionsType: () => ConditionsType,
  ConstraintContinuousOperatorType: () => ConstraintContinuousOperatorType,
  ConstraintDiscreteConditionOperatorType: () => ConstraintDiscreteConditionOperatorType,
  ConstraintsSubTypeContinuous: () => ConstraintsSubTypeContinuous,
  ConstraintsSubTypeDiscrete: () => ConstraintsSubTypeDiscrete,
  ConstraintsType: () => ConstraintsType,
  ExperimentStatus: () => ExperimentStatus,
  InitialGuessMethodType: () => InitialGuessMethodType,
  ParameterType: () => ParameterType,
  ProjectRole: () => ProjectRole,
  ProjectStatus: () => ProjectStatus,
  RecommendationStatus: () => RecommendationStatus,
  SurrogateModelType: () => SurrogateModelType,
  assertAcquisitionFunctionType: () => assertAcquisitionFunctionType,
  assertAnonymous1: () => assertAnonymous1,
  assertAnonymous10: () => assertAnonymous10,
  assertAnonymous10Json: () => assertAnonymous10Json,
  assertAnonymous11: () => assertAnonymous11,
  assertAnonymous11Json: () => assertAnonymous11Json,
  assertAnonymous12: () => assertAnonymous12,
  assertAnonymous15: () => assertAnonymous15,
  assertAnonymous15Json: () => assertAnonymous15Json,
  assertAnonymous16: () => assertAnonymous16,
  assertAnonymous16Json: () => assertAnonymous16Json,
  assertAnonymous17: () => assertAnonymous17,
  assertAnonymous20: () => assertAnonymous20,
  assertAnonymous20Json: () => assertAnonymous20Json,
  assertAnonymous21: () => assertAnonymous21,
  assertAnonymous21Json: () => assertAnonymous21Json,
  assertAnonymous22: () => assertAnonymous22,
  assertAnonymous22Json: () => assertAnonymous22Json,
  assertAnonymous23: () => assertAnonymous23,
  assertAnonymous24: () => assertAnonymous24,
  assertAnonymous24Json: () => assertAnonymous24Json,
  assertAnonymous25: () => assertAnonymous25,
  assertAnonymous25Json: () => assertAnonymous25Json,
  assertAnonymous26: () => assertAnonymous26,
  assertAnonymous26Json: () => assertAnonymous26Json,
  assertAnonymous27: () => assertAnonymous27,
  assertAnonymous27Json: () => assertAnonymous27Json,
  assertAnonymous28: () => assertAnonymous28,
  assertAnonymous29: () => assertAnonymous29,
  assertAnonymous29Json: () => assertAnonymous29Json,
  assertAnonymous30: () => assertAnonymous30,
  assertAnonymous30Json: () => assertAnonymous30Json,
  assertAnonymous31: () => assertAnonymous31,
  assertAnonymous31Json: () => assertAnonymous31Json,
  assertAnonymous32: () => assertAnonymous32,
  assertAnonymous32Json: () => assertAnonymous32Json,
  assertAnonymous33: () => assertAnonymous33,
  assertAnonymous33Json: () => assertAnonymous33Json,
  assertAnonymous34: () => assertAnonymous34,
  assertAnonymous35: () => assertAnonymous35,
  assertAnonymous35Json: () => assertAnonymous35Json,
  assertAnonymous36: () => assertAnonymous36,
  assertAnonymous36Json: () => assertAnonymous36Json,
  assertAnonymous37: () => assertAnonymous37,
  assertAnonymous38: () => assertAnonymous38,
  assertAnonymous38Json: () => assertAnonymous38Json,
  assertAnonymous39: () => assertAnonymous39,
  assertAnonymous39Json: () => assertAnonymous39Json,
  assertAnonymous40: () => assertAnonymous40,
  assertAnonymous40Json: () => assertAnonymous40Json,
  assertAnonymous41: () => assertAnonymous41,
  assertAnonymous42: () => assertAnonymous42,
  assertAnonymous42Json: () => assertAnonymous42Json,
  assertAnonymous43: () => assertAnonymous43,
  assertAnonymous43Json: () => assertAnonymous43Json,
  assertAnonymous44: () => assertAnonymous44,
  assertAnonymous44Json: () => assertAnonymous44Json,
  assertAnonymous45: () => assertAnonymous45,
  assertAnonymous45Json: () => assertAnonymous45Json,
  assertAnonymous46: () => assertAnonymous46,
  assertAnonymous46Json: () => assertAnonymous46Json,
  assertAnonymous6: () => assertAnonymous6,
  assertAnonymous9: () => assertAnonymous9,
  assertAnonymous9Json: () => assertAnonymous9Json,
  assertBatchQueryParameterNumericValue: () => assertBatchQueryParameterNumericValue,
  assertBatchQueryParameterStringValue: () => assertBatchQueryParameterStringValue,
  assertBatchQueryParameterValue: () => assertBatchQueryParameterValue,
  assertChangeHistoryRequest: () => assertChangeHistoryRequest,
  assertChangeHistoryRequestJson: () => assertChangeHistoryRequestJson,
  assertChangeHistoryRequestJsonDelete: () => assertChangeHistoryRequestJsonDelete,
  assertChangeHistoryRequestJsonDeleteItem: () => assertChangeHistoryRequestJsonDeleteItem,
  assertChangeHistoryRequestJsonModify: () => assertChangeHistoryRequestJsonModify,
  assertChangeHistoryRequestJsonModifyItem: () => assertChangeHistoryRequestJsonModifyItem,
  assertChangeHistoryRequestJsonModifyItemResults: () => assertChangeHistoryRequestJsonModifyItemResults,
  assertChangeHistoryRequestPathParameters: () => assertChangeHistoryRequestPathParameters,
  assertChangeHistoryResponse: () => assertChangeHistoryResponse,
  assertChangeInviteStatusRequest: () => assertChangeInviteStatusRequest,
  assertChangeInviteStatusRequestJson: () => assertChangeInviteStatusRequestJson,
  assertChangeInviteStatusRequestPathParameters: () => assertChangeInviteStatusRequestPathParameters,
  assertChangeInviteStatusResponse: () => assertChangeInviteStatusResponse,
  assertCloseProjectRequest: () => assertCloseProjectRequest,
  assertCloseProjectRequestPathParameters: () => assertCloseProjectRequestPathParameters,
  assertCloseProjectResponse: () => assertCloseProjectResponse,
  assertCloseProjectResponseJson: () => assertCloseProjectResponseJson,
  assertCombinerType: () => assertCombinerType,
  assertConditionsType: () => assertConditionsType,
  assertConstraint: () => assertConstraint,
  assertConstraintContinuousOperatorType: () => assertConstraintContinuousOperatorType,
  assertConstraintDiscreteConditionOperatorType: () => assertConstraintDiscreteConditionOperatorType,
  assertConstraintsSubTypeContinuous: () => assertConstraintsSubTypeContinuous,
  assertConstraintsSubTypeDiscrete: () => assertConstraintsSubTypeDiscrete,
  assertConstraintsType: () => assertConstraintsType,
  assertContinuousConstraint: () => assertContinuousConstraint,
  assertCreateExperimentsRequest: () => assertCreateExperimentsRequest,
  assertCreateExperimentsRequestJson: () => assertCreateExperimentsRequestJson,
  assertCreateExperimentsRequestJsonItem: () => assertCreateExperimentsRequestJsonItem,
  assertCreateExperimentsRequestJsonItemResults: () => assertCreateExperimentsRequestJsonItemResults,
  assertCreateExperimentsRequestPathParameters: () => assertCreateExperimentsRequestPathParameters,
  assertCreateExperimentsResponse: () => assertCreateExperimentsResponse,
  assertCreateFeedbackRequest: () => assertCreateFeedbackRequest,
  assertCreateFeedbackRequestJson: () => assertCreateFeedbackRequestJson,
  assertCreateFeedbackResponse: () => assertCreateFeedbackResponse,
  assertCreateFeedbackResponseJson: () => assertCreateFeedbackResponseJson,
  assertCreateProjectRequest: () => assertCreateProjectRequest,
  assertCreateProjectRequestJson: () => assertCreateProjectRequestJson,
  assertCreateProjectResponse: () => assertCreateProjectResponse,
  assertCreateRecommendationsRequest: () => assertCreateRecommendationsRequest,
  assertCreateRecommendationsRequestJson: () => assertCreateRecommendationsRequestJson,
  assertCreateRecommendationsRequestPathParameters: () => assertCreateRecommendationsRequestPathParameters,
  assertCreateRecommendationsRequestQueryStringParameters: () => assertCreateRecommendationsRequestQueryStringParameters,
  assertCreateRecommendationsResponse: () => assertCreateRecommendationsResponse,
  assertCustomParameterValue: () => assertCustomParameterValue,
  assertDeleteInviteRequest: () => assertDeleteInviteRequest,
  assertDeleteInviteRequestPathParameters: () => assertDeleteInviteRequestPathParameters,
  assertDeleteInviteResponse: () => assertDeleteInviteResponse,
  assertDeleteInviteResponseJson: () => assertDeleteInviteResponseJson,
  assertDeleteProjectRequest: () => assertDeleteProjectRequest,
  assertDeleteProjectRequestJson: () => assertDeleteProjectRequestJson,
  assertDeleteProjectResponse: () => assertDeleteProjectResponse,
  assertDiscreteConditions: () => assertDiscreteConditions,
  assertDiscreteConstraint: () => assertDiscreteConstraint,
  assertExpandChemicalSpaceRequest: () => assertExpandChemicalSpaceRequest,
  assertExpandChemicalSpaceRequestJson: () => assertExpandChemicalSpaceRequestJson,
  assertExpandChemicalSpaceRequestJsonExperiments: () => assertExpandChemicalSpaceRequestJsonExperiments,
  assertExpandChemicalSpaceRequestPathParameters: () => assertExpandChemicalSpaceRequestPathParameters,
  assertExpandChemicalSpaceResponse: () => assertExpandChemicalSpaceResponse,
  assertExperiment: () => assertExperiment,
  assertExperimentResults: () => assertExperimentResults,
  assertExperimentStatus: () => assertExperimentStatus,
  assertExpertOptions: () => assertExpertOptions,
  assertGetBaybeConfigsRequest: () => assertGetBaybeConfigsRequest,
  assertGetBaybeConfigsRequestPathParameters: () => assertGetBaybeConfigsRequestPathParameters,
  assertGetBaybeConfigsResponse: () => assertGetBaybeConfigsResponse,
  assertGetInviteByIdRequest: () => assertGetInviteByIdRequest,
  assertGetInviteByIdRequestPathParameters: () => assertGetInviteByIdRequestPathParameters,
  assertGetInviteByIdResponse: () => assertGetInviteByIdResponse,
  assertGetProjectByIdRequest: () => assertGetProjectByIdRequest,
  assertGetProjectByIdRequestPathParameters: () => assertGetProjectByIdRequestPathParameters,
  assertGetProjectByIdResponse: () => assertGetProjectByIdResponse,
  assertGetUserRequest: () => assertGetUserRequest,
  assertGetUserResponse: () => assertGetUserResponse,
  assertInitialGuessMethodType: () => assertInitialGuessMethodType,
  assertListConstraintsRequest: () => assertListConstraintsRequest,
  assertListConstraintsRequestPathParameters: () => assertListConstraintsRequestPathParameters,
  assertListConstraintsResponse: () => assertListConstraintsResponse,
  assertListExperimentsRequest: () => assertListExperimentsRequest,
  assertListExperimentsRequestPathParameters: () => assertListExperimentsRequestPathParameters,
  assertListExperimentsResponse: () => assertListExperimentsResponse,
  assertListInvitesRequest: () => assertListInvitesRequest,
  assertListInvitesRequestPathParameters: () => assertListInvitesRequestPathParameters,
  assertListInvitesResponse: () => assertListInvitesResponse,
  assertListProjectsRequest: () => assertListProjectsRequest,
  assertListProjectsResponse: () => assertListProjectsResponse,
  assertListRecommendationsRequest: () => assertListRecommendationsRequest,
  assertListRecommendationsRequestPathParameters: () => assertListRecommendationsRequestPathParameters,
  assertListRecommendationsRequestQueryStringParameters: () => assertListRecommendationsRequestQueryStringParameters,
  assertListRecommendationsResponse: () => assertListRecommendationsResponse,
  assertListUserInvitesRequest: () => assertListUserInvitesRequest,
  assertListUserInvitesRequestQueryStringParameters: () => assertListUserInvitesRequestQueryStringParameters,
  assertListUserInvitesResponse: () => assertListUserInvitesResponse,
  assertNumericParameterValue: () => assertNumericParameterValue,
  assertNumericalContinuousParameter: () => assertNumericalContinuousParameter,
  assertParameter: () => assertParameter,
  assertParameterRecommendation: () => assertParameterRecommendation,
  assertParameterRecommendationValue: () => assertParameterRecommendationValue,
  assertParameterType: () => assertParameterType,
  assertParameterValue: () => assertParameterValue,
  assertProject: () => assertProject,
  assertProjectInvite: () => assertProjectInvite,
  assertProjectRole: () => assertProjectRole,
  assertProjectStatus: () => assertProjectStatus,
  assertRecommendation: () => assertRecommendation,
  assertRecommendationStatus: () => assertRecommendationStatus,
  assertRejectRecommendationRequest: () => assertRejectRecommendationRequest,
  assertRejectRecommendationRequestPathParameters: () => assertRejectRecommendationRequestPathParameters,
  assertRejectRecommendationResponse: () => assertRejectRecommendationResponse,
  assertReopenProjectRequest: () => assertReopenProjectRequest,
  assertReopenProjectRequestPathParameters: () => assertReopenProjectRequestPathParameters,
  assertReopenProjectResponse: () => assertReopenProjectResponse,
  assertReopenProjectResponseJson: () => assertReopenProjectResponseJson,
  assertSendInviteRequest: () => assertSendInviteRequest,
  assertSendInviteRequestJson: () => assertSendInviteRequestJson,
  assertSendInviteRequestPathParameters: () => assertSendInviteRequestPathParameters,
  assertSendInviteResponse: () => assertSendInviteResponse,
  assertSmilesParameterValue: () => assertSmilesParameterValue,
  assertStringArray: () => assertStringArray,
  assertStringParameterValue: () => assertStringParameterValue,
  assertSubSelectionCondition: () => assertSubSelectionCondition,
  assertSurrogateModelType: () => assertSurrogateModelType,
  assertTargetVariable: () => assertTargetVariable,
  assertThresholdCondition: () => assertThresholdCondition,
  assertUser: () => assertUser,
  routes: () => routes,
  validators: () => validators
});
function assertUser(data) {
  if (exports.isUser(data))
    return;
  throw new ValidationError(exports.isUser.errors);
}
function assertBatchQueryParameterStringValue(data) {
  if (exports.isBatchQueryParameterStringValue(data))
    return;
  throw new ValidationError(exports.isBatchQueryParameterStringValue.errors);
}
var BatchQueryParameterStringValue;
(function(BatchQueryParameterStringValue2) {
  BatchQueryParameterStringValue2["all"] = "all";
  BatchQueryParameterStringValue2["latest"] = "latest";
})(BatchQueryParameterStringValue || (BatchQueryParameterStringValue = {}));
function assertBatchQueryParameterNumericValue(data) {
  if (exports.isBatchQueryParameterNumericValue(data))
    return;
  throw new ValidationError(exports.isBatchQueryParameterNumericValue.errors);
}
function assertBatchQueryParameterValue(data) {
  if (exports.isBatchQueryParameterValue(data))
    return;
  throw new ValidationError(exports.isBatchQueryParameterValue.errors);
}
function assertProjectStatus(data) {
  if (exports.isProjectStatus(data))
    return;
  throw new ValidationError(exports.isProjectStatus.errors);
}
var ProjectStatus;
(function(ProjectStatus2) {
  ProjectStatus2["creating"] = "creating";
  ProjectStatus2["draft"] = "draft";
  ProjectStatus2["idle"] = "idle";
  ProjectStatus2["error"] = "error";
  ProjectStatus2["running"] = "running";
  ProjectStatus2["closed"] = "closed";
})(ProjectStatus || (ProjectStatus = {}));
function assertExperimentStatus(data) {
  if (exports.isExperimentStatus(data))
    return;
  throw new ValidationError(exports.isExperimentStatus.errors);
}
var ExperimentStatus;
(function(ExperimentStatus2) {
  ExperimentStatus2["creating"] = "creating";
  ExperimentStatus2["running"] = "running";
  ExperimentStatus2["finished"] = "finished";
  ExperimentStatus2["error"] = "error";
})(ExperimentStatus || (ExperimentStatus = {}));
function assertRecommendationStatus(data) {
  if (exports.isRecommendationStatus(data))
    return;
  throw new ValidationError(exports.isRecommendationStatus.errors);
}
var RecommendationStatus;
(function(RecommendationStatus2) {
  RecommendationStatus2["created"] = "created";
  RecommendationStatus2["rejected"] = "rejected";
  RecommendationStatus2["finished"] = "finished";
  RecommendationStatus2["error"] = "error";
})(RecommendationStatus || (RecommendationStatus = {}));
function assertParameterType(data) {
  if (exports.isParameterType(data))
    return;
  throw new ValidationError(exports.isParameterType.errors);
}
var ParameterType;
(function(ParameterType2) {
  ParameterType2["smiles"] = "smiles";
  ParameterType2["numeric"] = "numeric";
  ParameterType2["category"] = "category";
  ParameterType2["custom"] = "custom";
  ParameterType2["task"] = "task";
  ParameterType2["continuous"] = "continuous";
})(ParameterType || (ParameterType = {}));
function assertConstraintsType(data) {
  if (exports.isConstraintsType(data))
    return;
  throw new ValidationError(exports.isConstraintsType.errors);
}
var ConstraintsType;
(function(ConstraintsType2) {
  ConstraintsType2["continuous"] = "continuous";
  ConstraintsType2["discrete"] = "discrete";
})(ConstraintsType || (ConstraintsType = {}));
function assertConstraintsSubTypeContinuous(data) {
  if (exports.isConstraintsSubTypeContinuous(data))
    return;
  throw new ValidationError(exports.isConstraintsSubTypeContinuous.errors);
}
var ConstraintsSubTypeContinuous;
(function(ConstraintsSubTypeContinuous2) {
  ConstraintsSubTypeContinuous2["ContinuousLinearConstraint"] = "ContinuousLinearConstraint";
})(ConstraintsSubTypeContinuous || (ConstraintsSubTypeContinuous = {}));
function assertConstraintsSubTypeDiscrete(data) {
  if (exports.isConstraintsSubTypeDiscrete(data))
    return;
  throw new ValidationError(exports.isConstraintsSubTypeDiscrete.errors);
}
var ConstraintsSubTypeDiscrete;
(function(ConstraintsSubTypeDiscrete2) {
  ConstraintsSubTypeDiscrete2["DiscreteExcludeConstraint"] = "DiscreteExcludeConstraint";
  ConstraintsSubTypeDiscrete2["DiscreteSumConstraint"] = "DiscreteSumConstraint";
  ConstraintsSubTypeDiscrete2["DiscreteProductConstraint"] = "DiscreteProductConstraint";
  ConstraintsSubTypeDiscrete2["DiscreteNoLabelDuplicatesConstraint"] = "DiscreteNoLabelDuplicatesConstraint";
  ConstraintsSubTypeDiscrete2["DiscreteLinkedParametersConstraint"] = "DiscreteLinkedParametersConstraint";
  ConstraintsSubTypeDiscrete2["DiscreteDependenciesConstraint"] = "DiscreteDependenciesConstraint";
})(ConstraintsSubTypeDiscrete || (ConstraintsSubTypeDiscrete = {}));
function assertConditionsType(data) {
  if (exports.isConditionsType(data))
    return;
  throw new ValidationError(exports.isConditionsType.errors);
}
var ConditionsType;
(function(ConditionsType2) {
  ConditionsType2["threshold"] = "threshold";
  ConditionsType2["subselection"] = "subselection";
})(ConditionsType || (ConditionsType = {}));
function assertConstraintContinuousOperatorType(data) {
  if (exports.isConstraintContinuousOperatorType(data))
    return;
  throw new ValidationError(exports.isConstraintContinuousOperatorType.errors);
}
var ConstraintContinuousOperatorType;
(function(ConstraintContinuousOperatorType2) {
  ConstraintContinuousOperatorType2["="] = "=";
  ConstraintContinuousOperatorType2[">="] = ">=";
  ConstraintContinuousOperatorType2["<="] = "<=";
})(ConstraintContinuousOperatorType || (ConstraintContinuousOperatorType = {}));
function assertConstraintDiscreteConditionOperatorType(data) {
  if (exports.isConstraintDiscreteConditionOperatorType(data))
    return;
  throw new ValidationError(exports.isConstraintDiscreteConditionOperatorType.errors);
}
var ConstraintDiscreteConditionOperatorType;
(function(ConstraintDiscreteConditionOperatorType2) {
  ConstraintDiscreteConditionOperatorType2["="] = "=";
  ConstraintDiscreteConditionOperatorType2["!="] = "!=";
  ConstraintDiscreteConditionOperatorType2[">="] = ">=";
  ConstraintDiscreteConditionOperatorType2[">"] = ">";
  ConstraintDiscreteConditionOperatorType2["<="] = "<=";
  ConstraintDiscreteConditionOperatorType2["<"] = "<";
})(ConstraintDiscreteConditionOperatorType || (ConstraintDiscreteConditionOperatorType = {}));
function assertCombinerType(data) {
  if (exports.isCombinerType(data))
    return;
  throw new ValidationError(exports.isCombinerType.errors);
}
var CombinerType;
(function(CombinerType2) {
  CombinerType2["AND"] = "AND";
  CombinerType2["OR"] = "OR";
  CombinerType2["XOR"] = "XOR";
})(CombinerType || (CombinerType = {}));
function assertSmilesParameterValue(data) {
  if (exports.isSmilesParameterValue(data))
    return;
  throw new ValidationError(exports.isSmilesParameterValue.errors);
}
function assertStringParameterValue(data) {
  if (exports.isStringParameterValue(data))
    return;
  throw new ValidationError(exports.isStringParameterValue.errors);
}
function assertNumericParameterValue(data) {
  if (exports.isNumericParameterValue(data))
    return;
  throw new ValidationError(exports.isNumericParameterValue.errors);
}
function assertCustomParameterValue(data) {
  if (exports.isCustomParameterValue(data))
    return;
  throw new ValidationError(exports.isCustomParameterValue.errors);
}
function assertNumericalContinuousParameter(data) {
  if (exports.isNumericalContinuousParameter(data))
    return;
  throw new ValidationError(exports.isNumericalContinuousParameter.errors);
}
function assertAnonymous1(data) {
  if (exports.isAnonymous1(data))
    return;
  throw new ValidationError(exports.isAnonymous1.errors);
}
function assertParameterValue(data) {
  if (exports.isParameterValue(data))
    return;
  throw new ValidationError(exports.isParameterValue.errors);
}
function assertParameterRecommendationValue(data) {
  if (exports.isParameterRecommendationValue(data))
    return;
  throw new ValidationError(exports.isParameterRecommendationValue.errors);
}
function assertParameter(data) {
  if (exports.isParameter(data))
    return;
  throw new ValidationError(exports.isParameter.errors);
}
function assertParameterRecommendation(data) {
  if (exports.isParameterRecommendation(data))
    return;
  throw new ValidationError(exports.isParameterRecommendation.errors);
}
function assertAcquisitionFunctionType(data) {
  if (exports.isAcquisitionFunctionType(data))
    return;
  throw new ValidationError(exports.isAcquisitionFunctionType.errors);
}
var AcquisitionFunctionType;
(function(AcquisitionFunctionType2) {
  AcquisitionFunctionType2["EI"] = "EI";
  AcquisitionFunctionType2["TS"] = "TS";
  AcquisitionFunctionType2["PI"] = "PI";
  AcquisitionFunctionType2["UCB"] = "UCB";
  AcquisitionFunctionType2["MeanMax"] = "MeanMax";
  AcquisitionFunctionType2["VarMax"] = "VarMax";
  AcquisitionFunctionType2["qLogNEI"] = "qLogNEI";
  AcquisitionFunctionType2["qSR"] = "qSR";
  AcquisitionFunctionType2["qNIPV"] = "qNIPV";
})(AcquisitionFunctionType || (AcquisitionFunctionType = {}));
function assertInitialGuessMethodType(data) {
  if (exports.isInitialGuessMethodType(data))
    return;
  throw new ValidationError(exports.isInitialGuessMethodType.errors);
}
var InitialGuessMethodType;
(function(InitialGuessMethodType2) {
  InitialGuessMethodType2["rand"] = "rand";
  InitialGuessMethodType2["pam"] = "pam";
  InitialGuessMethodType2["kmeans"] = "kmeans";
})(InitialGuessMethodType || (InitialGuessMethodType = {}));
function assertSurrogateModelType(data) {
  if (exports.isSurrogateModelType(data))
    return;
  throw new ValidationError(exports.isSurrogateModelType.errors);
}
var SurrogateModelType;
(function(SurrogateModelType2) {
  SurrogateModelType2["GP"] = "GP";
  SurrogateModelType2["RF"] = "RF";
  SurrogateModelType2["Linear"] = "Linear";
})(SurrogateModelType || (SurrogateModelType = {}));
function assertExpertOptions(data) {
  if (exports.isExpertOptions(data))
    return;
  throw new ValidationError(exports.isExpertOptions.errors);
}
function assertTargetVariable(data) {
  if (exports.isTargetVariable(data))
    return;
  throw new ValidationError(exports.isTargetVariable.errors);
}
function assertProjectRole(data) {
  if (exports.isProjectRole(data))
    return;
  throw new ValidationError(exports.isProjectRole.errors);
}
var ProjectRole;
(function(ProjectRole2) {
  ProjectRole2["Owner"] = "Owner";
  ProjectRole2["Editor"] = "Editor";
  ProjectRole2["Viewer"] = "Viewer";
})(ProjectRole || (ProjectRole = {}));
function assertProjectInvite(data) {
  if (exports.isProjectInvite(data))
    return;
  throw new ValidationError(exports.isProjectInvite.errors);
}
function assertProject(data) {
  if (exports.isProject(data))
    return;
  throw new ValidationError(exports.isProject.errors);
}
function assertExperimentResults(data) {
  if (exports.isExperimentResults(data))
    return;
  throw new ValidationError(exports.isExperimentResults.errors);
}
function assertExperiment(data) {
  if (exports.isExperiment(data))
    return;
  throw new ValidationError(exports.isExperiment.errors);
}
function assertRecommendation(data) {
  if (exports.isRecommendation(data))
    return;
  throw new ValidationError(exports.isRecommendation.errors);
}
function assertContinuousConstraint(data) {
  if (exports.isContinuousConstraint(data))
    return;
  throw new ValidationError(exports.isContinuousConstraint.errors);
}
function assertDiscreteConstraint(data) {
  if (exports.isDiscreteConstraint(data))
    return;
  throw new ValidationError(exports.isDiscreteConstraint.errors);
}
function assertConstraint(data) {
  if (exports.isConstraint(data))
    return;
  throw new ValidationError(exports.isConstraint.errors);
}
function assertSubSelectionCondition(data) {
  if (exports.isSubSelectionCondition(data))
    return;
  throw new ValidationError(exports.isSubSelectionCondition.errors);
}
function assertThresholdCondition(data) {
  if (exports.isThresholdCondition(data))
    return;
  throw new ValidationError(exports.isThresholdCondition.errors);
}
function assertDiscreteConditions(data) {
  if (exports.isDiscreteConditions(data))
    return;
  throw new ValidationError(exports.isDiscreteConditions.errors);
}
function assertStringArray(data) {
  if (exports.isStringArray(data))
    return;
  throw new ValidationError(exports.isStringArray.errors);
}
function assertGetUserResponse(data) {
  if (exports.isGetUserResponse(data))
    return;
  throw new ValidationError(exports.isGetUserResponse.errors);
}
function assertGetUserRequest(data) {
  if (exports.isGetUserRequest(data))
    return;
  throw new ValidationError(exports.isGetUserRequest.errors);
}
function assertListUserInvitesResponse(data) {
  if (exports.isListUserInvitesResponse(data))
    return;
  throw new ValidationError(exports.isListUserInvitesResponse.errors);
}
function assertListUserInvitesRequestQueryStringParameters(data) {
  if (exports.isListUserInvitesRequestQueryStringParameters(data))
    return;
  throw new ValidationError(exports.isListUserInvitesRequestQueryStringParameters.errors);
}
function assertListUserInvitesRequest(data) {
  if (exports.isListUserInvitesRequest(data))
    return;
  throw new ValidationError(exports.isListUserInvitesRequest.errors);
}
function assertListProjectsResponse(data) {
  if (exports.isListProjectsResponse(data))
    return;
  throw new ValidationError(exports.isListProjectsResponse.errors);
}
function assertListProjectsRequest(data) {
  if (exports.isListProjectsRequest(data))
    return;
  throw new ValidationError(exports.isListProjectsRequest.errors);
}
function assertAnonymous6(data) {
  if (exports.isAnonymous6(data))
    return;
  throw new ValidationError(exports.isAnonymous6.errors);
}
function assertAnonymous9Json(data) {
  if (exports.isAnonymous9Json(data))
    return;
  throw new ValidationError(exports.isAnonymous9Json.errors);
}
function assertAnonymous9(data) {
  if (exports.isAnonymous9(data))
    return;
  throw new ValidationError(exports.isAnonymous9.errors);
}
function assertCreateProjectResponse(data) {
  if (exports.isCreateProjectResponse(data))
    return;
  throw new ValidationError(exports.isCreateProjectResponse.errors);
}
function assertCreateProjectRequestJson(data) {
  if (exports.isCreateProjectRequestJson(data))
    return;
  throw new ValidationError(exports.isCreateProjectRequestJson.errors);
}
function assertCreateProjectRequest(data) {
  if (exports.isCreateProjectRequest(data))
    return;
  throw new ValidationError(exports.isCreateProjectRequest.errors);
}
function assertAnonymous10Json(data) {
  if (exports.isAnonymous10Json(data))
    return;
  throw new ValidationError(exports.isAnonymous10Json.errors);
}
function assertAnonymous10(data) {
  if (exports.isAnonymous10(data))
    return;
  throw new ValidationError(exports.isAnonymous10.errors);
}
function assertAnonymous11Json(data) {
  if (exports.isAnonymous11Json(data))
    return;
  throw new ValidationError(exports.isAnonymous11Json.errors);
}
function assertAnonymous11(data) {
  if (exports.isAnonymous11(data))
    return;
  throw new ValidationError(exports.isAnonymous11.errors);
}
function assertDeleteProjectResponse(data) {
  if (exports.isDeleteProjectResponse(data))
    return;
  throw new ValidationError(exports.isDeleteProjectResponse.errors);
}
function assertDeleteProjectRequestJson(data) {
  if (exports.isDeleteProjectRequestJson(data))
    return;
  throw new ValidationError(exports.isDeleteProjectRequestJson.errors);
}
function assertDeleteProjectRequest(data) {
  if (exports.isDeleteProjectRequest(data))
    return;
  throw new ValidationError(exports.isDeleteProjectRequest.errors);
}
function assertAnonymous12(data) {
  if (exports.isAnonymous12(data))
    return;
  throw new ValidationError(exports.isAnonymous12.errors);
}
function assertAnonymous15Json(data) {
  if (exports.isAnonymous15Json(data))
    return;
  throw new ValidationError(exports.isAnonymous15Json.errors);
}
function assertAnonymous15(data) {
  if (exports.isAnonymous15(data))
    return;
  throw new ValidationError(exports.isAnonymous15.errors);
}
function assertAnonymous16Json(data) {
  if (exports.isAnonymous16Json(data))
    return;
  throw new ValidationError(exports.isAnonymous16Json.errors);
}
function assertAnonymous16(data) {
  if (exports.isAnonymous16(data))
    return;
  throw new ValidationError(exports.isAnonymous16.errors);
}
function assertGetProjectByIdResponse(data) {
  if (exports.isGetProjectByIdResponse(data))
    return;
  throw new ValidationError(exports.isGetProjectByIdResponse.errors);
}
function assertGetProjectByIdRequestPathParameters(data) {
  if (exports.isGetProjectByIdRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isGetProjectByIdRequestPathParameters.errors);
}
function assertGetProjectByIdRequest(data) {
  if (exports.isGetProjectByIdRequest(data))
    return;
  throw new ValidationError(exports.isGetProjectByIdRequest.errors);
}
function assertAnonymous17(data) {
  if (exports.isAnonymous17(data))
    return;
  throw new ValidationError(exports.isAnonymous17.errors);
}
function assertAnonymous20Json(data) {
  if (exports.isAnonymous20Json(data))
    return;
  throw new ValidationError(exports.isAnonymous20Json.errors);
}
function assertAnonymous20(data) {
  if (exports.isAnonymous20(data))
    return;
  throw new ValidationError(exports.isAnonymous20.errors);
}
function assertAnonymous21Json(data) {
  if (exports.isAnonymous21Json(data))
    return;
  throw new ValidationError(exports.isAnonymous21Json.errors);
}
function assertAnonymous21(data) {
  if (exports.isAnonymous21(data))
    return;
  throw new ValidationError(exports.isAnonymous21.errors);
}
function assertAnonymous22Json(data) {
  if (exports.isAnonymous22Json(data))
    return;
  throw new ValidationError(exports.isAnonymous22Json.errors);
}
function assertAnonymous22(data) {
  if (exports.isAnonymous22(data))
    return;
  throw new ValidationError(exports.isAnonymous22.errors);
}
function assertExpandChemicalSpaceResponse(data) {
  if (exports.isExpandChemicalSpaceResponse(data))
    return;
  throw new ValidationError(exports.isExpandChemicalSpaceResponse.errors);
}
function assertExpandChemicalSpaceRequestPathParameters(data) {
  if (exports.isExpandChemicalSpaceRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isExpandChemicalSpaceRequestPathParameters.errors);
}
function assertExpandChemicalSpaceRequestJsonExperiments(data) {
  if (exports.isExpandChemicalSpaceRequestJsonExperiments(data))
    return;
  throw new ValidationError(exports.isExpandChemicalSpaceRequestJsonExperiments.errors);
}
function assertExpandChemicalSpaceRequestJson(data) {
  if (exports.isExpandChemicalSpaceRequestJson(data))
    return;
  throw new ValidationError(exports.isExpandChemicalSpaceRequestJson.errors);
}
function assertExpandChemicalSpaceRequest(data) {
  if (exports.isExpandChemicalSpaceRequest(data))
    return;
  throw new ValidationError(exports.isExpandChemicalSpaceRequest.errors);
}
function assertCloseProjectResponseJson(data) {
  if (exports.isCloseProjectResponseJson(data))
    return;
  throw new ValidationError(exports.isCloseProjectResponseJson.errors);
}
function assertCloseProjectResponse(data) {
  if (exports.isCloseProjectResponse(data))
    return;
  throw new ValidationError(exports.isCloseProjectResponse.errors);
}
function assertCloseProjectRequestPathParameters(data) {
  if (exports.isCloseProjectRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isCloseProjectRequestPathParameters.errors);
}
function assertCloseProjectRequest(data) {
  if (exports.isCloseProjectRequest(data))
    return;
  throw new ValidationError(exports.isCloseProjectRequest.errors);
}
function assertReopenProjectResponseJson(data) {
  if (exports.isReopenProjectResponseJson(data))
    return;
  throw new ValidationError(exports.isReopenProjectResponseJson.errors);
}
function assertReopenProjectResponse(data) {
  if (exports.isReopenProjectResponse(data))
    return;
  throw new ValidationError(exports.isReopenProjectResponse.errors);
}
function assertReopenProjectRequestPathParameters(data) {
  if (exports.isReopenProjectRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isReopenProjectRequestPathParameters.errors);
}
function assertReopenProjectRequest(data) {
  if (exports.isReopenProjectRequest(data))
    return;
  throw new ValidationError(exports.isReopenProjectRequest.errors);
}
function assertListInvitesResponse(data) {
  if (exports.isListInvitesResponse(data))
    return;
  throw new ValidationError(exports.isListInvitesResponse.errors);
}
function assertListInvitesRequestPathParameters(data) {
  if (exports.isListInvitesRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isListInvitesRequestPathParameters.errors);
}
function assertListInvitesRequest(data) {
  if (exports.isListInvitesRequest(data))
    return;
  throw new ValidationError(exports.isListInvitesRequest.errors);
}
function assertSendInviteResponse(data) {
  if (exports.isSendInviteResponse(data))
    return;
  throw new ValidationError(exports.isSendInviteResponse.errors);
}
function assertSendInviteRequestPathParameters(data) {
  if (exports.isSendInviteRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isSendInviteRequestPathParameters.errors);
}
function assertSendInviteRequestJson(data) {
  if (exports.isSendInviteRequestJson(data))
    return;
  throw new ValidationError(exports.isSendInviteRequestJson.errors);
}
function assertSendInviteRequest(data) {
  if (exports.isSendInviteRequest(data))
    return;
  throw new ValidationError(exports.isSendInviteRequest.errors);
}
function assertGetInviteByIdResponse(data) {
  if (exports.isGetInviteByIdResponse(data))
    return;
  throw new ValidationError(exports.isGetInviteByIdResponse.errors);
}
function assertGetInviteByIdRequestPathParameters(data) {
  if (exports.isGetInviteByIdRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isGetInviteByIdRequestPathParameters.errors);
}
function assertGetInviteByIdRequest(data) {
  if (exports.isGetInviteByIdRequest(data))
    return;
  throw new ValidationError(exports.isGetInviteByIdRequest.errors);
}
function assertAnonymous23(data) {
  if (exports.isAnonymous23(data))
    return;
  throw new ValidationError(exports.isAnonymous23.errors);
}
function assertAnonymous24Json(data) {
  if (exports.isAnonymous24Json(data))
    return;
  throw new ValidationError(exports.isAnonymous24Json.errors);
}
function assertAnonymous24(data) {
  if (exports.isAnonymous24(data))
    return;
  throw new ValidationError(exports.isAnonymous24.errors);
}
function assertChangeInviteStatusResponse(data) {
  if (exports.isChangeInviteStatusResponse(data))
    return;
  throw new ValidationError(exports.isChangeInviteStatusResponse.errors);
}
function assertChangeInviteStatusRequestPathParameters(data) {
  if (exports.isChangeInviteStatusRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isChangeInviteStatusRequestPathParameters.errors);
}
function assertChangeInviteStatusRequestJson(data) {
  if (exports.isChangeInviteStatusRequestJson(data))
    return;
  throw new ValidationError(exports.isChangeInviteStatusRequestJson.errors);
}
function assertChangeInviteStatusRequest(data) {
  if (exports.isChangeInviteStatusRequest(data))
    return;
  throw new ValidationError(exports.isChangeInviteStatusRequest.errors);
}
function assertDeleteInviteResponseJson(data) {
  if (exports.isDeleteInviteResponseJson(data))
    return;
  throw new ValidationError(exports.isDeleteInviteResponseJson.errors);
}
function assertDeleteInviteResponse(data) {
  if (exports.isDeleteInviteResponse(data))
    return;
  throw new ValidationError(exports.isDeleteInviteResponse.errors);
}
function assertDeleteInviteRequestPathParameters(data) {
  if (exports.isDeleteInviteRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isDeleteInviteRequestPathParameters.errors);
}
function assertDeleteInviteRequest(data) {
  if (exports.isDeleteInviteRequest(data))
    return;
  throw new ValidationError(exports.isDeleteInviteRequest.errors);
}
function assertAnonymous25Json(data) {
  if (exports.isAnonymous25Json(data))
    return;
  throw new ValidationError(exports.isAnonymous25Json.errors);
}
function assertAnonymous25(data) {
  if (exports.isAnonymous25(data))
    return;
  throw new ValidationError(exports.isAnonymous25.errors);
}
function assertAnonymous26Json(data) {
  if (exports.isAnonymous26Json(data))
    return;
  throw new ValidationError(exports.isAnonymous26Json.errors);
}
function assertAnonymous26(data) {
  if (exports.isAnonymous26(data))
    return;
  throw new ValidationError(exports.isAnonymous26.errors);
}
function assertAnonymous27Json(data) {
  if (exports.isAnonymous27Json(data))
    return;
  throw new ValidationError(exports.isAnonymous27Json.errors);
}
function assertAnonymous27(data) {
  if (exports.isAnonymous27(data))
    return;
  throw new ValidationError(exports.isAnonymous27.errors);
}
function assertCreateRecommendationsResponse(data) {
  if (exports.isCreateRecommendationsResponse(data))
    return;
  throw new ValidationError(exports.isCreateRecommendationsResponse.errors);
}
function assertCreateRecommendationsRequestPathParameters(data) {
  if (exports.isCreateRecommendationsRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isCreateRecommendationsRequestPathParameters.errors);
}
function assertCreateRecommendationsRequestQueryStringParameters(data) {
  if (exports.isCreateRecommendationsRequestQueryStringParameters(data))
    return;
  throw new ValidationError(exports.isCreateRecommendationsRequestQueryStringParameters.errors);
}
function assertCreateRecommendationsRequestJson(data) {
  if (exports.isCreateRecommendationsRequestJson(data))
    return;
  throw new ValidationError(exports.isCreateRecommendationsRequestJson.errors);
}
function assertCreateRecommendationsRequest(data) {
  if (exports.isCreateRecommendationsRequest(data))
    return;
  throw new ValidationError(exports.isCreateRecommendationsRequest.errors);
}
function assertAnonymous28(data) {
  if (exports.isAnonymous28(data))
    return;
  throw new ValidationError(exports.isAnonymous28.errors);
}
function assertAnonymous29Json(data) {
  if (exports.isAnonymous29Json(data))
    return;
  throw new ValidationError(exports.isAnonymous29Json.errors);
}
function assertAnonymous29(data) {
  if (exports.isAnonymous29(data))
    return;
  throw new ValidationError(exports.isAnonymous29.errors);
}
function assertAnonymous30Json(data) {
  if (exports.isAnonymous30Json(data))
    return;
  throw new ValidationError(exports.isAnonymous30Json.errors);
}
function assertAnonymous30(data) {
  if (exports.isAnonymous30(data))
    return;
  throw new ValidationError(exports.isAnonymous30.errors);
}
function assertListRecommendationsResponse(data) {
  if (exports.isListRecommendationsResponse(data))
    return;
  throw new ValidationError(exports.isListRecommendationsResponse.errors);
}
function assertListRecommendationsRequestPathParameters(data) {
  if (exports.isListRecommendationsRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isListRecommendationsRequestPathParameters.errors);
}
function assertListRecommendationsRequestQueryStringParameters(data) {
  if (exports.isListRecommendationsRequestQueryStringParameters(data))
    return;
  throw new ValidationError(exports.isListRecommendationsRequestQueryStringParameters.errors);
}
function assertListRecommendationsRequest(data) {
  if (exports.isListRecommendationsRequest(data))
    return;
  throw new ValidationError(exports.isListRecommendationsRequest.errors);
}
function assertAnonymous31Json(data) {
  if (exports.isAnonymous31Json(data))
    return;
  throw new ValidationError(exports.isAnonymous31Json.errors);
}
function assertAnonymous31(data) {
  if (exports.isAnonymous31(data))
    return;
  throw new ValidationError(exports.isAnonymous31.errors);
}
function assertAnonymous32Json(data) {
  if (exports.isAnonymous32Json(data))
    return;
  throw new ValidationError(exports.isAnonymous32Json.errors);
}
function assertAnonymous32(data) {
  if (exports.isAnonymous32(data))
    return;
  throw new ValidationError(exports.isAnonymous32.errors);
}
function assertAnonymous33Json(data) {
  if (exports.isAnonymous33Json(data))
    return;
  throw new ValidationError(exports.isAnonymous33Json.errors);
}
function assertAnonymous33(data) {
  if (exports.isAnonymous33(data))
    return;
  throw new ValidationError(exports.isAnonymous33.errors);
}
function assertRejectRecommendationResponse(data) {
  if (exports.isRejectRecommendationResponse(data))
    return;
  throw new ValidationError(exports.isRejectRecommendationResponse.errors);
}
function assertRejectRecommendationRequestPathParameters(data) {
  if (exports.isRejectRecommendationRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isRejectRecommendationRequestPathParameters.errors);
}
function assertRejectRecommendationRequest(data) {
  if (exports.isRejectRecommendationRequest(data))
    return;
  throw new ValidationError(exports.isRejectRecommendationRequest.errors);
}
function assertAnonymous34(data) {
  if (exports.isAnonymous34(data))
    return;
  throw new ValidationError(exports.isAnonymous34.errors);
}
function assertAnonymous35Json(data) {
  if (exports.isAnonymous35Json(data))
    return;
  throw new ValidationError(exports.isAnonymous35Json.errors);
}
function assertAnonymous35(data) {
  if (exports.isAnonymous35(data))
    return;
  throw new ValidationError(exports.isAnonymous35.errors);
}
function assertAnonymous36Json(data) {
  if (exports.isAnonymous36Json(data))
    return;
  throw new ValidationError(exports.isAnonymous36Json.errors);
}
function assertAnonymous36(data) {
  if (exports.isAnonymous36(data))
    return;
  throw new ValidationError(exports.isAnonymous36.errors);
}
function assertListExperimentsResponse(data) {
  if (exports.isListExperimentsResponse(data))
    return;
  throw new ValidationError(exports.isListExperimentsResponse.errors);
}
function assertListExperimentsRequestPathParameters(data) {
  if (exports.isListExperimentsRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isListExperimentsRequestPathParameters.errors);
}
function assertListExperimentsRequest(data) {
  if (exports.isListExperimentsRequest(data))
    return;
  throw new ValidationError(exports.isListExperimentsRequest.errors);
}
function assertAnonymous37(data) {
  if (exports.isAnonymous37(data))
    return;
  throw new ValidationError(exports.isAnonymous37.errors);
}
function assertAnonymous38Json(data) {
  if (exports.isAnonymous38Json(data))
    return;
  throw new ValidationError(exports.isAnonymous38Json.errors);
}
function assertAnonymous38(data) {
  if (exports.isAnonymous38(data))
    return;
  throw new ValidationError(exports.isAnonymous38.errors);
}
function assertAnonymous39Json(data) {
  if (exports.isAnonymous39Json(data))
    return;
  throw new ValidationError(exports.isAnonymous39Json.errors);
}
function assertAnonymous39(data) {
  if (exports.isAnonymous39(data))
    return;
  throw new ValidationError(exports.isAnonymous39.errors);
}
function assertAnonymous40Json(data) {
  if (exports.isAnonymous40Json(data))
    return;
  throw new ValidationError(exports.isAnonymous40Json.errors);
}
function assertAnonymous40(data) {
  if (exports.isAnonymous40(data))
    return;
  throw new ValidationError(exports.isAnonymous40.errors);
}
function assertCreateExperimentsResponse(data) {
  if (exports.isCreateExperimentsResponse(data))
    return;
  throw new ValidationError(exports.isCreateExperimentsResponse.errors);
}
function assertCreateExperimentsRequestPathParameters(data) {
  if (exports.isCreateExperimentsRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isCreateExperimentsRequestPathParameters.errors);
}
function assertCreateExperimentsRequestJsonItemResults(data) {
  if (exports.isCreateExperimentsRequestJsonItemResults(data))
    return;
  throw new ValidationError(exports.isCreateExperimentsRequestJsonItemResults.errors);
}
function assertCreateExperimentsRequestJsonItem(data) {
  if (exports.isCreateExperimentsRequestJsonItem(data))
    return;
  throw new ValidationError(exports.isCreateExperimentsRequestJsonItem.errors);
}
function assertCreateExperimentsRequestJson(data) {
  if (exports.isCreateExperimentsRequestJson(data))
    return;
  throw new ValidationError(exports.isCreateExperimentsRequestJson.errors);
}
function assertCreateExperimentsRequest(data) {
  if (exports.isCreateExperimentsRequest(data))
    return;
  throw new ValidationError(exports.isCreateExperimentsRequest.errors);
}
function assertAnonymous41(data) {
  if (exports.isAnonymous41(data))
    return;
  throw new ValidationError(exports.isAnonymous41.errors);
}
function assertAnonymous42Json(data) {
  if (exports.isAnonymous42Json(data))
    return;
  throw new ValidationError(exports.isAnonymous42Json.errors);
}
function assertAnonymous42(data) {
  if (exports.isAnonymous42(data))
    return;
  throw new ValidationError(exports.isAnonymous42.errors);
}
function assertAnonymous43Json(data) {
  if (exports.isAnonymous43Json(data))
    return;
  throw new ValidationError(exports.isAnonymous43Json.errors);
}
function assertAnonymous43(data) {
  if (exports.isAnonymous43(data))
    return;
  throw new ValidationError(exports.isAnonymous43.errors);
}
function assertChangeHistoryResponse(data) {
  if (exports.isChangeHistoryResponse(data))
    return;
  throw new ValidationError(exports.isChangeHistoryResponse.errors);
}
function assertChangeHistoryRequestPathParameters(data) {
  if (exports.isChangeHistoryRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isChangeHistoryRequestPathParameters.errors);
}
function assertChangeHistoryRequestJsonDeleteItem(data) {
  if (exports.isChangeHistoryRequestJsonDeleteItem(data))
    return;
  throw new ValidationError(exports.isChangeHistoryRequestJsonDeleteItem.errors);
}
function assertChangeHistoryRequestJsonDelete(data) {
  if (exports.isChangeHistoryRequestJsonDelete(data))
    return;
  throw new ValidationError(exports.isChangeHistoryRequestJsonDelete.errors);
}
function assertChangeHistoryRequestJsonModifyItemResults(data) {
  if (exports.isChangeHistoryRequestJsonModifyItemResults(data))
    return;
  throw new ValidationError(exports.isChangeHistoryRequestJsonModifyItemResults.errors);
}
function assertChangeHistoryRequestJsonModifyItem(data) {
  if (exports.isChangeHistoryRequestJsonModifyItem(data))
    return;
  throw new ValidationError(exports.isChangeHistoryRequestJsonModifyItem.errors);
}
function assertChangeHistoryRequestJsonModify(data) {
  if (exports.isChangeHistoryRequestJsonModify(data))
    return;
  throw new ValidationError(exports.isChangeHistoryRequestJsonModify.errors);
}
function assertChangeHistoryRequestJson(data) {
  if (exports.isChangeHistoryRequestJson(data))
    return;
  throw new ValidationError(exports.isChangeHistoryRequestJson.errors);
}
function assertChangeHistoryRequest(data) {
  if (exports.isChangeHistoryRequest(data))
    return;
  throw new ValidationError(exports.isChangeHistoryRequest.errors);
}
function assertListConstraintsResponse(data) {
  if (exports.isListConstraintsResponse(data))
    return;
  throw new ValidationError(exports.isListConstraintsResponse.errors);
}
function assertListConstraintsRequestPathParameters(data) {
  if (exports.isListConstraintsRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isListConstraintsRequestPathParameters.errors);
}
function assertListConstraintsRequest(data) {
  if (exports.isListConstraintsRequest(data))
    return;
  throw new ValidationError(exports.isListConstraintsRequest.errors);
}
function assertAnonymous44Json(data) {
  if (exports.isAnonymous44Json(data))
    return;
  throw new ValidationError(exports.isAnonymous44Json.errors);
}
function assertAnonymous44(data) {
  if (exports.isAnonymous44(data))
    return;
  throw new ValidationError(exports.isAnonymous44.errors);
}
function assertAnonymous45Json(data) {
  if (exports.isAnonymous45Json(data))
    return;
  throw new ValidationError(exports.isAnonymous45Json.errors);
}
function assertAnonymous45(data) {
  if (exports.isAnonymous45(data))
    return;
  throw new ValidationError(exports.isAnonymous45.errors);
}
function assertAnonymous46Json(data) {
  if (exports.isAnonymous46Json(data))
    return;
  throw new ValidationError(exports.isAnonymous46Json.errors);
}
function assertAnonymous46(data) {
  if (exports.isAnonymous46(data))
    return;
  throw new ValidationError(exports.isAnonymous46.errors);
}
function assertGetBaybeConfigsResponse(data) {
  if (exports.isGetBaybeConfigsResponse(data))
    return;
  throw new ValidationError(exports.isGetBaybeConfigsResponse.errors);
}
function assertGetBaybeConfigsRequestPathParameters(data) {
  if (exports.isGetBaybeConfigsRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isGetBaybeConfigsRequestPathParameters.errors);
}
function assertGetBaybeConfigsRequest(data) {
  if (exports.isGetBaybeConfigsRequest(data))
    return;
  throw new ValidationError(exports.isGetBaybeConfigsRequest.errors);
}
function assertCreateFeedbackResponseJson(data) {
  if (exports.isCreateFeedbackResponseJson(data))
    return;
  throw new ValidationError(exports.isCreateFeedbackResponseJson.errors);
}
function assertCreateFeedbackResponse(data) {
  if (exports.isCreateFeedbackResponse(data))
    return;
  throw new ValidationError(exports.isCreateFeedbackResponse.errors);
}
function assertCreateFeedbackRequestJson(data) {
  if (exports.isCreateFeedbackRequestJson(data))
    return;
  throw new ValidationError(exports.isCreateFeedbackRequestJson.errors);
}
function assertCreateFeedbackRequest(data) {
  if (exports.isCreateFeedbackRequest(data))
    return;
  throw new ValidationError(exports.isCreateFeedbackRequest.errors);
}
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidationError = void 0;
var isNumber = new RegExp(/^\d+$/);
var ValidationError = class extends Error {
  constructor(errors) {
    super();
    this.name = "ValidationError";
    this.errors = this.translate([...errors]);
  }
  translate(input) {
    const errors = [];
    for (const error of input) {
      const [_, rawField, nested] = error.instancePath.split("/");
      const field = rawField !== void 0 && rawField.length && !isNumber.test(rawField) ? { field: rawField } : {};
      if (nested || isNumber.test(rawField)) {
        const err = this.translate([{
          ...error,
          instancePath: "/"
        }]);
        errors.push({
          name: "Nested",
          ...field,
          index: parseInt(nested !== null && nested !== void 0 ? nested : rawField, 10),
          errors: err
        });
      } else if (error.keyword === "enum") {
        errors.push({
          name: "Enum",
          ...field
        });
      } else if (error.keyword === "required") {
        errors.push({
          name: "Required",
          field: error.params.missingProperty
        });
      } else if (error.keyword === "type") {
        errors.push({
          name: "TypeMismatch",
          expected: error.params.type,
          ...field
        });
      } else if (error.keyword === "additionalProperties") {
        errors.push({
          name: "AdditionalProperties",
          actual: [error.params.additionalProperty],
          expected: [],
          ...field
        });
      }
    }
    return errors;
  }
  toString() {
    return JSON.stringify(this.errors);
  }
};
exports.ValidationError = ValidationError;
exports.isUser = validate21;
function validate21(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.email === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "email" }, message: "must have required property 'email'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.userId !== void 0) {
      if (typeof data.userId !== "string") {
        const err1 = { instancePath: instancePath + "/userId", schemaPath: "#/properties/userId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
    if (data.email !== void 0) {
      if (typeof data.email !== "string") {
        const err2 = { instancePath: instancePath + "/email", schemaPath: "#/properties/email/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.name !== void 0) {
      if (typeof data.name !== "string") {
        const err3 = { instancePath: instancePath + "/name", schemaPath: "#/properties/name/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate21.errors = vErrors;
  return errors === 0;
}
exports.isBatchQueryParameterStringValue = validate22;
var schema7 = { "type": "string", "enum": ["all", "latest"], "default": "latest" };
var func0 = require_equal().default;
function validate22(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (!(data === "all" || data === "latest")) {
    const err1 = { instancePath, schemaPath: "#/enum", keyword: "enum", params: { allowedValues: schema7.enum }, message: "must be equal to one of the allowed values" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate22.errors = vErrors;
  return errors === 0;
}
exports.isBatchQueryParameterNumericValue = validate23;
function validate23(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(typeof data == "number" && isFinite(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "number" }, message: "must be number" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate23.errors = vErrors;
  return errors === 0;
}
exports.isBatchQueryParameterValue = validate24;
function validate24(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  const _errs0 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs1 = errors;
  if (!validate22(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate22.errors : vErrors.concat(validate22.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs1 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs2 = errors;
  if (!validate23(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate23.errors : vErrors.concat(validate23.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
  }
  if (!valid0) {
    const err0 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  } else {
    errors = _errs0;
    if (vErrors !== null) {
      if (_errs0) {
        vErrors.length = _errs0;
      } else {
        vErrors = null;
      }
    }
  }
  validate24.errors = vErrors;
  return errors === 0;
}
exports.isProjectStatus = validate27;
var schema10 = { "type": "string", "enum": ["creating", "draft", "idle", "error", "running", "closed"] };
function validate27(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (!(data === "creating" || data === "draft" || data === "idle" || data === "error" || data === "running" || data === "closed")) {
    const err1 = { instancePath, schemaPath: "#/enum", keyword: "enum", params: { allowedValues: schema10.enum }, message: "must be equal to one of the allowed values" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate27.errors = vErrors;
  return errors === 0;
}
exports.isExperimentStatus = validate28;
var schema11 = { "type": "string", "enum": ["creating", "running", "finished", "error"] };
function validate28(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (!(data === "creating" || data === "running" || data === "finished" || data === "error")) {
    const err1 = { instancePath, schemaPath: "#/enum", keyword: "enum", params: { allowedValues: schema11.enum }, message: "must be equal to one of the allowed values" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate28.errors = vErrors;
  return errors === 0;
}
exports.isRecommendationStatus = validate29;
var schema12 = { "type": "string", "enum": ["created", "rejected", "finished", "error"] };
function validate29(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (!(data === "created" || data === "rejected" || data === "finished" || data === "error")) {
    const err1 = { instancePath, schemaPath: "#/enum", keyword: "enum", params: { allowedValues: schema12.enum }, message: "must be equal to one of the allowed values" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate29.errors = vErrors;
  return errors === 0;
}
exports.isParameterType = validate30;
var schema13 = { "type": "string", "enum": ["smiles", "numeric", "category", "custom", "task", "continuous"], "example": "numeric" };
function validate30(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (!(data === "smiles" || data === "numeric" || data === "category" || data === "custom" || data === "task" || data === "continuous")) {
    const err1 = { instancePath, schemaPath: "#/enum", keyword: "enum", params: { allowedValues: schema13.enum }, message: "must be equal to one of the allowed values" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate30.errors = vErrors;
  return errors === 0;
}
exports.isConstraintsType = validate31;
var schema14 = { "type": "string", "enum": ["continuous", "discrete"] };
function validate31(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (!(data === "continuous" || data === "discrete")) {
    const err1 = { instancePath, schemaPath: "#/enum", keyword: "enum", params: { allowedValues: schema14.enum }, message: "must be equal to one of the allowed values" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate31.errors = vErrors;
  return errors === 0;
}
exports.isConstraintsSubTypeContinuous = validate32;
var schema15 = { "type": "string", "enum": ["ContinuousLinearConstraint"] };
function validate32(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (!(data === "ContinuousLinearConstraint")) {
    const err1 = { instancePath, schemaPath: "#/enum", keyword: "enum", params: { allowedValues: schema15.enum }, message: "must be equal to one of the allowed values" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate32.errors = vErrors;
  return errors === 0;
}
exports.isConstraintsSubTypeDiscrete = validate33;
var schema16 = { "type": "string", "enum": ["DiscreteExcludeConstraint", "DiscreteSumConstraint", "DiscreteProductConstraint", "DiscreteNoLabelDuplicatesConstraint", "DiscreteLinkedParametersConstraint", "DiscreteDependenciesConstraint"] };
function validate33(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (!(data === "DiscreteExcludeConstraint" || data === "DiscreteSumConstraint" || data === "DiscreteProductConstraint" || data === "DiscreteNoLabelDuplicatesConstraint" || data === "DiscreteLinkedParametersConstraint" || data === "DiscreteDependenciesConstraint")) {
    const err1 = { instancePath, schemaPath: "#/enum", keyword: "enum", params: { allowedValues: schema16.enum }, message: "must be equal to one of the allowed values" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate33.errors = vErrors;
  return errors === 0;
}
exports.isConditionsType = validate34;
var schema17 = { "type": "string", "enum": ["threshold", "subselection"] };
function validate34(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (!(data === "threshold" || data === "subselection")) {
    const err1 = { instancePath, schemaPath: "#/enum", keyword: "enum", params: { allowedValues: schema17.enum }, message: "must be equal to one of the allowed values" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate34.errors = vErrors;
  return errors === 0;
}
exports.isConstraintContinuousOperatorType = validate35;
var schema18 = { "type": "string", "enum": ["=", ">=", "<="] };
function validate35(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (!(data === "=" || data === ">=" || data === "<=")) {
    const err1 = { instancePath, schemaPath: "#/enum", keyword: "enum", params: { allowedValues: schema18.enum }, message: "must be equal to one of the allowed values" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate35.errors = vErrors;
  return errors === 0;
}
exports.isConstraintDiscreteConditionOperatorType = validate36;
var schema19 = { "type": "string", "enum": ["=", "!=", ">=", ">", "<=", "<"] };
function validate36(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (!(data === "=" || data === "!=" || data === ">=" || data === ">" || data === "<=" || data === "<")) {
    const err1 = { instancePath, schemaPath: "#/enum", keyword: "enum", params: { allowedValues: schema19.enum }, message: "must be equal to one of the allowed values" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate36.errors = vErrors;
  return errors === 0;
}
exports.isCombinerType = validate37;
var schema20 = { "type": "string", "enum": ["AND", "OR", "XOR"] };
function validate37(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (!(data === "AND" || data === "OR" || data === "XOR")) {
    const err1 = { instancePath, schemaPath: "#/enum", keyword: "enum", params: { allowedValues: schema20.enum }, message: "must be equal to one of the allowed values" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate37.errors = vErrors;
  return errors === 0;
}
exports.isSmilesParameterValue = validate38;
function validate38(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.Name === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "Name" }, message: "must have required property 'Name'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    for (const key0 in data) {
      if (!(key0 === "Name" || key0 === "SMILES")) {
        const err1 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
    if (data.Name !== void 0) {
      if (typeof data.Name !== "string") {
        const err2 = { instancePath: instancePath + "/Name", schemaPath: "#/properties/Name/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.SMILES !== void 0) {
      if (typeof data.SMILES !== "string") {
        const err3 = { instancePath: instancePath + "/SMILES", schemaPath: "#/properties/SMILES/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate38.errors = vErrors;
  return errors === 0;
}
exports.isStringParameterValue = validate39;
function validate39(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate39.errors = vErrors;
  return errors === 0;
}
exports.isNumericParameterValue = validate40;
function validate40(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(typeof data == "number" && isFinite(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "number" }, message: "must be number" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate40.errors = vErrors;
  return errors === 0;
}
exports.isCustomParameterValue = validate41;
function validate41(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      if (typeof data[key0] !== "string") {
        const err0 = { instancePath: instancePath + "/" + key0.replace(/~/g, "~0").replace(/\//g, "~1"), schemaPath: "#/additionalProperties/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err0];
        } else {
          vErrors.push(err0);
        }
        errors++;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate41.errors = vErrors;
  return errors === 0;
}
exports.isNumericalContinuousParameter = validate42;
function validate42(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (Array.isArray(data)) {
    const len0 = data.length;
    for (let i0 = 0; i0 < len0; i0++) {
      let data0 = data[i0];
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err0 = { instancePath: instancePath + "/" + i0, schemaPath: "#/items/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err0];
        } else {
          vErrors.push(err0);
        }
        errors++;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "array" }, message: "must be array" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err2 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
  }
  validate42.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous1 = validate43;
function validate43(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
  } else {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate43.errors = vErrors;
  return errors === 0;
}
exports.isParameterValue = validate44;
function validate44(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  const _errs0 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs1 = errors;
  if (!(typeof data == "number" && isFinite(data))) {
    const err0 = { instancePath, schemaPath: "#/oneOf/0/type", keyword: "type", params: { type: "number" }, message: "must be number" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  var _valid0 = _errs1 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (typeof data !== "string") {
    const err1 = { instancePath, schemaPath: "#/oneOf/1/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
    const _errs5 = errors;
    if (!validate43(data, { instancePath, parentData, parentDataProperty, rootData })) {
      vErrors = vErrors === null ? validate43.errors : vErrors.concat(validate43.errors);
      errors = vErrors.length;
    }
    var _valid0 = _errs5 === errors;
    if (_valid0 && valid0) {
      valid0 = false;
      passing0 = [passing0, 2];
    } else {
      if (_valid0) {
        valid0 = true;
        passing0 = 2;
      }
    }
  }
  if (!valid0) {
    const err2 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  } else {
    errors = _errs0;
    if (vErrors !== null) {
      if (_errs0) {
        vErrors.length = _errs0;
      } else {
        vErrors = null;
      }
    }
  }
  validate44.errors = vErrors;
  return errors === 0;
}
exports.isParameterRecommendationValue = validate46;
function validate46(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  const _errs0 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs1 = errors;
  if (!validate39(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate39.errors : vErrors.concat(validate39.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs1 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs2 = errors;
  if (!validate40(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate40.errors : vErrors.concat(validate40.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
  }
  if (!valid0) {
    const err0 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  } else {
    errors = _errs0;
    if (vErrors !== null) {
      if (_errs0) {
        vErrors.length = _errs0;
      } else {
        vErrors = null;
      }
    }
  }
  validate46.errors = vErrors;
  return errors === 0;
}
exports.isParameter = validate49;
function validate49(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.parameterName === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "parameterName" }, message: "must have required property 'parameterName'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.parameterType === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "parameterType" }, message: "must have required property 'parameterType'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.parameterValues === void 0) {
      const err2 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "parameterValues" }, message: "must have required property 'parameterValues'" };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    if (data.parameterName !== void 0) {
      if (typeof data.parameterName !== "string") {
        const err3 = { instancePath: instancePath + "/parameterName", schemaPath: "#/properties/parameterName/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.parameterType !== void 0) {
      if (!validate30(data.parameterType, { instancePath: instancePath + "/parameterType", parentData: data, parentDataProperty: "parameterType", rootData })) {
        vErrors = vErrors === null ? validate30.errors : vErrors.concat(validate30.errors);
        errors = vErrors.length;
      }
    }
    if (data.parameterValues !== void 0) {
      let data2 = data.parameterValues;
      if (Array.isArray(data2)) {
        const len0 = data2.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (!validate44(data2[i0], { instancePath: instancePath + "/parameterValues/" + i0, parentData: data2, parentDataProperty: i0, rootData })) {
            vErrors = vErrors === null ? validate44.errors : vErrors.concat(validate44.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err4 = { instancePath: instancePath + "/parameterValues", schemaPath: "#/properties/parameterValues/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
    if (data.activeValues !== void 0) {
      let data4 = data.activeValues;
      if (Array.isArray(data4)) {
        const len1 = data4.length;
        for (let i1 = 0; i1 < len1; i1++) {
          if (!validate44(data4[i1], { instancePath: instancePath + "/activeValues/" + i1, parentData: data4, parentDataProperty: i1, rootData })) {
            vErrors = vErrors === null ? validate44.errors : vErrors.concat(validate44.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err5 = { instancePath: instancePath + "/activeValues", schemaPath: "#/properties/activeValues/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err5];
        } else {
          vErrors.push(err5);
        }
        errors++;
      }
    }
  } else {
    const err6 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err6];
    } else {
      vErrors.push(err6);
    }
    errors++;
  }
  validate49.errors = vErrors;
  return errors === 0;
}
exports.isParameterRecommendation = validate53;
function validate53(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      if (!validate46(data[key0], { instancePath: instancePath + "/" + key0.replace(/~/g, "~0").replace(/\//g, "~1"), parentData: data, parentDataProperty: key0, rootData })) {
        vErrors = vErrors === null ? validate46.errors : vErrors.concat(validate46.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate53.errors = vErrors;
  return errors === 0;
}
exports.isAcquisitionFunctionType = validate55;
var schema31 = { "type": "string", "default": "EI", "enum": ["EI", "TS", "PI", "UCB", "MeanMax", "VarMax", "qLogNEI", "qSR", "qNIPV"] };
function validate55(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (!(data === "EI" || data === "TS" || data === "PI" || data === "UCB" || data === "MeanMax" || data === "VarMax" || data === "qLogNEI" || data === "qSR" || data === "qNIPV")) {
    const err1 = { instancePath, schemaPath: "#/enum", keyword: "enum", params: { allowedValues: schema31.enum }, message: "must be equal to one of the allowed values" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate55.errors = vErrors;
  return errors === 0;
}
exports.isInitialGuessMethodType = validate56;
var schema32 = { "type": "string", "default": "rand", "enum": ["rand", "pam", "kmeans"] };
function validate56(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (!(data === "rand" || data === "pam" || data === "kmeans")) {
    const err1 = { instancePath, schemaPath: "#/enum", keyword: "enum", params: { allowedValues: schema32.enum }, message: "must be equal to one of the allowed values" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate56.errors = vErrors;
  return errors === 0;
}
exports.isSurrogateModelType = validate57;
var schema33 = { "type": "string", "default": "GP", "enum": ["GP", "RF", "Linear"] };
function validate57(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (!(data === "GP" || data === "RF" || data === "Linear")) {
    const err1 = { instancePath, schemaPath: "#/enum", keyword: "enum", params: { allowedValues: schema33.enum }, message: "must be equal to one of the allowed values" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate57.errors = vErrors;
  return errors === 0;
}
exports.isExpertOptions = validate58;
function validate58(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.acquisitionFunction !== void 0) {
      if (!validate55(data.acquisitionFunction, { instancePath: instancePath + "/acquisitionFunction", parentData: data, parentDataProperty: "acquisitionFunction", rootData })) {
        vErrors = vErrors === null ? validate55.errors : vErrors.concat(validate55.errors);
        errors = vErrors.length;
      }
    }
    if (data.initialGuessMethod !== void 0) {
      if (!validate56(data.initialGuessMethod, { instancePath: instancePath + "/initialGuessMethod", parentData: data, parentDataProperty: "initialGuessMethod", rootData })) {
        vErrors = vErrors === null ? validate56.errors : vErrors.concat(validate56.errors);
        errors = vErrors.length;
      }
    }
    if (data.surrogateModel !== void 0) {
      if (!validate57(data.surrogateModel, { instancePath: instancePath + "/surrogateModel", parentData: data, parentDataProperty: "surrogateModel", rootData })) {
        vErrors = vErrors === null ? validate57.errors : vErrors.concat(validate57.errors);
        errors = vErrors.length;
      }
    }
  }
  validate58.errors = vErrors;
  return errors === 0;
}
exports.isTargetVariable = validate62;
var schema35 = { "type": "object", "properties": { "targetName": { "type": "string", "example": "yield" }, "targetWeight": { "type": "number", "example": 1 }, "targetObjective": { "type": "string", "enum": ["Max", "Min", "Match"], "default": "Max" }, "lowerBound": { "type": "number", "example": 45 }, "upperBound": { "type": "number", "example": 100 } }, "required": ["targetName"] };
function validate62(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.targetName === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "targetName" }, message: "must have required property 'targetName'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.targetName !== void 0) {
      if (typeof data.targetName !== "string") {
        const err1 = { instancePath: instancePath + "/targetName", schemaPath: "#/properties/targetName/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
    if (data.targetWeight !== void 0) {
      let data1 = data.targetWeight;
      if (!(typeof data1 == "number" && isFinite(data1))) {
        const err2 = { instancePath: instancePath + "/targetWeight", schemaPath: "#/properties/targetWeight/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.targetObjective !== void 0) {
      let data2 = data.targetObjective;
      if (typeof data2 !== "string") {
        const err3 = { instancePath: instancePath + "/targetObjective", schemaPath: "#/properties/targetObjective/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
      if (!(data2 === "Max" || data2 === "Min" || data2 === "Match")) {
        const err4 = { instancePath: instancePath + "/targetObjective", schemaPath: "#/properties/targetObjective/enum", keyword: "enum", params: { allowedValues: schema35.properties.targetObjective.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
    if (data.lowerBound !== void 0) {
      let data3 = data.lowerBound;
      if (!(typeof data3 == "number" && isFinite(data3))) {
        const err5 = { instancePath: instancePath + "/lowerBound", schemaPath: "#/properties/lowerBound/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err5];
        } else {
          vErrors.push(err5);
        }
        errors++;
      }
    }
    if (data.upperBound !== void 0) {
      let data4 = data.upperBound;
      if (!(typeof data4 == "number" && isFinite(data4))) {
        const err6 = { instancePath: instancePath + "/upperBound", schemaPath: "#/properties/upperBound/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err6];
        } else {
          vErrors.push(err6);
        }
        errors++;
      }
    }
  } else {
    const err7 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err7];
    } else {
      vErrors.push(err7);
    }
    errors++;
  }
  validate62.errors = vErrors;
  return errors === 0;
}
exports.isProjectRole = validate63;
var schema36 = { "type": "string", "default": "Owner", "enum": ["Owner", "Editor", "Viewer"] };
function validate63(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (!(data === "Owner" || data === "Editor" || data === "Viewer")) {
    const err1 = { instancePath, schemaPath: "#/enum", keyword: "enum", params: { allowedValues: schema36.enum }, message: "must be equal to one of the allowed values" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate63.errors = vErrors;
  return errors === 0;
}
exports.isProjectInvite = validate64;
var schema37 = { "type": "object", "required": ["projectId", "inviteId", "projectName", "role", "email", "status", "sender", "createdAt", "expiresAt"], "properties": { "projectId": { "type": "string", "example": "00016244156fdcec3f9adb08746e" }, "inviteId": { "type": "string", "example": "00016244156fdcec3f9adb08746e" }, "userId": { "type": "string", "example": "qmTI1YzkBFK5-prw3AuYNw" }, "projectName": { "type": "string", "example": "Test Project" }, "role": { "$ref": "#/components/schemas/ProjectRole" }, "email": { "type": "string" }, "invitee": { "$ref": "#/components/schemas/User" }, "sender": { "$ref": "#/components/schemas/User" }, "createdAt": { "type": "string" }, "expiresAt": { "type": "string" }, "status": { "type": "string", "enum": ["pending", "accepted", "rejected", "expired"] } } };
function validate64(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.inviteId === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "inviteId" }, message: "must have required property 'inviteId'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.projectName === void 0) {
      const err2 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectName" }, message: "must have required property 'projectName'" };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    if (data.role === void 0) {
      const err3 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "role" }, message: "must have required property 'role'" };
      if (vErrors === null) {
        vErrors = [err3];
      } else {
        vErrors.push(err3);
      }
      errors++;
    }
    if (data.email === void 0) {
      const err4 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "email" }, message: "must have required property 'email'" };
      if (vErrors === null) {
        vErrors = [err4];
      } else {
        vErrors.push(err4);
      }
      errors++;
    }
    if (data.status === void 0) {
      const err5 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "status" }, message: "must have required property 'status'" };
      if (vErrors === null) {
        vErrors = [err5];
      } else {
        vErrors.push(err5);
      }
      errors++;
    }
    if (data.sender === void 0) {
      const err6 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "sender" }, message: "must have required property 'sender'" };
      if (vErrors === null) {
        vErrors = [err6];
      } else {
        vErrors.push(err6);
      }
      errors++;
    }
    if (data.createdAt === void 0) {
      const err7 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "createdAt" }, message: "must have required property 'createdAt'" };
      if (vErrors === null) {
        vErrors = [err7];
      } else {
        vErrors.push(err7);
      }
      errors++;
    }
    if (data.expiresAt === void 0) {
      const err8 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "expiresAt" }, message: "must have required property 'expiresAt'" };
      if (vErrors === null) {
        vErrors = [err8];
      } else {
        vErrors.push(err8);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err9 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err9];
        } else {
          vErrors.push(err9);
        }
        errors++;
      }
    }
    if (data.inviteId !== void 0) {
      if (typeof data.inviteId !== "string") {
        const err10 = { instancePath: instancePath + "/inviteId", schemaPath: "#/properties/inviteId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err10];
        } else {
          vErrors.push(err10);
        }
        errors++;
      }
    }
    if (data.userId !== void 0) {
      if (typeof data.userId !== "string") {
        const err11 = { instancePath: instancePath + "/userId", schemaPath: "#/properties/userId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err11];
        } else {
          vErrors.push(err11);
        }
        errors++;
      }
    }
    if (data.projectName !== void 0) {
      if (typeof data.projectName !== "string") {
        const err12 = { instancePath: instancePath + "/projectName", schemaPath: "#/properties/projectName/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err12];
        } else {
          vErrors.push(err12);
        }
        errors++;
      }
    }
    if (data.role !== void 0) {
      if (!validate63(data.role, { instancePath: instancePath + "/role", parentData: data, parentDataProperty: "role", rootData })) {
        vErrors = vErrors === null ? validate63.errors : vErrors.concat(validate63.errors);
        errors = vErrors.length;
      }
    }
    if (data.email !== void 0) {
      if (typeof data.email !== "string") {
        const err13 = { instancePath: instancePath + "/email", schemaPath: "#/properties/email/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err13];
        } else {
          vErrors.push(err13);
        }
        errors++;
      }
    }
    if (data.invitee !== void 0) {
      if (!validate21(data.invitee, { instancePath: instancePath + "/invitee", parentData: data, parentDataProperty: "invitee", rootData })) {
        vErrors = vErrors === null ? validate21.errors : vErrors.concat(validate21.errors);
        errors = vErrors.length;
      }
    }
    if (data.sender !== void 0) {
      if (!validate21(data.sender, { instancePath: instancePath + "/sender", parentData: data, parentDataProperty: "sender", rootData })) {
        vErrors = vErrors === null ? validate21.errors : vErrors.concat(validate21.errors);
        errors = vErrors.length;
      }
    }
    if (data.createdAt !== void 0) {
      if (typeof data.createdAt !== "string") {
        const err14 = { instancePath: instancePath + "/createdAt", schemaPath: "#/properties/createdAt/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err14];
        } else {
          vErrors.push(err14);
        }
        errors++;
      }
    }
    if (data.expiresAt !== void 0) {
      if (typeof data.expiresAt !== "string") {
        const err15 = { instancePath: instancePath + "/expiresAt", schemaPath: "#/properties/expiresAt/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err15];
        } else {
          vErrors.push(err15);
        }
        errors++;
      }
    }
    if (data.status !== void 0) {
      let data10 = data.status;
      if (typeof data10 !== "string") {
        const err16 = { instancePath: instancePath + "/status", schemaPath: "#/properties/status/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err16];
        } else {
          vErrors.push(err16);
        }
        errors++;
      }
      if (!(data10 === "pending" || data10 === "accepted" || data10 === "rejected" || data10 === "expired")) {
        const err17 = { instancePath: instancePath + "/status", schemaPath: "#/properties/status/enum", keyword: "enum", params: { allowedValues: schema37.properties.status.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err17];
        } else {
          vErrors.push(err17);
        }
        errors++;
      }
    }
  } else {
    const err18 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err18];
    } else {
      vErrors.push(err18);
    }
    errors++;
  }
  validate64.errors = vErrors;
  return errors === 0;
}
exports.isProject = validate68;
var schema40 = { "type": "object", "properties": { "operator": { "$ref": "#/components/schemas/ConstraintContinuousOperatorType" }, "subType": { "type": "string", "enum": ["ContinuousLinearConstraint"] }, "type": { "type": "string", "enum": ["continuous"] }, "parameters": { "type": "array", "items": { "type": "string" } }, "coefficients": { "type": "array", "items": { "type": "number" } }, "rhs": { "type": "number" } }, "required": ["type", "parameters", "coefficients", "rhs", "operator"] };
function validate76(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.type === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "type" }, message: "must have required property 'type'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.parameters === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "parameters" }, message: "must have required property 'parameters'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.coefficients === void 0) {
      const err2 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "coefficients" }, message: "must have required property 'coefficients'" };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    if (data.rhs === void 0) {
      const err3 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "rhs" }, message: "must have required property 'rhs'" };
      if (vErrors === null) {
        vErrors = [err3];
      } else {
        vErrors.push(err3);
      }
      errors++;
    }
    if (data.operator === void 0) {
      const err4 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "operator" }, message: "must have required property 'operator'" };
      if (vErrors === null) {
        vErrors = [err4];
      } else {
        vErrors.push(err4);
      }
      errors++;
    }
    if (data.operator !== void 0) {
      if (!validate35(data.operator, { instancePath: instancePath + "/operator", parentData: data, parentDataProperty: "operator", rootData })) {
        vErrors = vErrors === null ? validate35.errors : vErrors.concat(validate35.errors);
        errors = vErrors.length;
      }
    }
    if (data.subType !== void 0) {
      let data1 = data.subType;
      if (typeof data1 !== "string") {
        const err5 = { instancePath: instancePath + "/subType", schemaPath: "#/properties/subType/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err5];
        } else {
          vErrors.push(err5);
        }
        errors++;
      }
      if (!(data1 === "ContinuousLinearConstraint")) {
        const err6 = { instancePath: instancePath + "/subType", schemaPath: "#/properties/subType/enum", keyword: "enum", params: { allowedValues: schema40.properties.subType.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err6];
        } else {
          vErrors.push(err6);
        }
        errors++;
      }
    }
    if (data.type !== void 0) {
      let data2 = data.type;
      if (typeof data2 !== "string") {
        const err7 = { instancePath: instancePath + "/type", schemaPath: "#/properties/type/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err7];
        } else {
          vErrors.push(err7);
        }
        errors++;
      }
      if (!(data2 === "continuous")) {
        const err8 = { instancePath: instancePath + "/type", schemaPath: "#/properties/type/enum", keyword: "enum", params: { allowedValues: schema40.properties.type.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err8];
        } else {
          vErrors.push(err8);
        }
        errors++;
      }
    }
    if (data.parameters !== void 0) {
      let data3 = data.parameters;
      if (Array.isArray(data3)) {
        const len0 = data3.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (typeof data3[i0] !== "string") {
            const err9 = { instancePath: instancePath + "/parameters/" + i0, schemaPath: "#/properties/parameters/items/type", keyword: "type", params: { type: "string" }, message: "must be string" };
            if (vErrors === null) {
              vErrors = [err9];
            } else {
              vErrors.push(err9);
            }
            errors++;
          }
        }
      } else {
        const err10 = { instancePath: instancePath + "/parameters", schemaPath: "#/properties/parameters/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err10];
        } else {
          vErrors.push(err10);
        }
        errors++;
      }
    }
    if (data.coefficients !== void 0) {
      let data5 = data.coefficients;
      if (Array.isArray(data5)) {
        const len1 = data5.length;
        for (let i1 = 0; i1 < len1; i1++) {
          let data6 = data5[i1];
          if (!(typeof data6 == "number" && isFinite(data6))) {
            const err11 = { instancePath: instancePath + "/coefficients/" + i1, schemaPath: "#/properties/coefficients/items/type", keyword: "type", params: { type: "number" }, message: "must be number" };
            if (vErrors === null) {
              vErrors = [err11];
            } else {
              vErrors.push(err11);
            }
            errors++;
          }
        }
      } else {
        const err12 = { instancePath: instancePath + "/coefficients", schemaPath: "#/properties/coefficients/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err12];
        } else {
          vErrors.push(err12);
        }
        errors++;
      }
    }
    if (data.rhs !== void 0) {
      let data7 = data.rhs;
      if (!(typeof data7 == "number" && isFinite(data7))) {
        const err13 = { instancePath: instancePath + "/rhs", schemaPath: "#/properties/rhs/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err13];
        } else {
          vErrors.push(err13);
        }
        errors++;
      }
    }
  } else {
    const err14 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err14];
    } else {
      vErrors.push(err14);
    }
    errors++;
  }
  validate76.errors = vErrors;
  return errors === 0;
}
var schema41 = { "type": "object", "properties": { "subType": { "type": "string", "enum": ["DiscreteExcludeConstraint", "DiscreteSumConstraint", "DiscreteProductConstraint", "DiscreteNoLabelDuplicatesConstraint", "DiscreteLinkedParametersConstraint", "DiscreteDependenciesConstraint"] }, "type": { "type": "string", "enum": ["discrete"] }, "parameters": { "type": "array", "items": { "type": "string" } }, "combiner": { "$ref": "#/components/schemas/CombinerType" }, "conditions": { "type": "array", "items": { "$ref": "#/components/schemas/DiscreteConditions" } }, "affectedParameters": { "type": "array", "items": { "$ref": "#/components/schemas/StringArray" } } }, "required": ["type", "parameters", "subType"] };
var schema43 = { "type": "object", "properties": { "type": { "type": "string", "enum": ["SubSelectionCondition"] }, "selection": { "type": "array", "items": { "type": "string" } } } };
function validate82(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.type !== void 0) {
      let data0 = data.type;
      if (typeof data0 !== "string") {
        const err0 = { instancePath: instancePath + "/type", schemaPath: "#/properties/type/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err0];
        } else {
          vErrors.push(err0);
        }
        errors++;
      }
      if (!(data0 === "SubSelectionCondition")) {
        const err1 = { instancePath: instancePath + "/type", schemaPath: "#/properties/type/enum", keyword: "enum", params: { allowedValues: schema43.properties.type.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
    if (data.selection !== void 0) {
      let data1 = data.selection;
      if (Array.isArray(data1)) {
        const len0 = data1.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (typeof data1[i0] !== "string") {
            const err2 = { instancePath: instancePath + "/selection/" + i0, schemaPath: "#/properties/selection/items/type", keyword: "type", params: { type: "string" }, message: "must be string" };
            if (vErrors === null) {
              vErrors = [err2];
            } else {
              vErrors.push(err2);
            }
            errors++;
          }
        }
      } else {
        const err3 = { instancePath: instancePath + "/selection", schemaPath: "#/properties/selection/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate82.errors = vErrors;
  return errors === 0;
}
var schema44 = { "type": "object", "properties": { "type": { "type": "string", "enum": ["ThresholdCondition"] }, "threshold": { "type": "number" }, "operator": { "$ref": "#/components/schemas/ConstraintDiscreteConditionOperatorType" }, "tolerance": { "type": "number" } }, "required": ["threshold", "operator"] };
function validate84(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.threshold === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "threshold" }, message: "must have required property 'threshold'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.operator === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "operator" }, message: "must have required property 'operator'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.type !== void 0) {
      let data0 = data.type;
      if (typeof data0 !== "string") {
        const err2 = { instancePath: instancePath + "/type", schemaPath: "#/properties/type/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === "ThresholdCondition")) {
        const err3 = { instancePath: instancePath + "/type", schemaPath: "#/properties/type/enum", keyword: "enum", params: { allowedValues: schema44.properties.type.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.threshold !== void 0) {
      let data1 = data.threshold;
      if (!(typeof data1 == "number" && isFinite(data1))) {
        const err4 = { instancePath: instancePath + "/threshold", schemaPath: "#/properties/threshold/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
    if (data.operator !== void 0) {
      if (!validate36(data.operator, { instancePath: instancePath + "/operator", parentData: data, parentDataProperty: "operator", rootData })) {
        vErrors = vErrors === null ? validate36.errors : vErrors.concat(validate36.errors);
        errors = vErrors.length;
      }
    }
    if (data.tolerance !== void 0) {
      let data3 = data.tolerance;
      if (!(typeof data3 == "number" && isFinite(data3))) {
        const err5 = { instancePath: instancePath + "/tolerance", schemaPath: "#/properties/tolerance/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err5];
        } else {
          vErrors.push(err5);
        }
        errors++;
      }
    }
  } else {
    const err6 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err6];
    } else {
      vErrors.push(err6);
    }
    errors++;
  }
  validate84.errors = vErrors;
  return errors === 0;
}
function validate81(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  const _errs0 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs1 = errors;
  if (!validate82(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate82.errors : vErrors.concat(validate82.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs1 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs2 = errors;
  if (!validate84(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate84.errors : vErrors.concat(validate84.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
  }
  if (!valid0) {
    const err0 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  } else {
    errors = _errs0;
    if (vErrors !== null) {
      if (_errs0) {
        vErrors.length = _errs0;
      } else {
        vErrors = null;
      }
    }
  }
  validate81.errors = vErrors;
  return errors === 0;
}
function validate88(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (Array.isArray(data)) {
    const len0 = data.length;
    for (let i0 = 0; i0 < len0; i0++) {
      if (typeof data[i0] !== "string") {
        const err0 = { instancePath: instancePath + "/" + i0, schemaPath: "#/items/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err0];
        } else {
          vErrors.push(err0);
        }
        errors++;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "array" }, message: "must be array" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate88.errors = vErrors;
  return errors === 0;
}
function validate79(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.type === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "type" }, message: "must have required property 'type'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.parameters === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "parameters" }, message: "must have required property 'parameters'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.subType === void 0) {
      const err2 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "subType" }, message: "must have required property 'subType'" };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    if (data.subType !== void 0) {
      let data0 = data.subType;
      if (typeof data0 !== "string") {
        const err3 = { instancePath: instancePath + "/subType", schemaPath: "#/properties/subType/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
      if (!(data0 === "DiscreteExcludeConstraint" || data0 === "DiscreteSumConstraint" || data0 === "DiscreteProductConstraint" || data0 === "DiscreteNoLabelDuplicatesConstraint" || data0 === "DiscreteLinkedParametersConstraint" || data0 === "DiscreteDependenciesConstraint")) {
        const err4 = { instancePath: instancePath + "/subType", schemaPath: "#/properties/subType/enum", keyword: "enum", params: { allowedValues: schema41.properties.subType.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
    if (data.type !== void 0) {
      let data1 = data.type;
      if (typeof data1 !== "string") {
        const err5 = { instancePath: instancePath + "/type", schemaPath: "#/properties/type/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err5];
        } else {
          vErrors.push(err5);
        }
        errors++;
      }
      if (!(data1 === "discrete")) {
        const err6 = { instancePath: instancePath + "/type", schemaPath: "#/properties/type/enum", keyword: "enum", params: { allowedValues: schema41.properties.type.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err6];
        } else {
          vErrors.push(err6);
        }
        errors++;
      }
    }
    if (data.parameters !== void 0) {
      let data2 = data.parameters;
      if (Array.isArray(data2)) {
        const len0 = data2.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (typeof data2[i0] !== "string") {
            const err7 = { instancePath: instancePath + "/parameters/" + i0, schemaPath: "#/properties/parameters/items/type", keyword: "type", params: { type: "string" }, message: "must be string" };
            if (vErrors === null) {
              vErrors = [err7];
            } else {
              vErrors.push(err7);
            }
            errors++;
          }
        }
      } else {
        const err8 = { instancePath: instancePath + "/parameters", schemaPath: "#/properties/parameters/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err8];
        } else {
          vErrors.push(err8);
        }
        errors++;
      }
    }
    if (data.combiner !== void 0) {
      if (!validate37(data.combiner, { instancePath: instancePath + "/combiner", parentData: data, parentDataProperty: "combiner", rootData })) {
        vErrors = vErrors === null ? validate37.errors : vErrors.concat(validate37.errors);
        errors = vErrors.length;
      }
    }
    if (data.conditions !== void 0) {
      let data5 = data.conditions;
      if (Array.isArray(data5)) {
        const len1 = data5.length;
        for (let i1 = 0; i1 < len1; i1++) {
          if (!validate81(data5[i1], { instancePath: instancePath + "/conditions/" + i1, parentData: data5, parentDataProperty: i1, rootData })) {
            vErrors = vErrors === null ? validate81.errors : vErrors.concat(validate81.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err9 = { instancePath: instancePath + "/conditions", schemaPath: "#/properties/conditions/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err9];
        } else {
          vErrors.push(err9);
        }
        errors++;
      }
    }
    if (data.affectedParameters !== void 0) {
      let data7 = data.affectedParameters;
      if (Array.isArray(data7)) {
        const len2 = data7.length;
        for (let i2 = 0; i2 < len2; i2++) {
          if (!validate88(data7[i2], { instancePath: instancePath + "/affectedParameters/" + i2, parentData: data7, parentDataProperty: i2, rootData })) {
            vErrors = vErrors === null ? validate88.errors : vErrors.concat(validate88.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err10 = { instancePath: instancePath + "/affectedParameters", schemaPath: "#/properties/affectedParameters/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err10];
        } else {
          vErrors.push(err10);
        }
        errors++;
      }
    }
  } else {
    const err11 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err11];
    } else {
      vErrors.push(err11);
    }
    errors++;
  }
  validate79.errors = vErrors;
  return errors === 0;
}
function validate75(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  const _errs0 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs1 = errors;
  if (!validate76(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate76.errors : vErrors.concat(validate76.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs1 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs2 = errors;
  if (!validate79(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate79.errors : vErrors.concat(validate79.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
  }
  if (!valid0) {
    const err0 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  } else {
    errors = _errs0;
    if (vErrors !== null) {
      if (_errs0) {
        vErrors.length = _errs0;
      } else {
        vErrors = null;
      }
    }
  }
  validate75.errors = vErrors;
  return errors === 0;
}
function validate68(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.userId === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "userId" }, message: "must have required property 'userId'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.projectOwner === void 0) {
      const err2 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectOwner" }, message: "must have required property 'projectOwner'" };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    if (data.name === void 0) {
      const err3 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "name" }, message: "must have required property 'name'" };
      if (vErrors === null) {
        vErrors = [err3];
      } else {
        vErrors.push(err3);
      }
      errors++;
    }
    if (data.batchSize === void 0) {
      const err4 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "batchSize" }, message: "must have required property 'batchSize'" };
      if (vErrors === null) {
        vErrors = [err4];
      } else {
        vErrors.push(err4);
      }
      errors++;
    }
    if (data.targets === void 0) {
      const err5 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "targets" }, message: "must have required property 'targets'" };
      if (vErrors === null) {
        vErrors = [err5];
      } else {
        vErrors.push(err5);
      }
      errors++;
    }
    if (data.currentBatch === void 0) {
      const err6 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "currentBatch" }, message: "must have required property 'currentBatch'" };
      if (vErrors === null) {
        vErrors = [err6];
      } else {
        vErrors.push(err6);
      }
      errors++;
    }
    if (data.parameterSpace === void 0) {
      const err7 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "parameterSpace" }, message: "must have required property 'parameterSpace'" };
      if (vErrors === null) {
        vErrors = [err7];
      } else {
        vErrors.push(err7);
      }
      errors++;
    }
    if (data.createdAt === void 0) {
      const err8 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "createdAt" }, message: "must have required property 'createdAt'" };
      if (vErrors === null) {
        vErrors = [err8];
      } else {
        vErrors.push(err8);
      }
      errors++;
    }
    if (data.changedAt === void 0) {
      const err9 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "changedAt" }, message: "must have required property 'changedAt'" };
      if (vErrors === null) {
        vErrors = [err9];
      } else {
        vErrors.push(err9);
      }
      errors++;
    }
    if (data.role === void 0) {
      const err10 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "role" }, message: "must have required property 'role'" };
      if (vErrors === null) {
        vErrors = [err10];
      } else {
        vErrors.push(err10);
      }
      errors++;
    }
    if (data.status === void 0) {
      const err11 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "status" }, message: "must have required property 'status'" };
      if (vErrors === null) {
        vErrors = [err11];
      } else {
        vErrors.push(err11);
      }
      errors++;
    }
    if (data.constraints === void 0) {
      const err12 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "constraints" }, message: "must have required property 'constraints'" };
      if (vErrors === null) {
        vErrors = [err12];
      } else {
        vErrors.push(err12);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err13 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err13];
        } else {
          vErrors.push(err13);
        }
        errors++;
      }
    }
    if (data.userId !== void 0) {
      if (typeof data.userId !== "string") {
        const err14 = { instancePath: instancePath + "/userId", schemaPath: "#/properties/userId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err14];
        } else {
          vErrors.push(err14);
        }
        errors++;
      }
    }
    if (data.projectOwner !== void 0) {
      if (typeof data.projectOwner !== "string") {
        const err15 = { instancePath: instancePath + "/projectOwner", schemaPath: "#/properties/projectOwner/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err15];
        } else {
          vErrors.push(err15);
        }
        errors++;
      }
    }
    if (data.role !== void 0) {
      if (!validate63(data.role, { instancePath: instancePath + "/role", parentData: data, parentDataProperty: "role", rootData })) {
        vErrors = vErrors === null ? validate63.errors : vErrors.concat(validate63.errors);
        errors = vErrors.length;
      }
    }
    if (data.name !== void 0) {
      if (typeof data.name !== "string") {
        const err16 = { instancePath: instancePath + "/name", schemaPath: "#/properties/name/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err16];
        } else {
          vErrors.push(err16);
        }
        errors++;
      }
    }
    if (data.batchSize !== void 0) {
      let data5 = data.batchSize;
      if (!(typeof data5 == "number" && isFinite(data5))) {
        const err17 = { instancePath: instancePath + "/batchSize", schemaPath: "#/properties/batchSize/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err17];
        } else {
          vErrors.push(err17);
        }
        errors++;
      }
    }
    if (data.targets !== void 0) {
      let data6 = data.targets;
      if (Array.isArray(data6)) {
        const len0 = data6.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (!validate62(data6[i0], { instancePath: instancePath + "/targets/" + i0, parentData: data6, parentDataProperty: i0, rootData })) {
            vErrors = vErrors === null ? validate62.errors : vErrors.concat(validate62.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err18 = { instancePath: instancePath + "/targets", schemaPath: "#/properties/targets/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err18];
        } else {
          vErrors.push(err18);
        }
        errors++;
      }
    }
    if (data.currentBatch !== void 0) {
      let data8 = data.currentBatch;
      if (!(typeof data8 == "number" && isFinite(data8))) {
        const err19 = { instancePath: instancePath + "/currentBatch", schemaPath: "#/properties/currentBatch/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err19];
        } else {
          vErrors.push(err19);
        }
        errors++;
      }
    }
    if (data.parameterSpace !== void 0) {
      let data9 = data.parameterSpace;
      if (Array.isArray(data9)) {
        const len1 = data9.length;
        for (let i1 = 0; i1 < len1; i1++) {
          if (!validate49(data9[i1], { instancePath: instancePath + "/parameterSpace/" + i1, parentData: data9, parentDataProperty: i1, rootData })) {
            vErrors = vErrors === null ? validate49.errors : vErrors.concat(validate49.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err20 = { instancePath: instancePath + "/parameterSpace", schemaPath: "#/properties/parameterSpace/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err20];
        } else {
          vErrors.push(err20);
        }
        errors++;
      }
    }
    if (data.expertOptions !== void 0) {
      if (!validate58(data.expertOptions, { instancePath: instancePath + "/expertOptions", parentData: data, parentDataProperty: "expertOptions", rootData })) {
        vErrors = vErrors === null ? validate58.errors : vErrors.concat(validate58.errors);
        errors = vErrors.length;
      }
    }
    if (data.createdAt !== void 0) {
      if (typeof data.createdAt !== "string") {
        const err21 = { instancePath: instancePath + "/createdAt", schemaPath: "#/properties/createdAt/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err21];
        } else {
          vErrors.push(err21);
        }
        errors++;
      }
    }
    if (data.changedAt !== void 0) {
      if (typeof data.changedAt !== "string") {
        const err22 = { instancePath: instancePath + "/changedAt", schemaPath: "#/properties/changedAt/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err22];
        } else {
          vErrors.push(err22);
        }
        errors++;
      }
    }
    if (data.changedBy !== void 0) {
      if (!validate21(data.changedBy, { instancePath: instancePath + "/changedBy", parentData: data, parentDataProperty: "changedBy", rootData })) {
        vErrors = vErrors === null ? validate21.errors : vErrors.concat(validate21.errors);
        errors = vErrors.length;
      }
    }
    if (data.status !== void 0) {
      if (!validate27(data.status, { instancePath: instancePath + "/status", parentData: data, parentDataProperty: "status", rootData })) {
        vErrors = vErrors === null ? validate27.errors : vErrors.concat(validate27.errors);
        errors = vErrors.length;
      }
    }
    if (data.error !== void 0) {
      let data16 = data.error;
      if (typeof data16 !== "string" && data16 !== null) {
        const err23 = { instancePath: instancePath + "/error", schemaPath: "#/properties/error/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err23];
        } else {
          vErrors.push(err23);
        }
        errors++;
      }
    }
    if (data.constraints !== void 0) {
      let data17 = data.constraints;
      if (Array.isArray(data17)) {
        const len2 = data17.length;
        for (let i2 = 0; i2 < len2; i2++) {
          if (!validate75(data17[i2], { instancePath: instancePath + "/constraints/" + i2, parentData: data17, parentDataProperty: i2, rootData })) {
            vErrors = vErrors === null ? validate75.errors : vErrors.concat(validate75.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err24 = { instancePath: instancePath + "/constraints", schemaPath: "#/properties/constraints/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err24];
        } else {
          vErrors.push(err24);
        }
        errors++;
      }
    }
  } else {
    const err25 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err25];
    } else {
      vErrors.push(err25);
    }
    errors++;
  }
  validate68.errors = vErrors;
  return errors === 0;
}
exports.isExperimentResults = validate92;
function validate92(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      let data0 = data[key0];
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err0 = { instancePath: instancePath + "/" + key0.replace(/~/g, "~0").replace(/\//g, "~1"), schemaPath: "#/additionalProperties/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err0];
        } else {
          vErrors.push(err0);
        }
        errors++;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate92.errors = vErrors;
  return errors === 0;
}
exports.isExperiment = validate93;
function validate93(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.experimentId === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "experimentId" }, message: "must have required property 'experimentId'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.batch === void 0) {
      const err2 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "batch" }, message: "must have required property 'batch'" };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    if (data.parameters === void 0) {
      const err3 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "parameters" }, message: "must have required property 'parameters'" };
      if (vErrors === null) {
        vErrors = [err3];
      } else {
        vErrors.push(err3);
      }
      errors++;
    }
    if (data.results === void 0) {
      const err4 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "results" }, message: "must have required property 'results'" };
      if (vErrors === null) {
        vErrors = [err4];
      } else {
        vErrors.push(err4);
      }
      errors++;
    }
    if (data.createdAt === void 0) {
      const err5 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "createdAt" }, message: "must have required property 'createdAt'" };
      if (vErrors === null) {
        vErrors = [err5];
      } else {
        vErrors.push(err5);
      }
      errors++;
    }
    if (data.changedAt === void 0) {
      const err6 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "changedAt" }, message: "must have required property 'changedAt'" };
      if (vErrors === null) {
        vErrors = [err6];
      } else {
        vErrors.push(err6);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err7 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err7];
        } else {
          vErrors.push(err7);
        }
        errors++;
      }
    }
    if (data.experimentId !== void 0) {
      if (typeof data.experimentId !== "string") {
        const err8 = { instancePath: instancePath + "/experimentId", schemaPath: "#/properties/experimentId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err8];
        } else {
          vErrors.push(err8);
        }
        errors++;
      }
    }
    if (data.recommendationId !== void 0) {
      if (typeof data.recommendationId !== "string") {
        const err9 = { instancePath: instancePath + "/recommendationId", schemaPath: "#/properties/recommendationId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err9];
        } else {
          vErrors.push(err9);
        }
        errors++;
      }
    }
    if (data.batch !== void 0) {
      let data3 = data.batch;
      if (!(typeof data3 == "number" && isFinite(data3))) {
        const err10 = { instancePath: instancePath + "/batch", schemaPath: "#/properties/batch/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err10];
        } else {
          vErrors.push(err10);
        }
        errors++;
      }
    }
    if (data.parameters !== void 0) {
      if (!validate53(data.parameters, { instancePath: instancePath + "/parameters", parentData: data, parentDataProperty: "parameters", rootData })) {
        vErrors = vErrors === null ? validate53.errors : vErrors.concat(validate53.errors);
        errors = vErrors.length;
      }
    }
    if (data.results !== void 0) {
      if (!validate92(data.results, { instancePath: instancePath + "/results", parentData: data, parentDataProperty: "results", rootData })) {
        vErrors = vErrors === null ? validate92.errors : vErrors.concat(validate92.errors);
        errors = vErrors.length;
      }
    }
    if (data.createdAt !== void 0) {
      if (typeof data.createdAt !== "string") {
        const err11 = { instancePath: instancePath + "/createdAt", schemaPath: "#/properties/createdAt/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err11];
        } else {
          vErrors.push(err11);
        }
        errors++;
      }
    }
    if (data.changedAt !== void 0) {
      if (typeof data.changedAt !== "string") {
        const err12 = { instancePath: instancePath + "/changedAt", schemaPath: "#/properties/changedAt/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err12];
        } else {
          vErrors.push(err12);
        }
        errors++;
      }
    }
    if (data.changedBy !== void 0) {
      if (!validate21(data.changedBy, { instancePath: instancePath + "/changedBy", parentData: data, parentDataProperty: "changedBy", rootData })) {
        vErrors = vErrors === null ? validate21.errors : vErrors.concat(validate21.errors);
        errors = vErrors.length;
      }
    }
    if (data.status !== void 0) {
      if (!validate28(data.status, { instancePath: instancePath + "/status", parentData: data, parentDataProperty: "status", rootData })) {
        vErrors = vErrors === null ? validate28.errors : vErrors.concat(validate28.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err13 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err13];
    } else {
      vErrors.push(err13);
    }
    errors++;
  }
  validate93.errors = vErrors;
  return errors === 0;
}
exports.isRecommendation = validate98;
function validate98(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.recommendationId === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "recommendationId" }, message: "must have required property 'recommendationId'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.parameters === void 0) {
      const err2 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "parameters" }, message: "must have required property 'parameters'" };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    if (data.createdAt === void 0) {
      const err3 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "createdAt" }, message: "must have required property 'createdAt'" };
      if (vErrors === null) {
        vErrors = [err3];
      } else {
        vErrors.push(err3);
      }
      errors++;
    }
    if (data.changedAt === void 0) {
      const err4 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "changedAt" }, message: "must have required property 'changedAt'" };
      if (vErrors === null) {
        vErrors = [err4];
      } else {
        vErrors.push(err4);
      }
      errors++;
    }
    if (data.status === void 0) {
      const err5 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "status" }, message: "must have required property 'status'" };
      if (vErrors === null) {
        vErrors = [err5];
      } else {
        vErrors.push(err5);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err6 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err6];
        } else {
          vErrors.push(err6);
        }
        errors++;
      }
    }
    if (data.recommendationId !== void 0) {
      if (typeof data.recommendationId !== "string") {
        const err7 = { instancePath: instancePath + "/recommendationId", schemaPath: "#/properties/recommendationId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err7];
        } else {
          vErrors.push(err7);
        }
        errors++;
      }
    }
    if (data.parameters !== void 0) {
      if (!validate53(data.parameters, { instancePath: instancePath + "/parameters", parentData: data, parentDataProperty: "parameters", rootData })) {
        vErrors = vErrors === null ? validate53.errors : vErrors.concat(validate53.errors);
        errors = vErrors.length;
      }
    }
    if (data.createdAt !== void 0) {
      if (typeof data.createdAt !== "string") {
        const err8 = { instancePath: instancePath + "/createdAt", schemaPath: "#/properties/createdAt/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err8];
        } else {
          vErrors.push(err8);
        }
        errors++;
      }
    }
    if (data.changedAt !== void 0) {
      if (typeof data.changedAt !== "string") {
        const err9 = { instancePath: instancePath + "/changedAt", schemaPath: "#/properties/changedAt/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err9];
        } else {
          vErrors.push(err9);
        }
        errors++;
      }
    }
    if (data.status !== void 0) {
      if (!validate29(data.status, { instancePath: instancePath + "/status", parentData: data, parentDataProperty: "status", rootData })) {
        vErrors = vErrors === null ? validate29.errors : vErrors.concat(validate29.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err10 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err10];
    } else {
      vErrors.push(err10);
    }
    errors++;
  }
  validate98.errors = vErrors;
  return errors === 0;
}
exports.isContinuousConstraint = validate76;
exports.isDiscreteConstraint = validate79;
exports.isConstraint = validate75;
exports.isSubSelectionCondition = validate82;
exports.isThresholdCondition = validate84;
exports.isDiscreteConditions = validate81;
exports.isStringArray = validate88;
exports.isGetUserResponse = validate101;
var schema49 = { "type": "object", "description": "Success", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "$ref": "#/components/schemas/User" } } };
function validate101(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema49.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate21(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate21.errors : vErrors.concat(validate21.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate101.errors = vErrors;
  return errors === 0;
}
exports.isGetUserRequest = validate103;
function validate103(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate103.errors = vErrors;
  return errors === 0;
}
exports.isListUserInvitesResponse = validate104;
var schema51 = { "type": "object", "description": "ok", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "type": "array", "items": { "$ref": "#/components/schemas/ProjectInvite" } } } };
function validate104(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema51.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      let data1 = data.json;
      if (Array.isArray(data1)) {
        const len0 = data1.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (!validate64(data1[i0], { instancePath: instancePath + "/json/" + i0, parentData: data1, parentDataProperty: i0, rootData })) {
            vErrors = vErrors === null ? validate64.errors : vErrors.concat(validate64.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err4 = { instancePath: instancePath + "/json", schemaPath: "#/properties/json/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
  } else {
    const err5 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err5];
    } else {
      vErrors.push(err5);
    }
    errors++;
  }
  validate104.errors = vErrors;
  return errors === 0;
}
exports.isListUserInvitesRequestQueryStringParameters = validate106;
var schema52 = { "type": "object", "required": [], "properties": { "sortBy": { "type": "string", "default": "createdAt", "enum": ["createdAt", "expiresAt", "role", "projectId"] }, "order": { "type": "string", "default": "desc", "enum": ["asc", "desc"] } }, "nullable": true };
function validate106(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data)) && data !== null) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.sortBy !== void 0) {
      let data0 = data.sortBy;
      if (typeof data0 !== "string") {
        const err1 = { instancePath: instancePath + "/sortBy", schemaPath: "#/properties/sortBy/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === "createdAt" || data0 === "expiresAt" || data0 === "role" || data0 === "projectId")) {
        const err2 = { instancePath: instancePath + "/sortBy", schemaPath: "#/properties/sortBy/enum", keyword: "enum", params: { allowedValues: schema52.properties.sortBy.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.order !== void 0) {
      let data1 = data.order;
      if (typeof data1 !== "string") {
        const err3 = { instancePath: instancePath + "/order", schemaPath: "#/properties/order/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
      if (!(data1 === "asc" || data1 === "desc")) {
        const err4 = { instancePath: instancePath + "/order", schemaPath: "#/properties/order/enum", keyword: "enum", params: { allowedValues: schema52.properties.order.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
  }
  validate106.errors = vErrors;
  return errors === 0;
}
exports.isListUserInvitesRequest = validate107;
function validate107(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.queryStringParameters !== void 0) {
      if (!validate106(data.queryStringParameters, { instancePath: instancePath + "/queryStringParameters", parentData: data, parentDataProperty: "queryStringParameters", rootData })) {
        vErrors = vErrors === null ? validate106.errors : vErrors.concat(validate106.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate107.errors = vErrors;
  return errors === 0;
}
exports.isListProjectsResponse = validate109;
var schema54 = { "type": "object", "description": "Success", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "type": "array", "items": { "$ref": "#/components/schemas/Project" } } } };
function validate109(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema54.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      let data1 = data.json;
      if (Array.isArray(data1)) {
        const len0 = data1.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (!validate68(data1[i0], { instancePath: instancePath + "/json/" + i0, parentData: data1, parentDataProperty: i0, rootData })) {
            vErrors = vErrors === null ? validate68.errors : vErrors.concat(validate68.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err4 = { instancePath: instancePath + "/json", schemaPath: "#/properties/json/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
  } else {
    const err5 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err5];
    } else {
      vErrors.push(err5);
    }
    errors++;
  }
  validate109.errors = vErrors;
  return errors === 0;
}
exports.isListProjectsRequest = validate111;
function validate111(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate111.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous6 = validate112;
var schema56 = { "type": "object", "description": "item created", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [201] }, "json": { "$ref": "#/components/schemas/Project" } } };
function validate112(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 201)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema56.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate68(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate68.errors : vErrors.concat(validate68.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate112.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous9Json = validate114;
function validate114(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate114.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous9 = validate115;
var schema58 = { "type": "object", "description": "invalid input, object invalid", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [400] }, "json": { "$ref": "#/components/schemas/Anonymous9Json" } } };
function validate115(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 400)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema58.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate114(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate114.errors : vErrors.concat(validate114.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate115.errors = vErrors;
  return errors === 0;
}
exports.isCreateProjectResponse = validate117;
function validate117(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  const _errs1 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs2 = errors;
  if (!validate112(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate112.errors : vErrors.concat(validate112.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (!validate115(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate115.errors : vErrors.concat(validate115.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
  }
  if (!valid0) {
    const err1 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  } else {
    errors = _errs1;
    if (vErrors !== null) {
      if (_errs1) {
        vErrors.length = _errs1;
      } else {
        vErrors = null;
      }
    }
  }
  validate117.errors = vErrors;
  return errors === 0;
}
exports.isCreateProjectRequestJson = validate120;
function validate120(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.name === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "name" }, message: "must have required property 'name'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.batchSize === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "batchSize" }, message: "must have required property 'batchSize'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.parameterSpace === void 0) {
      const err2 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "parameterSpace" }, message: "must have required property 'parameterSpace'" };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    if (data.constraints === void 0) {
      const err3 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "constraints" }, message: "must have required property 'constraints'" };
      if (vErrors === null) {
        vErrors = [err3];
      } else {
        vErrors.push(err3);
      }
      errors++;
    }
    if (data.name !== void 0) {
      if (typeof data.name !== "string") {
        const err4 = { instancePath: instancePath + "/name", schemaPath: "#/properties/name/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
    if (data.batchSize !== void 0) {
      let data1 = data.batchSize;
      if (!(typeof data1 == "number" && (!(data1 % 1) && !isNaN(data1)) && isFinite(data1))) {
        const err5 = { instancePath: instancePath + "/batchSize", schemaPath: "#/properties/batchSize/type", keyword: "type", params: { type: "integer" }, message: "must be integer" };
        if (vErrors === null) {
          vErrors = [err5];
        } else {
          vErrors.push(err5);
        }
        errors++;
      }
    }
    if (data.targets !== void 0) {
      let data2 = data.targets;
      if (Array.isArray(data2)) {
        const len0 = data2.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (!validate62(data2[i0], { instancePath: instancePath + "/targets/" + i0, parentData: data2, parentDataProperty: i0, rootData })) {
            vErrors = vErrors === null ? validate62.errors : vErrors.concat(validate62.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err6 = { instancePath: instancePath + "/targets", schemaPath: "#/properties/targets/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err6];
        } else {
          vErrors.push(err6);
        }
        errors++;
      }
    }
    if (data.expertOptions !== void 0) {
      if (!validate58(data.expertOptions, { instancePath: instancePath + "/expertOptions", parentData: data, parentDataProperty: "expertOptions", rootData })) {
        vErrors = vErrors === null ? validate58.errors : vErrors.concat(validate58.errors);
        errors = vErrors.length;
      }
    }
    if (data.parameterSpace !== void 0) {
      let data5 = data.parameterSpace;
      if (Array.isArray(data5)) {
        const len1 = data5.length;
        for (let i1 = 0; i1 < len1; i1++) {
          if (!validate49(data5[i1], { instancePath: instancePath + "/parameterSpace/" + i1, parentData: data5, parentDataProperty: i1, rootData })) {
            vErrors = vErrors === null ? validate49.errors : vErrors.concat(validate49.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err7 = { instancePath: instancePath + "/parameterSpace", schemaPath: "#/properties/parameterSpace/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err7];
        } else {
          vErrors.push(err7);
        }
        errors++;
      }
    }
    if (data.constraints !== void 0) {
      let data7 = data.constraints;
      if (Array.isArray(data7)) {
        const len2 = data7.length;
        for (let i2 = 0; i2 < len2; i2++) {
          if (!validate75(data7[i2], { instancePath: instancePath + "/constraints/" + i2, parentData: data7, parentDataProperty: i2, rootData })) {
            vErrors = vErrors === null ? validate75.errors : vErrors.concat(validate75.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err8 = { instancePath: instancePath + "/constraints", schemaPath: "#/properties/constraints/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err8];
        } else {
          vErrors.push(err8);
        }
        errors++;
      }
    }
  } else {
    const err9 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err9];
    } else {
      vErrors.push(err9);
    }
    errors++;
  }
  validate120.errors = vErrors;
  return errors === 0;
}
exports.isCreateProjectRequest = validate125;
function validate125(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.json === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json !== void 0) {
      if (!validate120(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate120.errors : vErrors.concat(validate120.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate125.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous10Json = validate127;
function validate127(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate127.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous10 = validate128;
var schema63 = { "type": "object", "description": "item deleted", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "$ref": "#/components/schemas/Anonymous10Json" } } };
function validate128(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema63.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate127(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate127.errors : vErrors.concat(validate127.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate128.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous11Json = validate130;
function validate130(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate130.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous11 = validate131;
var schema65 = { "type": "object", "description": "invalid input, object invalid", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [400] }, "json": { "$ref": "#/components/schemas/Anonymous11Json" } } };
function validate131(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 400)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema65.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate130(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate130.errors : vErrors.concat(validate130.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate131.errors = vErrors;
  return errors === 0;
}
exports.isDeleteProjectResponse = validate133;
function validate133(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  const _errs1 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs2 = errors;
  if (!validate128(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate128.errors : vErrors.concat(validate128.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (!validate131(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate131.errors : vErrors.concat(validate131.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
  }
  if (!valid0) {
    const err1 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  } else {
    errors = _errs1;
    if (vErrors !== null) {
      if (_errs1) {
        vErrors.length = _errs1;
      } else {
        vErrors = null;
      }
    }
  }
  validate133.errors = vErrors;
  return errors === 0;
}
exports.isDeleteProjectRequestJson = validate136;
function validate136(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate136.errors = vErrors;
  return errors === 0;
}
exports.isDeleteProjectRequest = validate137;
function validate137(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.json === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json !== void 0) {
      if (!validate136(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate136.errors : vErrors.concat(validate136.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate137.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous12 = validate139;
var schema69 = { "type": "object", "description": "Project for provided id", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "$ref": "#/components/schemas/Project" } } };
function validate139(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema69.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate68(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate68.errors : vErrors.concat(validate68.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate139.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous15Json = validate141;
function validate141(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate141.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous15 = validate142;
var schema71 = { "type": "object", "description": "User is not authorized to view project", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [403] }, "json": { "$ref": "#/components/schemas/Anonymous15Json" } } };
function validate142(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 403)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema71.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate141(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate141.errors : vErrors.concat(validate141.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate142.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous16Json = validate144;
function validate144(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate144.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous16 = validate145;
var schema73 = { "type": "object", "description": "no project for provided id", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [404] }, "json": { "$ref": "#/components/schemas/Anonymous16Json" } } };
function validate145(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 404)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema73.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate144(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate144.errors : vErrors.concat(validate144.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate145.errors = vErrors;
  return errors === 0;
}
exports.isGetProjectByIdResponse = validate147;
function validate147(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  const _errs1 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs2 = errors;
  if (!validate139(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate139.errors : vErrors.concat(validate139.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (!validate142(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate142.errors : vErrors.concat(validate142.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
    const _errs4 = errors;
    if (!validate145(data, { instancePath, parentData, parentDataProperty, rootData })) {
      vErrors = vErrors === null ? validate145.errors : vErrors.concat(validate145.errors);
      errors = vErrors.length;
    }
    var _valid0 = _errs4 === errors;
    if (_valid0 && valid0) {
      valid0 = false;
      passing0 = [passing0, 2];
    } else {
      if (_valid0) {
        valid0 = true;
        passing0 = 2;
      }
    }
  }
  if (!valid0) {
    const err1 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  } else {
    errors = _errs1;
    if (vErrors !== null) {
      if (_errs1) {
        vErrors.length = _errs1;
      } else {
        vErrors = null;
      }
    }
  }
  validate147.errors = vErrors;
  return errors === 0;
}
exports.isGetProjectByIdRequestPathParameters = validate151;
function validate151(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate151.errors = vErrors;
  return errors === 0;
}
exports.isGetProjectByIdRequest = validate152;
function validate152(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate151(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate151.errors : vErrors.concat(validate151.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate152.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous17 = validate154;
var schema77 = { "type": "object", "description": "Chemical Space was expanded successfully", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "$ref": "#/components/schemas/Project" } } };
function validate154(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema77.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate68(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate68.errors : vErrors.concat(validate68.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate154.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous20Json = validate156;
function validate156(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate156.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous20 = validate157;
var schema79 = { "type": "object", "description": "invalid input, object invalid", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [400] }, "json": { "$ref": "#/components/schemas/Anonymous20Json" } } };
function validate157(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 400)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema79.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate156(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate156.errors : vErrors.concat(validate156.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate157.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous21Json = validate159;
function validate159(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate159.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous21 = validate160;
var schema81 = { "type": "object", "description": "User is not authorized to change experiment", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [403] }, "json": { "$ref": "#/components/schemas/Anonymous21Json" } } };
function validate160(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 403)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema81.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate159(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate159.errors : vErrors.concat(validate159.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate160.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous22Json = validate162;
function validate162(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate162.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous22 = validate163;
var schema83 = { "type": "object", "description": "Project not found", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [404] }, "json": { "$ref": "#/components/schemas/Anonymous22Json" } } };
function validate163(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 404)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema83.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate162(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate162.errors : vErrors.concat(validate162.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate163.errors = vErrors;
  return errors === 0;
}
exports.isExpandChemicalSpaceResponse = validate165;
function validate165(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  const _errs1 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs2 = errors;
  if (!validate154(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate154.errors : vErrors.concat(validate154.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (!validate157(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate157.errors : vErrors.concat(validate157.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
    const _errs4 = errors;
    if (!validate160(data, { instancePath, parentData, parentDataProperty, rootData })) {
      vErrors = vErrors === null ? validate160.errors : vErrors.concat(validate160.errors);
      errors = vErrors.length;
    }
    var _valid0 = _errs4 === errors;
    if (_valid0 && valid0) {
      valid0 = false;
      passing0 = [passing0, 2];
    } else {
      if (_valid0) {
        valid0 = true;
        passing0 = 2;
      }
      const _errs5 = errors;
      if (!validate163(data, { instancePath, parentData, parentDataProperty, rootData })) {
        vErrors = vErrors === null ? validate163.errors : vErrors.concat(validate163.errors);
        errors = vErrors.length;
      }
      var _valid0 = _errs5 === errors;
      if (_valid0 && valid0) {
        valid0 = false;
        passing0 = [passing0, 3];
      } else {
        if (_valid0) {
          valid0 = true;
          passing0 = 3;
        }
      }
    }
  }
  if (!valid0) {
    const err1 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  } else {
    errors = _errs1;
    if (vErrors !== null) {
      if (_errs1) {
        vErrors.length = _errs1;
      } else {
        vErrors = null;
      }
    }
  }
  validate165.errors = vErrors;
  return errors === 0;
}
exports.isExpandChemicalSpaceRequestPathParameters = validate170;
function validate170(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate170.errors = vErrors;
  return errors === 0;
}
exports.isExpandChemicalSpaceRequestJsonExperiments = validate171;
function validate171(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      if (!validate53(data[key0], { instancePath: instancePath + "/" + key0.replace(/~/g, "~0").replace(/\//g, "~1"), parentData: data, parentDataProperty: key0, rootData })) {
        vErrors = vErrors === null ? validate53.errors : vErrors.concat(validate53.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate171.errors = vErrors;
  return errors === 0;
}
exports.isExpandChemicalSpaceRequestJson = validate173;
function validate173(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.parameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "parameters" }, message: "must have required property 'parameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.parameters !== void 0) {
      let data0 = data.parameters;
      if (Array.isArray(data0)) {
        const len0 = data0.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (!validate49(data0[i0], { instancePath: instancePath + "/parameters/" + i0, parentData: data0, parentDataProperty: i0, rootData })) {
            vErrors = vErrors === null ? validate49.errors : vErrors.concat(validate49.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err1 = { instancePath: instancePath + "/parameters", schemaPath: "#/properties/parameters/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
    if (data.experiments !== void 0) {
      if (!validate171(data.experiments, { instancePath: instancePath + "/experiments", parentData: data, parentDataProperty: "experiments", rootData })) {
        vErrors = vErrors === null ? validate171.errors : vErrors.concat(validate171.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate173.errors = vErrors;
  return errors === 0;
}
exports.isExpandChemicalSpaceRequest = validate176;
function validate176(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate170(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate170.errors : vErrors.concat(validate170.errors);
        errors = vErrors.length;
      }
    }
    if (data.json !== void 0) {
      if (!validate173(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate173.errors : vErrors.concat(validate173.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate176.errors = vErrors;
  return errors === 0;
}
exports.isCloseProjectResponseJson = validate179;
function validate179(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate179.errors = vErrors;
  return errors === 0;
}
exports.isCloseProjectResponse = validate180;
var schema90 = { "type": "object", "description": "Successfully closed", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [204] }, "json": { "$ref": "#/components/schemas/CloseProjectResponseJson" } } };
function validate180(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 204)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema90.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate179(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate179.errors : vErrors.concat(validate179.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate180.errors = vErrors;
  return errors === 0;
}
exports.isCloseProjectRequestPathParameters = validate182;
function validate182(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate182.errors = vErrors;
  return errors === 0;
}
exports.isCloseProjectRequest = validate183;
function validate183(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate182(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate182.errors : vErrors.concat(validate182.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate183.errors = vErrors;
  return errors === 0;
}
exports.isReopenProjectResponseJson = validate185;
function validate185(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate185.errors = vErrors;
  return errors === 0;
}
exports.isReopenProjectResponse = validate186;
var schema94 = { "type": "object", "description": "Successfully opened", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "$ref": "#/components/schemas/ReopenProjectResponseJson" } } };
function validate186(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema94.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate185(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate185.errors : vErrors.concat(validate185.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate186.errors = vErrors;
  return errors === 0;
}
exports.isReopenProjectRequestPathParameters = validate188;
function validate188(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate188.errors = vErrors;
  return errors === 0;
}
exports.isReopenProjectRequest = validate189;
function validate189(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate188(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate188.errors : vErrors.concat(validate188.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate189.errors = vErrors;
  return errors === 0;
}
exports.isListInvitesResponse = validate191;
var schema97 = { "type": "object", "description": "ok", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "type": "array", "items": { "$ref": "#/components/schemas/ProjectInvite" } } } };
function validate191(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema97.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      let data1 = data.json;
      if (Array.isArray(data1)) {
        const len0 = data1.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (!validate64(data1[i0], { instancePath: instancePath + "/json/" + i0, parentData: data1, parentDataProperty: i0, rootData })) {
            vErrors = vErrors === null ? validate64.errors : vErrors.concat(validate64.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err4 = { instancePath: instancePath + "/json", schemaPath: "#/properties/json/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
  } else {
    const err5 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err5];
    } else {
      vErrors.push(err5);
    }
    errors++;
  }
  validate191.errors = vErrors;
  return errors === 0;
}
exports.isListInvitesRequestPathParameters = validate193;
function validate193(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate193.errors = vErrors;
  return errors === 0;
}
exports.isListInvitesRequest = validate194;
function validate194(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate193(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate193.errors : vErrors.concat(validate193.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate194.errors = vErrors;
  return errors === 0;
}
exports.isSendInviteResponse = validate196;
var schema100 = { "type": "object", "description": "ok", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [201] }, "json": { "$ref": "#/components/schemas/ProjectInvite" } } };
function validate196(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 201)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema100.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate64(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate64.errors : vErrors.concat(validate64.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate196.errors = vErrors;
  return errors === 0;
}
exports.isSendInviteRequestPathParameters = validate198;
function validate198(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate198.errors = vErrors;
  return errors === 0;
}
exports.isSendInviteRequestJson = validate199;
function validate199(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.role === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "role" }, message: "must have required property 'role'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.email === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "email" }, message: "must have required property 'email'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.role !== void 0) {
      if (!validate63(data.role, { instancePath: instancePath + "/role", parentData: data, parentDataProperty: "role", rootData })) {
        vErrors = vErrors === null ? validate63.errors : vErrors.concat(validate63.errors);
        errors = vErrors.length;
      }
    }
    if (data.email !== void 0) {
      if (typeof data.email !== "string") {
        const err2 = { instancePath: instancePath + "/email", schemaPath: "#/properties/email/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate199.errors = vErrors;
  return errors === 0;
}
exports.isSendInviteRequest = validate201;
function validate201(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate198(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate198.errors : vErrors.concat(validate198.errors);
        errors = vErrors.length;
      }
    }
    if (data.json !== void 0) {
      if (!validate199(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate199.errors : vErrors.concat(validate199.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate201.errors = vErrors;
  return errors === 0;
}
exports.isGetInviteByIdResponse = validate204;
var schema104 = { "type": "object", "description": "ok", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "$ref": "#/components/schemas/ProjectInvite" } } };
function validate204(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema104.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate64(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate64.errors : vErrors.concat(validate64.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate204.errors = vErrors;
  return errors === 0;
}
exports.isGetInviteByIdRequestPathParameters = validate206;
function validate206(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.inviteId === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "inviteId" }, message: "must have required property 'inviteId'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err2 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.inviteId !== void 0) {
      if (typeof data.inviteId !== "string") {
        const err3 = { instancePath: instancePath + "/inviteId", schemaPath: "#/properties/inviteId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate206.errors = vErrors;
  return errors === 0;
}
exports.isGetInviteByIdRequest = validate207;
function validate207(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate206(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate206.errors : vErrors.concat(validate206.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate207.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous23 = validate209;
var schema107 = { "type": "object", "description": "ok", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "$ref": "#/components/schemas/ProjectInvite" } } };
function validate209(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema107.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate64(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate64.errors : vErrors.concat(validate64.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate209.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous24Json = validate211;
function validate211(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate211.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous24 = validate212;
var schema109 = { "type": "object", "description": "Validation Error", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [400] }, "json": { "$ref": "#/components/schemas/Anonymous24Json" } } };
function validate212(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 400)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema109.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate211(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate211.errors : vErrors.concat(validate211.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate212.errors = vErrors;
  return errors === 0;
}
exports.isChangeInviteStatusResponse = validate214;
function validate214(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  const _errs1 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs2 = errors;
  if (!validate209(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate209.errors : vErrors.concat(validate209.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (!validate212(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate212.errors : vErrors.concat(validate212.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
  }
  if (!valid0) {
    const err1 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  } else {
    errors = _errs1;
    if (vErrors !== null) {
      if (_errs1) {
        vErrors.length = _errs1;
      } else {
        vErrors = null;
      }
    }
  }
  validate214.errors = vErrors;
  return errors === 0;
}
exports.isChangeInviteStatusRequestPathParameters = validate217;
function validate217(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.inviteId === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "inviteId" }, message: "must have required property 'inviteId'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err2 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.inviteId !== void 0) {
      if (typeof data.inviteId !== "string") {
        const err3 = { instancePath: instancePath + "/inviteId", schemaPath: "#/properties/inviteId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate217.errors = vErrors;
  return errors === 0;
}
exports.isChangeInviteStatusRequestJson = validate218;
var schema112 = { "type": "object", "properties": { "action": { "type": "string", "enum": ["accept", "reject"] } } };
function validate218(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.action !== void 0) {
      let data0 = data.action;
      if (typeof data0 !== "string") {
        const err0 = { instancePath: instancePath + "/action", schemaPath: "#/properties/action/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err0];
        } else {
          vErrors.push(err0);
        }
        errors++;
      }
      if (!(data0 === "accept" || data0 === "reject")) {
        const err1 = { instancePath: instancePath + "/action", schemaPath: "#/properties/action/enum", keyword: "enum", params: { allowedValues: schema112.properties.action.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate218.errors = vErrors;
  return errors === 0;
}
exports.isChangeInviteStatusRequest = validate219;
function validate219(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate217(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate217.errors : vErrors.concat(validate217.errors);
        errors = vErrors.length;
      }
    }
    if (data.json !== void 0) {
      if (!validate218(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate218.errors : vErrors.concat(validate218.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate219.errors = vErrors;
  return errors === 0;
}
exports.isDeleteInviteResponseJson = validate222;
function validate222(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate222.errors = vErrors;
  return errors === 0;
}
exports.isDeleteInviteResponse = validate223;
var schema115 = { "type": "object", "description": "ok", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [204] }, "json": { "$ref": "#/components/schemas/DeleteInviteResponseJson" } } };
function validate223(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 204)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema115.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate222(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate222.errors : vErrors.concat(validate222.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate223.errors = vErrors;
  return errors === 0;
}
exports.isDeleteInviteRequestPathParameters = validate225;
function validate225(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.inviteId === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "inviteId" }, message: "must have required property 'inviteId'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err2 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.inviteId !== void 0) {
      if (typeof data.inviteId !== "string") {
        const err3 = { instancePath: instancePath + "/inviteId", schemaPath: "#/properties/inviteId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate225.errors = vErrors;
  return errors === 0;
}
exports.isDeleteInviteRequest = validate226;
function validate226(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate225(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate225.errors : vErrors.concat(validate225.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate226.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous25Json = validate228;
function validate228(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate228.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous25 = validate229;
var schema119 = { "type": "object", "description": "ok", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [204] }, "json": { "$ref": "#/components/schemas/Anonymous25Json" } } };
function validate229(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 204)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema119.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate228(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate228.errors : vErrors.concat(validate228.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate229.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous26Json = validate231;
function validate231(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate231.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous26 = validate232;
var schema121 = { "type": "object", "description": "User is not authorized to view project", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [403] }, "json": { "$ref": "#/components/schemas/Anonymous26Json" } } };
function validate232(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 403)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema121.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate231(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate231.errors : vErrors.concat(validate231.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate232.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous27Json = validate234;
function validate234(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate234.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous27 = validate235;
var schema123 = { "type": "object", "description": "Project not found", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [404] }, "json": { "$ref": "#/components/schemas/Anonymous27Json" } } };
function validate235(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 404)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema123.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate234(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate234.errors : vErrors.concat(validate234.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate235.errors = vErrors;
  return errors === 0;
}
exports.isCreateRecommendationsResponse = validate237;
function validate237(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  const _errs1 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs2 = errors;
  if (!validate229(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate229.errors : vErrors.concat(validate229.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (!validate232(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate232.errors : vErrors.concat(validate232.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
    const _errs4 = errors;
    if (!validate235(data, { instancePath, parentData, parentDataProperty, rootData })) {
      vErrors = vErrors === null ? validate235.errors : vErrors.concat(validate235.errors);
      errors = vErrors.length;
    }
    var _valid0 = _errs4 === errors;
    if (_valid0 && valid0) {
      valid0 = false;
      passing0 = [passing0, 2];
    } else {
      if (_valid0) {
        valid0 = true;
        passing0 = 2;
      }
    }
  }
  if (!valid0) {
    const err1 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  } else {
    errors = _errs1;
    if (vErrors !== null) {
      if (_errs1) {
        vErrors.length = _errs1;
      } else {
        vErrors = null;
      }
    }
  }
  validate237.errors = vErrors;
  return errors === 0;
}
exports.isCreateRecommendationsRequestPathParameters = validate241;
function validate241(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate241.errors = vErrors;
  return errors === 0;
}
exports.isCreateRecommendationsRequestQueryStringParameters = validate242;
function validate242(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data)) && data !== null) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.batch !== void 0) {
      if (!validate24(data.batch, { instancePath: instancePath + "/batch", parentData: data, parentDataProperty: "batch", rootData })) {
        vErrors = vErrors === null ? validate24.errors : vErrors.concat(validate24.errors);
        errors = vErrors.length;
      }
    }
  }
  validate242.errors = vErrors;
  return errors === 0;
}
exports.isCreateRecommendationsRequestJson = validate244;
function validate244(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.batchSize !== void 0) {
      let data0 = data.batchSize;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err0 = { instancePath: instancePath + "/batchSize", schemaPath: "#/properties/batchSize/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err0];
        } else {
          vErrors.push(err0);
        }
        errors++;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate244.errors = vErrors;
  return errors === 0;
}
exports.isCreateRecommendationsRequest = validate245;
function validate245(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate241(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate241.errors : vErrors.concat(validate241.errors);
        errors = vErrors.length;
      }
    }
    if (data.queryStringParameters !== void 0) {
      if (!validate242(data.queryStringParameters, { instancePath: instancePath + "/queryStringParameters", parentData: data, parentDataProperty: "queryStringParameters", rootData })) {
        vErrors = vErrors === null ? validate242.errors : vErrors.concat(validate242.errors);
        errors = vErrors.length;
      }
    }
    if (data.json !== void 0) {
      if (!validate244(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate244.errors : vErrors.concat(validate244.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate245.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous28 = validate249;
var schema129 = { "type": "object", "description": "List of Recommendations for next steps of a Project", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "type": "array", "items": { "$ref": "#/components/schemas/Recommendation" } } } };
function validate249(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema129.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      let data1 = data.json;
      if (Array.isArray(data1)) {
        const len0 = data1.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (!validate98(data1[i0], { instancePath: instancePath + "/json/" + i0, parentData: data1, parentDataProperty: i0, rootData })) {
            vErrors = vErrors === null ? validate98.errors : vErrors.concat(validate98.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err4 = { instancePath: instancePath + "/json", schemaPath: "#/properties/json/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
  } else {
    const err5 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err5];
    } else {
      vErrors.push(err5);
    }
    errors++;
  }
  validate249.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous29Json = validate251;
function validate251(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate251.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous29 = validate252;
var schema131 = { "type": "object", "description": "User is not authorized to view project", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [403] }, "json": { "$ref": "#/components/schemas/Anonymous29Json" } } };
function validate252(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 403)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema131.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate251(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate251.errors : vErrors.concat(validate251.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate252.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous30Json = validate254;
function validate254(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate254.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous30 = validate255;
var schema133 = { "type": "object", "description": "Project not found", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [404] }, "json": { "$ref": "#/components/schemas/Anonymous30Json" } } };
function validate255(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 404)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema133.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate254(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate254.errors : vErrors.concat(validate254.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate255.errors = vErrors;
  return errors === 0;
}
exports.isListRecommendationsResponse = validate257;
function validate257(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  const _errs1 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs2 = errors;
  if (!validate249(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate249.errors : vErrors.concat(validate249.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (!validate252(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate252.errors : vErrors.concat(validate252.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
    const _errs4 = errors;
    if (!validate255(data, { instancePath, parentData, parentDataProperty, rootData })) {
      vErrors = vErrors === null ? validate255.errors : vErrors.concat(validate255.errors);
      errors = vErrors.length;
    }
    var _valid0 = _errs4 === errors;
    if (_valid0 && valid0) {
      valid0 = false;
      passing0 = [passing0, 2];
    } else {
      if (_valid0) {
        valid0 = true;
        passing0 = 2;
      }
    }
  }
  if (!valid0) {
    const err1 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  } else {
    errors = _errs1;
    if (vErrors !== null) {
      if (_errs1) {
        vErrors.length = _errs1;
      } else {
        vErrors = null;
      }
    }
  }
  validate257.errors = vErrors;
  return errors === 0;
}
exports.isListRecommendationsRequestPathParameters = validate261;
function validate261(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate261.errors = vErrors;
  return errors === 0;
}
exports.isListRecommendationsRequestQueryStringParameters = validate262;
function validate262(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data)) && data !== null) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.batch !== void 0) {
      if (!validate24(data.batch, { instancePath: instancePath + "/batch", parentData: data, parentDataProperty: "batch", rootData })) {
        vErrors = vErrors === null ? validate24.errors : vErrors.concat(validate24.errors);
        errors = vErrors.length;
      }
    }
  }
  validate262.errors = vErrors;
  return errors === 0;
}
exports.isListRecommendationsRequest = validate264;
function validate264(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate261(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate261.errors : vErrors.concat(validate261.errors);
        errors = vErrors.length;
      }
    }
    if (data.queryStringParameters !== void 0) {
      if (!validate262(data.queryStringParameters, { instancePath: instancePath + "/queryStringParameters", parentData: data, parentDataProperty: "queryStringParameters", rootData })) {
        vErrors = vErrors === null ? validate262.errors : vErrors.concat(validate262.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate264.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous31Json = validate267;
function validate267(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate267.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous31 = validate268;
var schema139 = { "type": "object", "description": "Recommendation deleted successfully", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [204] }, "json": { "$ref": "#/components/schemas/Anonymous31Json" } } };
function validate268(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 204)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema139.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate267(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate267.errors : vErrors.concat(validate267.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate268.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous32Json = validate270;
function validate270(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate270.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous32 = validate271;
var schema141 = { "type": "object", "description": "User is not authorized to view project", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [403] }, "json": { "$ref": "#/components/schemas/Anonymous32Json" } } };
function validate271(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 403)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema141.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate270(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate270.errors : vErrors.concat(validate270.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate271.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous33Json = validate273;
function validate273(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate273.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous33 = validate274;
var schema143 = { "type": "object", "description": "Project or Recommendation not found", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [404] }, "json": { "$ref": "#/components/schemas/Anonymous33Json" } } };
function validate274(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 404)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema143.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate273(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate273.errors : vErrors.concat(validate273.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate274.errors = vErrors;
  return errors === 0;
}
exports.isRejectRecommendationResponse = validate276;
function validate276(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  const _errs1 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs2 = errors;
  if (!validate268(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate268.errors : vErrors.concat(validate268.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (!validate271(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate271.errors : vErrors.concat(validate271.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
    const _errs4 = errors;
    if (!validate274(data, { instancePath, parentData, parentDataProperty, rootData })) {
      vErrors = vErrors === null ? validate274.errors : vErrors.concat(validate274.errors);
      errors = vErrors.length;
    }
    var _valid0 = _errs4 === errors;
    if (_valid0 && valid0) {
      valid0 = false;
      passing0 = [passing0, 2];
    } else {
      if (_valid0) {
        valid0 = true;
        passing0 = 2;
      }
    }
  }
  if (!valid0) {
    const err1 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  } else {
    errors = _errs1;
    if (vErrors !== null) {
      if (_errs1) {
        vErrors.length = _errs1;
      } else {
        vErrors = null;
      }
    }
  }
  validate276.errors = vErrors;
  return errors === 0;
}
exports.isRejectRecommendationRequestPathParameters = validate280;
function validate280(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.recommendationId === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "recommendationId" }, message: "must have required property 'recommendationId'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err2 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.recommendationId !== void 0) {
      if (typeof data.recommendationId !== "string") {
        const err3 = { instancePath: instancePath + "/recommendationId", schemaPath: "#/properties/recommendationId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate280.errors = vErrors;
  return errors === 0;
}
exports.isRejectRecommendationRequest = validate281;
function validate281(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate280(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate280.errors : vErrors.concat(validate280.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate281.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous34 = validate283;
var schema147 = { "type": "object", "description": "List of experiments for a project", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "type": "array", "items": { "$ref": "#/components/schemas/Experiment" } } } };
function validate283(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema147.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      let data1 = data.json;
      if (Array.isArray(data1)) {
        const len0 = data1.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (!validate93(data1[i0], { instancePath: instancePath + "/json/" + i0, parentData: data1, parentDataProperty: i0, rootData })) {
            vErrors = vErrors === null ? validate93.errors : vErrors.concat(validate93.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err4 = { instancePath: instancePath + "/json", schemaPath: "#/properties/json/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
  } else {
    const err5 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err5];
    } else {
      vErrors.push(err5);
    }
    errors++;
  }
  validate283.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous35Json = validate285;
function validate285(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate285.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous35 = validate286;
var schema149 = { "type": "object", "description": "User is not authorized to view project", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [403] }, "json": { "$ref": "#/components/schemas/Anonymous35Json" } } };
function validate286(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 403)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema149.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate285(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate285.errors : vErrors.concat(validate285.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate286.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous36Json = validate288;
function validate288(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate288.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous36 = validate289;
var schema151 = { "type": "object", "description": "Project not found", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [404] }, "json": { "$ref": "#/components/schemas/Anonymous36Json" } } };
function validate289(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 404)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema151.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate288(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate288.errors : vErrors.concat(validate288.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate289.errors = vErrors;
  return errors === 0;
}
exports.isListExperimentsResponse = validate291;
function validate291(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  const _errs1 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs2 = errors;
  if (!validate283(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate283.errors : vErrors.concat(validate283.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (!validate286(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate286.errors : vErrors.concat(validate286.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
    const _errs4 = errors;
    if (!validate289(data, { instancePath, parentData, parentDataProperty, rootData })) {
      vErrors = vErrors === null ? validate289.errors : vErrors.concat(validate289.errors);
      errors = vErrors.length;
    }
    var _valid0 = _errs4 === errors;
    if (_valid0 && valid0) {
      valid0 = false;
      passing0 = [passing0, 2];
    } else {
      if (_valid0) {
        valid0 = true;
        passing0 = 2;
      }
    }
  }
  if (!valid0) {
    const err1 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  } else {
    errors = _errs1;
    if (vErrors !== null) {
      if (_errs1) {
        vErrors.length = _errs1;
      } else {
        vErrors = null;
      }
    }
  }
  validate291.errors = vErrors;
  return errors === 0;
}
exports.isListExperimentsRequestPathParameters = validate295;
function validate295(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate295.errors = vErrors;
  return errors === 0;
}
exports.isListExperimentsRequest = validate296;
function validate296(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate295(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate295.errors : vErrors.concat(validate295.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate296.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous37 = validate298;
var schema155 = { "type": "object", "description": "Experiments succesfully created", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [201] }, "json": { "type": "array", "items": { "$ref": "#/components/schemas/Experiment" } } } };
function validate298(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 201)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema155.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      let data1 = data.json;
      if (Array.isArray(data1)) {
        const len0 = data1.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (!validate93(data1[i0], { instancePath: instancePath + "/json/" + i0, parentData: data1, parentDataProperty: i0, rootData })) {
            vErrors = vErrors === null ? validate93.errors : vErrors.concat(validate93.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err4 = { instancePath: instancePath + "/json", schemaPath: "#/properties/json/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
  } else {
    const err5 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err5];
    } else {
      vErrors.push(err5);
    }
    errors++;
  }
  validate298.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous38Json = validate300;
function validate300(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate300.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous38 = validate301;
var schema157 = { "type": "object", "description": "Invalid input", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [400] }, "json": { "$ref": "#/components/schemas/Anonymous38Json" } } };
function validate301(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 400)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema157.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate300(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate300.errors : vErrors.concat(validate300.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate301.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous39Json = validate303;
function validate303(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate303.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous39 = validate304;
var schema159 = { "type": "object", "description": "User is not authorized to change experiment", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [403] }, "json": { "$ref": "#/components/schemas/Anonymous39Json" } } };
function validate304(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 403)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema159.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate303(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate303.errors : vErrors.concat(validate303.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate304.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous40Json = validate306;
function validate306(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate306.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous40 = validate307;
var schema161 = { "type": "object", "description": "Experiement not found", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [404] }, "json": { "$ref": "#/components/schemas/Anonymous40Json" } } };
function validate307(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 404)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema161.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate306(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate306.errors : vErrors.concat(validate306.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate307.errors = vErrors;
  return errors === 0;
}
exports.isCreateExperimentsResponse = validate309;
function validate309(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  const _errs1 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs2 = errors;
  if (!validate298(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate298.errors : vErrors.concat(validate298.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (!validate301(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate301.errors : vErrors.concat(validate301.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
    const _errs4 = errors;
    if (!validate304(data, { instancePath, parentData, parentDataProperty, rootData })) {
      vErrors = vErrors === null ? validate304.errors : vErrors.concat(validate304.errors);
      errors = vErrors.length;
    }
    var _valid0 = _errs4 === errors;
    if (_valid0 && valid0) {
      valid0 = false;
      passing0 = [passing0, 2];
    } else {
      if (_valid0) {
        valid0 = true;
        passing0 = 2;
      }
      const _errs5 = errors;
      if (!validate307(data, { instancePath, parentData, parentDataProperty, rootData })) {
        vErrors = vErrors === null ? validate307.errors : vErrors.concat(validate307.errors);
        errors = vErrors.length;
      }
      var _valid0 = _errs5 === errors;
      if (_valid0 && valid0) {
        valid0 = false;
        passing0 = [passing0, 3];
      } else {
        if (_valid0) {
          valid0 = true;
          passing0 = 3;
        }
      }
    }
  }
  if (!valid0) {
    const err1 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  } else {
    errors = _errs1;
    if (vErrors !== null) {
      if (_errs1) {
        vErrors.length = _errs1;
      } else {
        vErrors = null;
      }
    }
  }
  validate309.errors = vErrors;
  return errors === 0;
}
exports.isCreateExperimentsRequestPathParameters = validate314;
function validate314(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate314.errors = vErrors;
  return errors === 0;
}
exports.isCreateExperimentsRequestJsonItemResults = validate315;
function validate315(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      let data0 = data[key0];
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err0 = { instancePath: instancePath + "/" + key0.replace(/~/g, "~0").replace(/\//g, "~1"), schemaPath: "#/additionalProperties/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err0];
        } else {
          vErrors.push(err0);
        }
        errors++;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate315.errors = vErrors;
  return errors === 0;
}
exports.isCreateExperimentsRequestJsonItem = validate316;
function validate316(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.parameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "parameters" }, message: "must have required property 'parameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.results === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "results" }, message: "must have required property 'results'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.recommendationId !== void 0) {
      if (typeof data.recommendationId !== "string") {
        const err2 = { instancePath: instancePath + "/recommendationId", schemaPath: "#/properties/recommendationId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.parameters !== void 0) {
      if (!validate53(data.parameters, { instancePath: instancePath + "/parameters", parentData: data, parentDataProperty: "parameters", rootData })) {
        vErrors = vErrors === null ? validate53.errors : vErrors.concat(validate53.errors);
        errors = vErrors.length;
      }
    }
    if (data.results !== void 0) {
      if (!validate315(data.results, { instancePath: instancePath + "/results", parentData: data, parentDataProperty: "results", rootData })) {
        vErrors = vErrors === null ? validate315.errors : vErrors.concat(validate315.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate316.errors = vErrors;
  return errors === 0;
}
exports.isCreateExperimentsRequestJson = validate319;
function validate319(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (Array.isArray(data)) {
    const len0 = data.length;
    for (let i0 = 0; i0 < len0; i0++) {
      if (!validate316(data[i0], { instancePath: instancePath + "/" + i0, parentData: data, parentDataProperty: i0, rootData })) {
        vErrors = vErrors === null ? validate316.errors : vErrors.concat(validate316.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "array" }, message: "must be array" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate319.errors = vErrors;
  return errors === 0;
}
exports.isCreateExperimentsRequest = validate321;
function validate321(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate314(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate314.errors : vErrors.concat(validate314.errors);
        errors = vErrors.length;
      }
    }
    if (data.json !== void 0) {
      if (!validate319(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate319.errors : vErrors.concat(validate319.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate321.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous41 = validate324;
var schema168 = { "type": "object", "description": "List of experiments for a project", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "type": "array", "items": { "$ref": "#/components/schemas/Experiment" } } } };
function validate324(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema168.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      let data1 = data.json;
      if (Array.isArray(data1)) {
        const len0 = data1.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (!validate93(data1[i0], { instancePath: instancePath + "/json/" + i0, parentData: data1, parentDataProperty: i0, rootData })) {
            vErrors = vErrors === null ? validate93.errors : vErrors.concat(validate93.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err4 = { instancePath: instancePath + "/json", schemaPath: "#/properties/json/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
  } else {
    const err5 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err5];
    } else {
      vErrors.push(err5);
    }
    errors++;
  }
  validate324.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous42Json = validate326;
function validate326(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate326.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous42 = validate327;
var schema170 = { "type": "object", "description": "User is not authorized to view project", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [403] }, "json": { "$ref": "#/components/schemas/Anonymous42Json" } } };
function validate327(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 403)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema170.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate326(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate326.errors : vErrors.concat(validate326.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate327.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous43Json = validate329;
function validate329(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate329.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous43 = validate330;
var schema172 = { "type": "object", "description": "Experiement not found", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [404] }, "json": { "$ref": "#/components/schemas/Anonymous43Json" } } };
function validate330(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 404)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema172.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate329(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate329.errors : vErrors.concat(validate329.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate330.errors = vErrors;
  return errors === 0;
}
exports.isChangeHistoryResponse = validate332;
function validate332(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  const _errs1 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs2 = errors;
  if (!validate324(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate324.errors : vErrors.concat(validate324.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (!validate327(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate327.errors : vErrors.concat(validate327.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
    const _errs4 = errors;
    if (!validate330(data, { instancePath, parentData, parentDataProperty, rootData })) {
      vErrors = vErrors === null ? validate330.errors : vErrors.concat(validate330.errors);
      errors = vErrors.length;
    }
    var _valid0 = _errs4 === errors;
    if (_valid0 && valid0) {
      valid0 = false;
      passing0 = [passing0, 2];
    } else {
      if (_valid0) {
        valid0 = true;
        passing0 = 2;
      }
    }
  }
  if (!valid0) {
    const err1 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  } else {
    errors = _errs1;
    if (vErrors !== null) {
      if (_errs1) {
        vErrors.length = _errs1;
      } else {
        vErrors = null;
      }
    }
  }
  validate332.errors = vErrors;
  return errors === 0;
}
exports.isChangeHistoryRequestPathParameters = validate336;
function validate336(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate336.errors = vErrors;
  return errors === 0;
}
exports.isChangeHistoryRequestJsonDeleteItem = validate337;
function validate337(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.experimentId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "experimentId" }, message: "must have required property 'experimentId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.experimentId !== void 0) {
      if (typeof data.experimentId !== "string") {
        const err1 = { instancePath: instancePath + "/experimentId", schemaPath: "#/properties/experimentId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate337.errors = vErrors;
  return errors === 0;
}
exports.isChangeHistoryRequestJsonDelete = validate338;
function validate338(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (Array.isArray(data)) {
    const len0 = data.length;
    for (let i0 = 0; i0 < len0; i0++) {
      if (!validate337(data[i0], { instancePath: instancePath + "/" + i0, parentData: data, parentDataProperty: i0, rootData })) {
        vErrors = vErrors === null ? validate337.errors : vErrors.concat(validate337.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "array" }, message: "must be array" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate338.errors = vErrors;
  return errors === 0;
}
exports.isChangeHistoryRequestJsonModifyItemResults = validate340;
function validate340(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      let data0 = data[key0];
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err0 = { instancePath: instancePath + "/" + key0.replace(/~/g, "~0").replace(/\//g, "~1"), schemaPath: "#/additionalProperties/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err0];
        } else {
          vErrors.push(err0);
        }
        errors++;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate340.errors = vErrors;
  return errors === 0;
}
exports.isChangeHistoryRequestJsonModifyItem = validate341;
function validate341(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.experimentId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "experimentId" }, message: "must have required property 'experimentId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.results === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "results" }, message: "must have required property 'results'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.experimentId !== void 0) {
      if (typeof data.experimentId !== "string") {
        const err2 = { instancePath: instancePath + "/experimentId", schemaPath: "#/properties/experimentId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.results !== void 0) {
      if (!validate340(data.results, { instancePath: instancePath + "/results", parentData: data, parentDataProperty: "results", rootData })) {
        vErrors = vErrors === null ? validate340.errors : vErrors.concat(validate340.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate341.errors = vErrors;
  return errors === 0;
}
exports.isChangeHistoryRequestJsonModify = validate343;
function validate343(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (Array.isArray(data)) {
    const len0 = data.length;
    for (let i0 = 0; i0 < len0; i0++) {
      if (!validate341(data[i0], { instancePath: instancePath + "/" + i0, parentData: data, parentDataProperty: i0, rootData })) {
        vErrors = vErrors === null ? validate341.errors : vErrors.concat(validate341.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "array" }, message: "must be array" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate343.errors = vErrors;
  return errors === 0;
}
exports.isChangeHistoryRequestJson = validate345;
function validate345(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.delete !== void 0) {
      if (!validate338(data.delete, { instancePath: instancePath + "/delete", parentData: data, parentDataProperty: "delete", rootData })) {
        vErrors = vErrors === null ? validate338.errors : vErrors.concat(validate338.errors);
        errors = vErrors.length;
      }
    }
    if (data.modify !== void 0) {
      if (!validate343(data.modify, { instancePath: instancePath + "/modify", parentData: data, parentDataProperty: "modify", rootData })) {
        vErrors = vErrors === null ? validate343.errors : vErrors.concat(validate343.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate345.errors = vErrors;
  return errors === 0;
}
exports.isChangeHistoryRequest = validate348;
function validate348(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate336(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate336.errors : vErrors.concat(validate336.errors);
        errors = vErrors.length;
      }
    }
    if (data.json !== void 0) {
      if (!validate345(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate345.errors : vErrors.concat(validate345.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate348.errors = vErrors;
  return errors === 0;
}
exports.isListConstraintsResponse = validate351;
var schema182 = { "type": "object", "description": "ok", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "type": "array", "items": { "$ref": "#/components/schemas/Constraint" } } } };
function validate351(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema182.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      let data1 = data.json;
      if (Array.isArray(data1)) {
        const len0 = data1.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (!validate75(data1[i0], { instancePath: instancePath + "/json/" + i0, parentData: data1, parentDataProperty: i0, rootData })) {
            vErrors = vErrors === null ? validate75.errors : vErrors.concat(validate75.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err4 = { instancePath: instancePath + "/json", schemaPath: "#/properties/json/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
  } else {
    const err5 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err5];
    } else {
      vErrors.push(err5);
    }
    errors++;
  }
  validate351.errors = vErrors;
  return errors === 0;
}
exports.isListConstraintsRequestPathParameters = validate353;
function validate353(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate353.errors = vErrors;
  return errors === 0;
}
exports.isListConstraintsRequest = validate354;
function validate354(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate353(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate353.errors : vErrors.concat(validate353.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate354.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous44Json = validate356;
function validate356(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate356.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous44 = validate357;
var schema186 = { "type": "object", "description": "Returns Baybe Configs for the Project", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "$ref": "#/components/schemas/Anonymous44Json" } } };
function validate357(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema186.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate356(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate356.errors : vErrors.concat(validate356.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate357.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous45Json = validate359;
function validate359(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate359.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous45 = validate360;
var schema188 = { "type": "object", "description": "User is not authorized to view project configs", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [403] }, "json": { "$ref": "#/components/schemas/Anonymous45Json" } } };
function validate360(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 403)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema188.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate359(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate359.errors : vErrors.concat(validate359.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate360.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous46Json = validate362;
function validate362(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate362.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous46 = validate363;
var schema190 = { "type": "object", "description": "Project not found", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [404] }, "json": { "$ref": "#/components/schemas/Anonymous46Json" } } };
function validate363(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 404)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema190.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate362(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate362.errors : vErrors.concat(validate362.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate363.errors = vErrors;
  return errors === 0;
}
exports.isGetBaybeConfigsResponse = validate365;
function validate365(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  const _errs1 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs2 = errors;
  if (!validate357(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate357.errors : vErrors.concat(validate357.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (!validate360(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate360.errors : vErrors.concat(validate360.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
    const _errs4 = errors;
    if (!validate363(data, { instancePath, parentData, parentDataProperty, rootData })) {
      vErrors = vErrors === null ? validate363.errors : vErrors.concat(validate363.errors);
      errors = vErrors.length;
    }
    var _valid0 = _errs4 === errors;
    if (_valid0 && valid0) {
      valid0 = false;
      passing0 = [passing0, 2];
    } else {
      if (_valid0) {
        valid0 = true;
        passing0 = 2;
      }
    }
  }
  if (!valid0) {
    const err1 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  } else {
    errors = _errs1;
    if (vErrors !== null) {
      if (_errs1) {
        vErrors.length = _errs1;
      } else {
        vErrors = null;
      }
    }
  }
  validate365.errors = vErrors;
  return errors === 0;
}
exports.isGetBaybeConfigsRequestPathParameters = validate369;
function validate369(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate369.errors = vErrors;
  return errors === 0;
}
exports.isGetBaybeConfigsRequest = validate370;
function validate370(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate369(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate369.errors : vErrors.concat(validate369.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate370.errors = vErrors;
  return errors === 0;
}
exports.isCreateFeedbackResponseJson = validate372;
function validate372(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate372.errors = vErrors;
  return errors === 0;
}
exports.isCreateFeedbackResponse = validate373;
var schema195 = { "type": "object", "description": "Feedback successfully posted.", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [201] }, "json": { "$ref": "#/components/schemas/CreateFeedbackResponseJson" } } };
function validate373(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 201)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema195.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate372(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate372.errors : vErrors.concat(validate372.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate373.errors = vErrors;
  return errors === 0;
}
exports.isCreateFeedbackRequestJson = validate375;
function validate375(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.subject === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "subject" }, message: "must have required property 'subject'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.message === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "message" }, message: "must have required property 'message'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.subject !== void 0) {
      if (typeof data.subject !== "string") {
        const err2 = { instancePath: instancePath + "/subject", schemaPath: "#/properties/subject/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.message !== void 0) {
      if (typeof data.message !== "string") {
        const err3 = { instancePath: instancePath + "/message", schemaPath: "#/properties/message/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate375.errors = vErrors;
  return errors === 0;
}
exports.isCreateFeedbackRequest = validate376;
function validate376(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.json === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json !== void 0) {
      if (!validate375(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate375.errors : vErrors.concat(validate375.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate376.errors = vErrors;
  return errors === 0;
}
var validators = {
  GetUser: {
    isRequest: exports.isGetUserRequest,
    isResponse: exports.isGetUserResponse,
    assertRequest: assertGetUserRequest,
    assertResponse: assertGetUserResponse
  },
  ListUserInvites: {
    isRequest: exports.isListUserInvitesRequest,
    isResponse: exports.isListUserInvitesResponse,
    assertRequest: assertListUserInvitesRequest,
    assertResponse: assertListUserInvitesResponse
  },
  ListProjects: {
    isRequest: exports.isListProjectsRequest,
    isResponse: exports.isListProjectsResponse,
    assertRequest: assertListProjectsRequest,
    assertResponse: assertListProjectsResponse
  },
  CreateProject: {
    isRequest: exports.isCreateProjectRequest,
    isResponse: exports.isCreateProjectResponse,
    assertRequest: assertCreateProjectRequest,
    assertResponse: assertCreateProjectResponse
  },
  DeleteProject: {
    isRequest: exports.isDeleteProjectRequest,
    isResponse: exports.isDeleteProjectResponse,
    assertRequest: assertDeleteProjectRequest,
    assertResponse: assertDeleteProjectResponse
  },
  GetProjectById: {
    isRequest: exports.isGetProjectByIdRequest,
    isResponse: exports.isGetProjectByIdResponse,
    assertRequest: assertGetProjectByIdRequest,
    assertResponse: assertGetProjectByIdResponse
  },
  ExpandChemicalSpace: {
    isRequest: exports.isExpandChemicalSpaceRequest,
    isResponse: exports.isExpandChemicalSpaceResponse,
    assertRequest: assertExpandChemicalSpaceRequest,
    assertResponse: assertExpandChemicalSpaceResponse
  },
  CloseProject: {
    isRequest: exports.isCloseProjectRequest,
    isResponse: exports.isCloseProjectResponse,
    assertRequest: assertCloseProjectRequest,
    assertResponse: assertCloseProjectResponse
  },
  ReopenProject: {
    isRequest: exports.isReopenProjectRequest,
    isResponse: exports.isReopenProjectResponse,
    assertRequest: assertReopenProjectRequest,
    assertResponse: assertReopenProjectResponse
  },
  ListInvites: {
    isRequest: exports.isListInvitesRequest,
    isResponse: exports.isListInvitesResponse,
    assertRequest: assertListInvitesRequest,
    assertResponse: assertListInvitesResponse
  },
  SendInvite: {
    isRequest: exports.isSendInviteRequest,
    isResponse: exports.isSendInviteResponse,
    assertRequest: assertSendInviteRequest,
    assertResponse: assertSendInviteResponse
  },
  GetInviteById: {
    isRequest: exports.isGetInviteByIdRequest,
    isResponse: exports.isGetInviteByIdResponse,
    assertRequest: assertGetInviteByIdRequest,
    assertResponse: assertGetInviteByIdResponse
  },
  ChangeInviteStatus: {
    isRequest: exports.isChangeInviteStatusRequest,
    isResponse: exports.isChangeInviteStatusResponse,
    assertRequest: assertChangeInviteStatusRequest,
    assertResponse: assertChangeInviteStatusResponse
  },
  DeleteInvite: {
    isRequest: exports.isDeleteInviteRequest,
    isResponse: exports.isDeleteInviteResponse,
    assertRequest: assertDeleteInviteRequest,
    assertResponse: assertDeleteInviteResponse
  },
  CreateRecommendations: {
    isRequest: exports.isCreateRecommendationsRequest,
    isResponse: exports.isCreateRecommendationsResponse,
    assertRequest: assertCreateRecommendationsRequest,
    assertResponse: assertCreateRecommendationsResponse
  },
  ListRecommendations: {
    isRequest: exports.isListRecommendationsRequest,
    isResponse: exports.isListRecommendationsResponse,
    assertRequest: assertListRecommendationsRequest,
    assertResponse: assertListRecommendationsResponse
  },
  RejectRecommendation: {
    isRequest: exports.isRejectRecommendationRequest,
    isResponse: exports.isRejectRecommendationResponse,
    assertRequest: assertRejectRecommendationRequest,
    assertResponse: assertRejectRecommendationResponse
  },
  ListExperiments: {
    isRequest: exports.isListExperimentsRequest,
    isResponse: exports.isListExperimentsResponse,
    assertRequest: assertListExperimentsRequest,
    assertResponse: assertListExperimentsResponse
  },
  CreateExperiments: {
    isRequest: exports.isCreateExperimentsRequest,
    isResponse: exports.isCreateExperimentsResponse,
    assertRequest: assertCreateExperimentsRequest,
    assertResponse: assertCreateExperimentsResponse
  },
  ChangeHistory: {
    isRequest: exports.isChangeHistoryRequest,
    isResponse: exports.isChangeHistoryResponse,
    assertRequest: assertChangeHistoryRequest,
    assertResponse: assertChangeHistoryResponse
  },
  ListConstraints: {
    isRequest: exports.isListConstraintsRequest,
    isResponse: exports.isListConstraintsResponse,
    assertRequest: assertListConstraintsRequest,
    assertResponse: assertListConstraintsResponse
  },
  GetBaybeConfigs: {
    isRequest: exports.isGetBaybeConfigsRequest,
    isResponse: exports.isGetBaybeConfigsResponse,
    assertRequest: assertGetBaybeConfigsRequest,
    assertResponse: assertGetBaybeConfigsResponse
  },
  CreateFeedback: {
    isRequest: exports.isCreateFeedbackRequest,
    isResponse: exports.isCreateFeedbackResponse,
    assertRequest: assertCreateFeedbackRequest,
    assertResponse: assertCreateFeedbackResponse
  }
};
var routes = {
  "GET /user": "GetUser",
  "GET /invite": "ListUserInvites",
  "GET /project": "ListProjects",
  "POST /project": "CreateProject",
  "DELETE /project": "DeleteProject",
  "GET /project/{projectId}": "GetProjectById",
  "PUT /project/{projectId}": "ExpandChemicalSpace",
  "DELETE /project/{projectId}": "CloseProject",
  "POST /project/{projectId}": "ReopenProject",
  "GET /project/{projectId}/invite": "ListInvites",
  "POST /project/{projectId}/invite": "SendInvite",
  "GET /project/{projectId}/invite/{inviteId}": "GetInviteById",
  "PUT /project/{projectId}/invite/{inviteId}": "ChangeInviteStatus",
  "DELETE /project/{projectId}/invite/{inviteId}": "DeleteInvite",
  "POST /project/{projectId}/recommendation": "CreateRecommendations",
  "GET /project/{projectId}/recommendation": "ListRecommendations",
  "DELETE /project/{projectId}/recommendation/{recommendationId}": "RejectRecommendation",
  "GET /project/{projectId}/experiment": "ListExperiments",
  "POST /project/{projectId}/experiment": "CreateExperiments",
  "PUT /project/{projectId}/experiment": "ChangeHistory",
  "GET /project/{projectId}/constraints": "ListConstraints",
  "GET /project/{projectId}/config": "GetBaybeConfigs",
  "POST /feedback": "CreateFeedback"
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  AcquisitionFunctionType,
  BatchQueryParameterStringValue,
  CombinerType,
  ConditionsType,
  ConstraintContinuousOperatorType,
  ConstraintDiscreteConditionOperatorType,
  ConstraintsSubTypeContinuous,
  ConstraintsSubTypeDiscrete,
  ConstraintsType,
  ExperimentStatus,
  InitialGuessMethodType,
  ParameterType,
  ProjectRole,
  ProjectStatus,
  RecommendationStatus,
  SurrogateModelType,
  assertAcquisitionFunctionType,
  assertAnonymous1,
  assertAnonymous10,
  assertAnonymous10Json,
  assertAnonymous11,
  assertAnonymous11Json,
  assertAnonymous12,
  assertAnonymous15,
  assertAnonymous15Json,
  assertAnonymous16,
  assertAnonymous16Json,
  assertAnonymous17,
  assertAnonymous20,
  assertAnonymous20Json,
  assertAnonymous21,
  assertAnonymous21Json,
  assertAnonymous22,
  assertAnonymous22Json,
  assertAnonymous23,
  assertAnonymous24,
  assertAnonymous24Json,
  assertAnonymous25,
  assertAnonymous25Json,
  assertAnonymous26,
  assertAnonymous26Json,
  assertAnonymous27,
  assertAnonymous27Json,
  assertAnonymous28,
  assertAnonymous29,
  assertAnonymous29Json,
  assertAnonymous30,
  assertAnonymous30Json,
  assertAnonymous31,
  assertAnonymous31Json,
  assertAnonymous32,
  assertAnonymous32Json,
  assertAnonymous33,
  assertAnonymous33Json,
  assertAnonymous34,
  assertAnonymous35,
  assertAnonymous35Json,
  assertAnonymous36,
  assertAnonymous36Json,
  assertAnonymous37,
  assertAnonymous38,
  assertAnonymous38Json,
  assertAnonymous39,
  assertAnonymous39Json,
  assertAnonymous40,
  assertAnonymous40Json,
  assertAnonymous41,
  assertAnonymous42,
  assertAnonymous42Json,
  assertAnonymous43,
  assertAnonymous43Json,
  assertAnonymous44,
  assertAnonymous44Json,
  assertAnonymous45,
  assertAnonymous45Json,
  assertAnonymous46,
  assertAnonymous46Json,
  assertAnonymous6,
  assertAnonymous9,
  assertAnonymous9Json,
  assertBatchQueryParameterNumericValue,
  assertBatchQueryParameterStringValue,
  assertBatchQueryParameterValue,
  assertChangeHistoryRequest,
  assertChangeHistoryRequestJson,
  assertChangeHistoryRequestJsonDelete,
  assertChangeHistoryRequestJsonDeleteItem,
  assertChangeHistoryRequestJsonModify,
  assertChangeHistoryRequestJsonModifyItem,
  assertChangeHistoryRequestJsonModifyItemResults,
  assertChangeHistoryRequestPathParameters,
  assertChangeHistoryResponse,
  assertChangeInviteStatusRequest,
  assertChangeInviteStatusRequestJson,
  assertChangeInviteStatusRequestPathParameters,
  assertChangeInviteStatusResponse,
  assertCloseProjectRequest,
  assertCloseProjectRequestPathParameters,
  assertCloseProjectResponse,
  assertCloseProjectResponseJson,
  assertCombinerType,
  assertConditionsType,
  assertConstraint,
  assertConstraintContinuousOperatorType,
  assertConstraintDiscreteConditionOperatorType,
  assertConstraintsSubTypeContinuous,
  assertConstraintsSubTypeDiscrete,
  assertConstraintsType,
  assertContinuousConstraint,
  assertCreateExperimentsRequest,
  assertCreateExperimentsRequestJson,
  assertCreateExperimentsRequestJsonItem,
  assertCreateExperimentsRequestJsonItemResults,
  assertCreateExperimentsRequestPathParameters,
  assertCreateExperimentsResponse,
  assertCreateFeedbackRequest,
  assertCreateFeedbackRequestJson,
  assertCreateFeedbackResponse,
  assertCreateFeedbackResponseJson,
  assertCreateProjectRequest,
  assertCreateProjectRequestJson,
  assertCreateProjectResponse,
  assertCreateRecommendationsRequest,
  assertCreateRecommendationsRequestJson,
  assertCreateRecommendationsRequestPathParameters,
  assertCreateRecommendationsRequestQueryStringParameters,
  assertCreateRecommendationsResponse,
  assertCustomParameterValue,
  assertDeleteInviteRequest,
  assertDeleteInviteRequestPathParameters,
  assertDeleteInviteResponse,
  assertDeleteInviteResponseJson,
  assertDeleteProjectRequest,
  assertDeleteProjectRequestJson,
  assertDeleteProjectResponse,
  assertDiscreteConditions,
  assertDiscreteConstraint,
  assertExpandChemicalSpaceRequest,
  assertExpandChemicalSpaceRequestJson,
  assertExpandChemicalSpaceRequestJsonExperiments,
  assertExpandChemicalSpaceRequestPathParameters,
  assertExpandChemicalSpaceResponse,
  assertExperiment,
  assertExperimentResults,
  assertExperimentStatus,
  assertExpertOptions,
  assertGetBaybeConfigsRequest,
  assertGetBaybeConfigsRequestPathParameters,
  assertGetBaybeConfigsResponse,
  assertGetInviteByIdRequest,
  assertGetInviteByIdRequestPathParameters,
  assertGetInviteByIdResponse,
  assertGetProjectByIdRequest,
  assertGetProjectByIdRequestPathParameters,
  assertGetProjectByIdResponse,
  assertGetUserRequest,
  assertGetUserResponse,
  assertInitialGuessMethodType,
  assertListConstraintsRequest,
  assertListConstraintsRequestPathParameters,
  assertListConstraintsResponse,
  assertListExperimentsRequest,
  assertListExperimentsRequestPathParameters,
  assertListExperimentsResponse,
  assertListInvitesRequest,
  assertListInvitesRequestPathParameters,
  assertListInvitesResponse,
  assertListProjectsRequest,
  assertListProjectsResponse,
  assertListRecommendationsRequest,
  assertListRecommendationsRequestPathParameters,
  assertListRecommendationsRequestQueryStringParameters,
  assertListRecommendationsResponse,
  assertListUserInvitesRequest,
  assertListUserInvitesRequestQueryStringParameters,
  assertListUserInvitesResponse,
  assertNumericParameterValue,
  assertNumericalContinuousParameter,
  assertParameter,
  assertParameterRecommendation,
  assertParameterRecommendationValue,
  assertParameterType,
  assertParameterValue,
  assertProject,
  assertProjectInvite,
  assertProjectRole,
  assertProjectStatus,
  assertRecommendation,
  assertRecommendationStatus,
  assertRejectRecommendationRequest,
  assertRejectRecommendationRequestPathParameters,
  assertRejectRecommendationResponse,
  assertReopenProjectRequest,
  assertReopenProjectRequestPathParameters,
  assertReopenProjectResponse,
  assertReopenProjectResponseJson,
  assertSendInviteRequest,
  assertSendInviteRequestJson,
  assertSendInviteRequestPathParameters,
  assertSendInviteResponse,
  assertSmilesParameterValue,
  assertStringArray,
  assertStringParameterValue,
  assertSubSelectionCondition,
  assertSurrogateModelType,
  assertTargetVariable,
  assertThresholdCondition,
  assertUser,
  routes,
  validators
});
