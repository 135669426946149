import {
  LdBadge,
  LdButton,
  LdCard,
  LdIcon,
  LdLabel,
  LdTable,
  LdTableBody,
  LdTableCell,
  LdTableHead,
  LdTableHeader,
  LdTableRow,
  LdTypo,
} from '@emdgroup-liquid/liquid/dist/react';
import React, { MouseEventHandler } from 'react';

import Card from 'components/Card';
import {
  ConstraintsSubTypeDiscrete,
  ConstraintsType,
  SubSelectionCondition,
  ThresholdCondition,
} from 'types/api/types';
import {
  ConstraintDiscreteSubtypeSimple,
  ConstraintFormItem,
} from 'types/app/types';

type Props = {
  onDelete: MouseEventHandler;
  onEdit: MouseEventHandler;
  constraint: ConstraintFormItem;
};

const ConsraintDetails: React.FC<Props> = ({
  constraint,
  onDelete,
  onEdit,
}: Props) => {
  const {
    type,
    subType,
    constraintId,
    parameters,
    combiner,
    conditions,
    rhs,
    operator,
    coefficients,
    affectedParamters,
  } = constraint;
  return (
    <Card className=" p-4 w-full">
      <div className="flex flex-row w-full items-center gap-ld-8">
        <div className="flex flex-col flex-1 justify-start items-start gap-4 w-full">
          <div className="flex flex-grow w-full justify-between">
            <LdLabel position="left" className="flex flex-row align-middle">
              <LdTypo variant="h4" className="flex break-all">
                {subType?.split(/(?=[A-Z])/).join(' ')}
              </LdTypo>
            </LdLabel>
            <div className="flex flex-row">
              <LdButton mode="danger-secondary" onClick={onDelete}>
                <LdIcon name="cross" aria-label="remove parameter" />
              </LdButton>
              <LdButton onClick={onEdit} className="ml-2" mode="secondary">
                <LdIcon name="pen" aria-label="edit parameter" />
              </LdButton>
            </div>
          </div>
          <div className="flex-grow w-full">
            <div className="grid grid-cols-1 divide-y divide-sensitive-grey-darker mt-3">
              {type === ConstraintsType.continuous && parameters && (
                <>
                  <div className="flex flex-row justify-evenly">
                    <LdLabel
                      position="left"
                      className="flex flex-row align-middle"
                    >
                      <span className="flex mt-3 mb-3">
                        Right-Hand Side:  {rhs}
                      </span>
                    </LdLabel>
                    <LdLabel
                      position="left"
                      className="flex flex-row align-middle"
                    >
                      <span className="flex mt-3 mb-3">
                        Operator
                        <LdBadge className="ld-badge--info">{operator}</LdBadge>
                      </span>
                    </LdLabel>
                  </div>
                  <LdTable>
                    <LdTableHead>
                      <LdTableRow>
                        <LdTableHeader>Parameter</LdTableHeader>
                        <LdTableHeader>Coefficient</LdTableHeader>
                      </LdTableRow>
                    </LdTableHead>
                    <LdTableBody>
                      {Object.entries(parameters)?.map(([key, value]) =>
                        value ? (
                          <LdTableRow key={`${key}-${constraintId}`}>
                            <LdTableCell>
                              <LdTypo
                                variant="body-s"
                                className="max-w-[10rem]"
                                style={{ 'overflow-wrap': 'anywhere' }}
                              >
                                {value}
                              </LdTypo>
                            </LdTableCell>
                            <LdTableCell>
                              <LdTypo
                                variant="body-s"
                                className="max-w-[10rem]"
                                style={{ 'overflow-wrap': 'anywhere' }}
                              >
                                {coefficients?.[key]}
                              </LdTypo>
                            </LdTableCell>
                          </LdTableRow>
                        ) : null
                      )}
                    </LdTableBody>
                  </LdTable>
                </>
              )}
              {type === ConstraintsType.discrete && parameters && (
                <>
                  {subType &&
                  !ConstraintDiscreteSubtypeSimple.includes(subType) ? (
                    <>
                      <LdTable>
                        <LdTableHead>
                          <LdTableRow>
                            <LdTableHeader>Parameter</LdTableHeader>
                            {subType ===
                              ConstraintsSubTypeDiscrete.DiscreteDependenciesConstraint && (
                              <LdTableHeader>Affected Parameters</LdTableHeader>
                            )}
                            <LdTableHeader>Condition</LdTableHeader>
                          </LdTableRow>
                        </LdTableHead>
                        <LdTableBody>
                          {Object.entries(parameters)?.map(([key, value]) =>
                            value ? (
                              <LdTableRow key={`${key}-${constraintId}`}>
                                <LdTableCell>
                                  <LdTypo
                                    variant="body-s"
                                    className="max-w-[10rem]"
                                    style={{ 'overflow-wrap': 'anywhere' }}
                                  >
                                    {value}
                                  </LdTypo>
                                </LdTableCell>
                                {subType ===
                                  ConstraintsSubTypeDiscrete.DiscreteDependenciesConstraint && (
                                  <LdTableCell>
                                    <LdTypo
                                      variant="body-s"
                                      className="max-w-[10rem]"
                                      style={{ 'overflow-wrap': 'anywhere' }}
                                    >
                                      {affectedParamters?.[key]?.join(', ')}
                                    </LdTypo>
                                  </LdTableCell>
                                )}
                                <LdTableCell>
                                  {conditions?.[key]?.type ==
                                  'ThresholdCondition' ? (
                                    <LdLabel size="m">
                                      Threshold Condition
                                      <LdCard className="flex flex-row justify-between p-2 text-thm-primary mx-auto">
                                        <LdTypo
                                          variant="body-m"
                                          className="max-w-[10rem] mr-2"
                                          style={{
                                            'overflow-wrap': 'anywhere',
                                          }}
                                        >
                                          {'Operator : '}
                                          <LdBadge className="ld-badge--info">
                                            {
                                              (
                                                conditions?.[
                                                  key
                                                ] as ThresholdCondition
                                              ).operator
                                            }
                                          </LdBadge>
                                        </LdTypo>
                                        <LdTypo
                                          variant="body-m"
                                          className="max-w-[10rem mx-2"
                                          style={{
                                            'overflow-wrap': 'anywhere',
                                          }}
                                        >
                                          {'Threshold : '}
                                          <b>
                                            {
                                              (
                                                conditions?.[
                                                  key
                                                ] as ThresholdCondition
                                              ).threshold
                                            }
                                          </b>
                                        </LdTypo>
                                        <LdTypo
                                          variant="body-m"
                                          className="max-w-[10rem]"
                                          style={{
                                            'overflow-wrap': 'anywhere',
                                          }}
                                        >
                                          {'Tolerance : '}
                                          <b>
                                            {(
                                              conditions?.[
                                                key
                                              ] as ThresholdCondition
                                            ).tolerance || 'None'}
                                          </b>
                                        </LdTypo>
                                      </LdCard>
                                    </LdLabel>
                                  ) : (
                                    <LdLabel size="m">
                                      Sub Selection Condition
                                      <div className="flex flex-row w-full">
                                        {conditions?.[key] != undefined &&
                                          (
                                            conditions?.[
                                              key
                                            ] as SubSelectionCondition
                                          )?.selection?.map((e) => (
                                            <LdCard className="mr-2" key={e}>
                                              {e}
                                            </LdCard>
                                          ))}
                                      </div>
                                    </LdLabel>
                                  )}
                                </LdTableCell>
                              </LdTableRow>
                            ) : null
                          )}
                        </LdTableBody>
                      </LdTable>
                    </>
                  ) : (
                    <>
                      <LdTypo
                        variant="label-m"
                        className="flex flex-row align-middle"
                      >
                        Parameters
                      </LdTypo>
                      <div className="grid grid-cols-6 gap-2 mb-3">
                        {Object.entries(parameters)?.map(([key, value]) => (
                          <LdCard
                            key={`${key}_${value}`}
                            className="text-center justify-center min-w-fit-content"
                          >
                            <LdTypo
                              variant="body-s"
                              className="max-w-[10rem]"
                              style={{ 'overflow-wrap': 'anywhere' }}
                            >
                              {value}
                            </LdTypo>
                          </LdCard>
                        ))}
                      </div>

                      {conditions &&
                        (subType ==
                          ConstraintsSubTypeDiscrete.DiscreteSumConstraint ||
                          subType ==
                            ConstraintsSubTypeDiscrete.DiscreteProductConstraint) && (
                          <LdLabel size="m">
                            Threshold Condition
                            <LdCard className="flex flex-row justify-between p-2 text-thm-primary">
                              <LdTypo
                                variant="body-m"
                                className="max-w-[10rem]"
                                style={{ 'overflow-wrap': 'anywhere' }}
                              >
                                {'Operator : '}
                                <LdBadge className="ld-badge--info">
                                  {
                                    (
                                      Object.values(
                                        conditions
                                      )?.[0] as ThresholdCondition
                                    )?.operator
                                  }
                                </LdBadge>
                              </LdTypo>
                              <LdTypo
                                variant="body-m"
                                className="max-w-[10rem]"
                                style={{ 'overflow-wrap': 'anywhere' }}
                              >
                                {'Threshold : '}
                                {
                                  (
                                    Object.values(
                                      conditions
                                    )?.[0] as ThresholdCondition
                                  )?.threshold
                                }
                              </LdTypo>
                              <LdTypo
                                variant="body-m"
                                className="max-w-[10rem]"
                                style={{ 'overflow-wrap': 'anywhere' }}
                              >
                                {'Tolerance : '}
                                {(
                                  Object.values(
                                    conditions
                                  )?.[0] as ThresholdCondition
                                )?.tolerance || 'None'}
                              </LdTypo>
                            </LdCard>
                          </LdLabel>
                        )}
                    </>
                  )}
                  {type === ConstraintsType.discrete &&
                    subType ==
                      ConstraintsSubTypeDiscrete.DiscreteExcludeConstraint && (
                      <LdLabel
                        position="left"
                        className="flex flex-row align-middle"
                      >
                        <span className="flex mt-3 mb-3">
                          Combiner: <b> {` ${combiner}`} </b>
                        </span>
                      </LdLabel>
                    )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ConsraintDetails;
