/* eslint-disable import/named */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';

import {
  LdInput,
  LdInputMessage,
  LdLabel,
  LdOption,
  LdSelect,
  LdSwitch,
  LdSwitchItem,
} from '@emdgroup-liquid/liquid/dist/react';
import {
  ConstraintDiscreteConditionOperatorType,
  CustomParameterValue,
  DiscreteConditions,
  Parameter,
  ParameterType,
  SmilesParameterValue,
  SubSelectionCondition,
  ThresholdCondition,
} from 'types/api/types';

type FormData = {
  paramValue?: string;
  paramSmilesValue?: string;
};
interface ParameterValuesFormProps {
  name: string;
  tetherOptions: any;
  type: string;
  subType: string;
  paramter: Parameter | undefined;
}

export const ConditionsValueForm = forwardRef(function ConditionsValueForm(
  { tetherOptions, paramter }: ParameterValuesFormProps,
  ref
) {
  const { formState, setError, setValue, clearErrors, trigger } =
    useForm<FormData>({
      mode: 'onChange',
    });
  const { errors } = formState;
  const [threshold, setThreshold] = useState<number>();
  const [operator, setOperator] =
    useState<ConstraintDiscreteConditionOperatorType>();
  const [tolerance, setTolerance] = useState<number>();
  const [conditions, setConditions] = useState<string[]>([]);

  const subSelectionOptions: string[] = useMemo(() => {
    if (!paramter) return [];
    if (paramter.parameterType == ParameterType.custom) {
      return paramter.parameterValues.map(
        (row: CustomParameterValue) => Object.values(row)[0]
      );
    }
    return paramter.parameterValues.map((p) => {
      switch (paramter.parameterType) {
        case ParameterType.smiles:
          return (p as SmilesParameterValue).Name;
        case ParameterType.category:
          return p.toString();
      }
    });
  }, [paramter]);

  const handleFormInvalid = () => {
    setValue('paramValue', '');
    setTolerance(undefined);
    setOperator(undefined);
    setThreshold(undefined);
    setConditions([]);
  };

  const isValid = async () => {
    if (paramter?.parameterType == ParameterType.numeric) {
      if (!threshold) {
        setError('paramValue', {
          type: 'Threshold is required!',
        });
        return false;
      }
      if (!operator) {
        setError('paramValue', {
          type: 'Operator is required!',
        });
        return false;
      }
    } else {
      if (conditions?.length == 0) {
        setError('paramValue', {
          type: 'SubSelection Value is required!',
        });
        return false;
      }
    }
    clearErrors('paramValue');
    const isValid = await trigger();
    if (!isValid) return false;
    return true;
  };

  useEffect(() => {
    setTolerance(undefined);
  }, [operator]);

  useImperativeHandle(ref, () => ({
    async clickButton(submitForm: (p: DiscreteConditions | any) => void) {
      const valid = await isValid();
      if (valid) {
        if (paramter?.parameterType == ParameterType.numeric) {
          if (!operator || !threshold) return;
          const cond: ThresholdCondition = {
            tolerance,
            operator,
            threshold,
            type: 'ThresholdCondition',
          };
          await submitForm(cond);
        } else {
          const cond: SubSelectionCondition = {
            selection: conditions,
            type: 'SubSelectionCondition',
          };
          await submitForm(cond);
        }
        handleFormInvalid();
      }
    },
  }));

  return (
    <>
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onKeyDown={(e) => {
          e.key === 'Enter' && e.preventDefault();
        }}
        className="min-w-max w-full"
      >
        <div className="">
          {paramter?.parameterType == ParameterType.numeric && (
            <>
              <div className="flex flex-col flex-grow  p-2 my-2">
                <LdLabel>Threshold Condition</LdLabel>
                <div className="flex w-full flex-col flex-grow justify-start items-start py-3 border-dashed border-2 border-rich-blue-lightest border-opacity-50">
                  <div className="flex-2 mx-2">
                    <LdLabel>
                      <span className="flex justify-between">Operator</span>
                      <LdSwitch
                        required
                        onLdswitchchange={(e) => {
                          setOperator(
                            e.detail as ConstraintDiscreteConditionOperatorType
                          );
                        }}
                        placeholder="Select Operator"
                      >
                        {Object.keys(
                          ConstraintDiscreteConditionOperatorType
                        ).map((val) => (
                          <LdSwitchItem
                            key={val}
                            value={val}
                            checked={operator == val}
                          >
                            {val}
                          </LdSwitchItem>
                        ))}
                      </LdSwitch>
                    </LdLabel>
                  </div>

                  <div className="flex mx-1">
                    <LdLabel>
                      Threshold
                      <LdInput
                        onKeyDown={(e) => {
                          e.key === 'Enter' && e.preventDefault();
                        }}
                        required
                        placeholder="e.g. 3"
                        type="number"
                        tone="dark"
                        onInput={(ev) => {
                          const v = (ev.target as HTMLLdInputElement).value;
                          v ? setThreshold(Number(v)) : setThreshold(undefined);
                        }}
                        value={threshold?.toString()}
                      />
                    </LdLabel>
                  </div>

                  <div className="flex mx-1">
                    <LdLabel>
                      Tolerance
                      <LdInput
                        onKeyDown={(e) => {
                          e.key === 'Enter' && e.preventDefault();
                        }}
                        placeholder="e.g. 1 (Optional)"
                        type="number"
                        tone="dark"
                        onInput={(ev) => {
                          const v = (ev.target as HTMLLdInputElement).value;
                          v ? setTolerance(Number(v)) : setTolerance(undefined);
                        }}
                        value={tolerance?.toString()}
                        disabled={
                          ![
                            ConstraintDiscreteConditionOperatorType['!='],
                            ConstraintDiscreteConditionOperatorType['='],
                          ].includes(
                            operator as ConstraintDiscreteConditionOperatorType
                          )
                        }
                      />
                    </LdLabel>
                  </div>
                </div>
              </div>
            </>
          )}
          {paramter?.parameterType &&
            paramter?.parameterType != ParameterType.numeric && (
              <div className="flex flex-col flex-grow">
                <LdLabel>
                  <span className="flex justify-between">
                    SubSelection Condition
                  </span>
                  <LdSelect
                    mode="inline"
                    filter
                    tetherOptions={tetherOptions}
                    placeholder="Select Value"
                    name="subselect-cond-type"
                    multiple
                    style={{ maxWidth: '20rem' }}
                    maxRows={2}
                    className="w-72"
                    onLdchange={(ev) => {
                      setConditions(ev.detail);
                    }}
                  >
                    {subSelectionOptions.map((option) => (
                      <LdOption
                        value={option}
                        selected={conditions.includes(option)}
                        key={option}
                      >
                        {option}
                      </LdOption>
                    ))}
                  </LdSelect>
                </LdLabel>
              </div>
            )}
        </div>
        <div className="flex left-0 right-0 ml-auto mr-auto">
          <LdInputMessage
            className={
              errors.paramValue && errors.paramValue.type !== 'required'
                ? 'visible inline'
                : 'invisible d-none'
            }
            mode={errors.paramValue ? 'error' : 'valid'}
          >
            {errors.paramValue?.type || 'Value is required.'}
          </LdInputMessage>
        </div>
      </form>
    </>
  );
});

export default ConditionsValueForm;
