import React, { FC } from 'react';
import {
  LdButton,
  LdIcon,
  LdInput,
  LdTable,
  LdTableBody,
  LdTableCell,
  LdTableHead,
  LdTableHeader,
  LdTableRow,
  LdTypo,
} from '@emdgroup-liquid/liquid/dist/react';
import Card from '../../../../components/Card';
import {
  ExperimentResults,
  Project,
  ProjectRole,
  TargetVariable,
} from 'types/api/types';
import { ParameterInputSelection } from '../ParameterInputSelection';
import { hasAtLeastRole, isBusy } from 'util/project';
import useHistory from '../../hooks/useHistory';
import useExpandSpace from 'hooks/useExpandSpace';
import { UserIcon } from './UserIcon';
import ReactTooltip from 'react-tooltip';
import { useHints } from 'hooks/useHints';

interface ProjectHistoryProps {
  selectedProject: Project;
  onChange: (arg0: {
    experimentId: string;
    parameterName: string;
    value: any;
  }) => void;
}

export const ProjectHistory: FC<ProjectHistoryProps> = ({
  selectedProject,
  onChange,
}) => {
  const {
    history,
    changeExperiment,
    removeExperiment,
    cancel,
    save,
    isEditing,
    startEditing,
    exportHistory,
  } = useHistory(selectedProject.projectId);

  const { newParameters: _newParameters, isExpanding } = useExpandSpace(
    selectedProject.projectId
  );
  const { getToolTipTextById } = useHints();

  const newParameters = Object.values(_newParameters);
  const handleStartEditing = () => {
    startEditing();
  };
  const handleSaveEditing = () => {
    save();
  };
  const handleCancelEditing = () => {
    cancel();
  };

  const renderTargetCell = ({
    experimentId,
    results,
    target: { targetName },
  }: {
    experimentId: string;
    results: ExperimentResults;
    target: TargetVariable;
  }) => {
    const value = (results as Record<string, any>)[targetName];
    // TODO: Uncomment when History Change Feature shall be release
    if (isEditing)
      return (
        <LdTableCell
          className="px-4 text-left"
          key={`${experimentId}_result_${targetName}`}
        >
          <LdInput
            className="w-12"
            type="number"
            required
            min="0"
            size="sm"
            onInput={(e) => {
              const { value: newValue } = e.target as HTMLLdInputElement;
              if (newValue === undefined) return;
              const floatVal = parseFloat(newValue);
              if (isNaN(floatVal)) return;
              changeExperiment({
                experimentId,
                results: { [targetName]: floatVal },
              });
            }}
            value={value}
          />
        </LdTableCell>
      );
    return (
      <LdTableCell
        className="px-4 text-left"
        key={`${experimentId}_result_${targetName}`}
      >
        <LdTypo>{value}</LdTypo>
      </LdTableCell>
    );
  };

  return (
    <Card className="p-4 mt-8">
      <LdTable className="w-full">
        <LdTableHead style={{ textAlign: 'right' }}>
          <LdTableRow>
            <LdTableHeader
              data-id="history-table-header"
              sortable
              style={{ textAlign: 'left' }}
              sortOrder="asc"
            >
              Author
            </LdTableHeader>
            <LdTableHeader
              data-id="history-table-header"
              sortable
              style={{ textAlign: 'left' }}
              sortOrder="asc"
            >
              Batch
            </LdTableHeader>
            {selectedProject?.parameterSpace.map((p) => (
              <LdTableHeader
                data-id="history-table-header"
                key={p.parameterName}
                sortable
                style={{ textAlign: 'left' }}
              >
                {p.parameterName}
              </LdTableHeader>
            ))}
            {newParameters.map((p) => (
              <LdTableHeader
                data-id="history-table-header"
                key={p.parameterName}
                style={{ textAlign: 'left' }}
              >
                {p.parameterName}
              </LdTableHeader>
            ))}
            {selectedProject?.targets.map((t) => (
              <LdTableHeader
                data-id="history-table-header"
                className="px-4 text-left"
                key={t.targetName}
              >
                {t.targetName}
              </LdTableHeader>
            ))}
            {isEditing && (
              <LdTableHeader className="py-1 text-right"></LdTableHeader>
            )}
          </LdTableRow>
        </LdTableHead>
        <LdTableBody>
          {history.map(
            ({
              experimentId,
              batch,
              parameters,
              results,
              changedBy,
              changedAt,
            }) => (
              <LdTableRow key={experimentId} data-test="project-history-row">
                <LdTableCell className="px-4">
                  <UserIcon
                    id={experimentId}
                    changedAt={changedAt}
                    changedBy={changedBy}
                  />
                </LdTableCell>
                <LdTableCell className="px-4">
                  <LdTypo>{batch}</LdTypo>
                </LdTableCell>
                {selectedProject?.parameterSpace.map((p) => (
                  <LdTableCell
                    key={`${experimentId}_${p.parameterName}`}
                    className="px-4"
                  >
                    <LdTypo>
                      {(parameters as Record<string, any>)[p.parameterName]}
                    </LdTypo>
                  </LdTableCell>
                ))}
                {newParameters.map((p) => (
                  <LdTableCell
                    key={`${experimentId}_${p.parameterName}`}
                    className="px-4"
                  >
                    <ParameterInputSelection
                      parameter={p}
                      touched={false}
                      onChange={({ value }) => {
                        onChange({
                          experimentId,
                          parameterName: p.parameterName,
                          value,
                        });
                      }}
                    />
                  </LdTableCell>
                ))}
                {selectedProject?.targets.map((target) =>
                  renderTargetCell({ experimentId, results, target })
                )}
                {isEditing && (
                  <LdTableCell className="py-1 text-right">
                    <LdButton
                      size="sm"
                      mode="danger-secondary"
                      onClick={() => removeExperiment(experimentId)}
                    >
                      <LdIcon name="bin" />
                    </LdButton>
                  </LdTableCell>
                )}
              </LdTableRow>
            )
          )}
        </LdTableBody>
      </LdTable>
      <div className="flex justify-end gap-4 w-full mt-ld-8">
        {!isEditing && (
          <LdButton
            onClick={exportHistory}
            data-tip={getToolTipTextById('Exp_2')}
            data-for="export-tooltip"
            mode="ghost"
          >
            <LdIcon name="cloud-download" />
            Export
          </LdButton>
        )}
        {!isEditing &&
          !isExpanding &&
          hasAtLeastRole(ProjectRole.Editor, selectedProject) && (
            <LdButton
              type="button"
              onClick={handleStartEditing}
              disabled={isBusy(selectedProject)}
            >
              <LdIcon name="pen" /> Edit History
            </LdButton>
          )}
        {isEditing && (
          <>
            <LdButton
              mode="danger-secondary"
              type="button"
              onClick={handleCancelEditing}
            >
              Cancel
            </LdButton>
            <LdButton type="button" onClick={handleSaveEditing}>
              Save
            </LdButton>
          </>
        )}
        <ReactTooltip
          id="export-tooltip"
          type="light"
          effect="solid"
          className="tooltip"
        />
      </div>
    </Card>
  );
};
