// eslint-disable-next-line import/named
import { useUser } from '@emdgroup/react-auth';
import axios from 'axios';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useEffect, useMemo, useState } from 'react';
import {
  clear,
  setNewParameter,
  setNewParameterValues,
  setNewParameterResults,
  startExpanding,
} from 'services/expand';
import { setExperiments } from 'services/experiments';
import { setProject } from 'services/projects';

import { useDispatch, useSelector } from 'store';
import {
  Parameter,
  ParameterRecommendation,
  ParameterValue,
  Project,
} from 'types/api/types';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
  ? process.env.REACT_APP_API_ENDPOINT.replace(/\/+$/, '')
  : '';

const useExpandSpace = (projectId: string) => {
  const dispatch = useDispatch();
  const { authHeader } = useUser();
  const [selectedProject, setSelectedProject] = useState<Project>();

  const expandState = useSelector((state) => state.expand);
  const projects = useSelector(({ projects }) => projects.projects);
  const experiments = useSelector(
    ({ experiments }) => experiments.experiments[projectId]
  );

  useEffect(() => {
    if (_.has(projects, projectId)) {
      setSelectedProject(projects[projectId]);
    }
  }, [projects, projectId]);

  const newParameterValues = (parameterName: string) => {
    try {
      return expandState[projectId].newParameterValues[parameterName] || [];
    } catch {
      return [];
    }
  };
  const newParameters = useMemo(() => {
    try {
      return expandState[projectId].newParameters;
    } catch {
      return {};
    }
  }, [projectId, expandState]);
  const hasEmptyParameterValues = useMemo(() => {
    try {
      const newParameters = Object.values(expandState[projectId].newParameters);
      const newParameterResults = expandState[projectId].newParameterResults;
      let isInvalid = false;
      newParameters.forEach((parameter) => {
        experiments.forEach((experiment) => {
          try {
            const val =
              newParameterResults[experiment.experimentId][
                parameter.parameterName
              ];
            if (val === undefined) {
              isInvalid = true;
              return true;
            }
          } catch {
            isInvalid = true;
            return true;
          }
        });
      });
      return isInvalid;
    } catch {
      return false;
    }
  }, [expandState, experiments, projectId]);

  const finishExpanding = async () => {
    if (hasEmptyParameterValues) return;
    const newParameterResults = expandState[projectId].newParameterResults;
    const experimentResults = experiments.reduce((prev, cur) => {
      const { experimentId, parameters } = cur;
      const params = {
        ...parameters,
        ...newParameterResults[experimentId],
      };

      return Object.assign(prev, { [experimentId]: params });
    }, {});
    const parameters = [
      ...(selectedProject?.parameterSpace || []).map(
        ({ parameterName, parameterType, parameterValues }) => ({
          parameterName,
          parameterType,
          parameterValues: newParameterValues(parameterName).length
            ? newParameterValues(parameterName)
            : parameterValues,
        })
      ),
      ...Object.values(expandState[projectId].newParameters),
    ];
    const body: {
      parameters: Parameter[];
      experiments: { [key: string]: ParameterRecommendation };
    } = {
      parameters,
      experiments: experimentResults,
    };
    const resp = await axios.put(`${apiEndpoint}/project/${projectId}`, body, {
      headers: authHeader,
    });
    if (resp.status === 200) {
      dispatch(setProject(resp.data));
      dispatch(
        setExperiments({
          projectId,
          experiments: experiments.map((experiment) => {
            const _params = {
              ...experiment.parameters,
              ...newParameterResults[experiment.experimentId],
            };
            return { ...experiment, parameters: _params };
          }),
        })
      );
    }
    dispatch(clear(projectId));
  };

  const isExpanding = useMemo(() => {
    return _.has(expandState, projectId);
  }, [expandState, projectId]);

  return {
    startExpanding: () => dispatch(startExpanding(projectId)),
    cancelExpanding: () => dispatch(clear(projectId)),
    finishExpanding,
    newParameterValues,
    newParameters,
    setNewParameter: (parameter: Parameter) => {
      const newKey = nanoid();
      dispatch(setNewParameter({ projectId, key: newKey, parameter }));
      return newKey;
    },
    setNewParameterValues: ({
      parameterName,
      values,
    }: {
      parameterName: string;
      values: ParameterValue[];
    }) => {
      dispatch(setNewParameterValues({ projectId, parameterName, values }));
    },
    setNewParameterResults: ({
      experimentId,
      parameters,
    }: {
      experimentId: string;
      parameters: { [parameterName: string]: any };
    }) =>
      dispatch(setNewParameterResults({ projectId, experimentId, parameters })),
    isExpanding,
    hasEmptyParameterValues,
  };
};

export { useExpandSpace as default };
